import React, { useEffect, useState } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import MenList from '../SelectList/MenList';
import { PostAuthRequest, PostNoAuthRequest, getRecords, addBodyClass, removeBodyClass } from "../../utills/common";

export default function ServicesPage() {
  pageTitle('Men');
  const [portfolioData, setPortfolioData] = useState([]);
  // const [loading, setLoading] = useState(true)
  const [regions, setReagions] = useState({});
  const [services, setServics] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0)
        async function fetchData() {
          let regionsData = await PostNoAuthRequest("get-regions");
          let servicesData = await getRecords("service/get-services");
          // let userListData = await PostNoAuthRequest("get-men-profiles");
          // setLoading(true);
          // addBodyClass("MyLoaderClass")
          // if (userListData.success) {
          //     setPortfolioData(userListData);
          //     // setLoading(false);
          //     removeBodyClass('MyLoaderClass');
          // }
            
          if (regionsData.success) {
              let arr = [{value:"",label:"Select Region"}];
              regionsData.data && regionsData.data.map((item, index)=>{
                  arr.push({value:item.id,label:item.name})
              })
            setReagions(arr);
          }

          if (regionsData.success) {
              let arr =[{value:"",label:"Select Service"}];
              servicesData.data && servicesData.data.map((item, index)=>{
                  arr.push({value:item.id,label:item.name})
              })
              setServics(arr);
          }          
        }
        fetchData();
  }, [])
  return (
      <>    
            {/* Select List Page */}
            {
                // loading? (
                // <div className="loader-container">
                //     <div className="spinner"></div>
                // </div>
                // ) :
                <>
                    <MenList regions={regions}  services={services}/>
               
          
              </>            
            }
      {/* Select List Page */}
    </>
  )
}
