import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Div from '../Div';
import { defaultTheme } from 'react-select';
import './selectlist.scss';
import Spacing from '../Spacing';
import { Icon } from '@iconify/react';
import { PostNoAuthRequest, getRecords , addBodyClass, removeBodyClass} from "../../utills/common";
import { useForm } from "react-hook-form";
import PortfolioAgencyList from '../PortfolioAgencyList';

export default function SelectAgencyList() {
    const [portfolioData, setPortfolioData] = useState([]);
    const [loading, setLoading] = useState(true)
    const {
        register,
        handleSubmit,
        formState: { isValid },
      } = useForm();
    const [regions, setReagions] = useState({});
    const [services, setServics] = useState({});
    const [searchName, setSearchName] = useState();
    const [location, setLocation] = useState();

    const handleSearch = async (formData) => {
        // const handleSearch = async  => {
        // e.preventDefault();
        try {
        const searchFilds = {
            searchName,
            // available,
            // service,
            location,
            isSearch:formData.isSearch
        }

          if(isValid){
            let clubListData = await PostNoAuthRequest("get-clubs",searchFilds);
            setLoading(true);
            addBodyClass("MyLoaderClass")
            if(clubListData.success){
                   setPortfolioData(clubListData.data); 
                   setLoading(false);
                   removeBodyClass('MyLoaderClass');              
            }else{
                 console.error(clubListData.message);
                 setLoading(false);
                 removeBodyClass('MyLoaderClass');   
            }       
          }      
        } catch (error) {
          console.error(error.message);
          setLoading(false);
          removeBodyClass('MyLoaderClass');   
        }
      };

    useEffect(() => {
        try {
          async function fetchData() {
            let regionsData = await PostNoAuthRequest("get-regions");
            let servicesData = await getRecords("service/get-services");

            let clubList = await PostNoAuthRequest("get-clubs");
            // setLoading(true);
            addBodyClass("MyLoaderClass")
            if (clubList.success) {
                setPortfolioData(clubList.data);
                setLoading(false);
                removeBodyClass('MyLoaderClass');   
            }

            // let cityData = await PostAuthRequest("get-suburbs",{'region_id':2});
            // console.log("cityData ", cityData);
            // if (cityData.success) {
            //   setSuburbs(cityData.data);
            // }
            if (regionsData.success) {
                let arr = [{value:"",label:"Select Region"}];
                regionsData.data && regionsData.data.map((item, index)=>{
                    arr.push({value:item.id,label:item.name})
                })
              setReagions(arr);
            }

            if (regionsData.success) {
                let arr =[{value:"",label:"Select Service"}];
                servicesData.data && servicesData.data.map((item, index)=>{
                    arr.push({value:item.id,label:item.name})
                })
                setServics(arr);
            }
            
          }
          fetchData();
        } catch (error) {
          // setLoading(false); // Stop loading in case of error
          console.error(error);
        }
      }, []);

    const { colors } = defaultTheme;
    // const location = [
    //     { value: "all", label: "All" },
    //     { value: "nearme", label: "Near Me" },
    // ];
    const availability = [
        {value:"", label:"Select Availabilty"},
        { value: "any", label: "Any" },
        // { value: "availablenow", label: "Available Now" },
        // { value: "daytime", label: "Day Time" },
        // { value: "nightime", label: "Night Time" },
        // { value: "daynight", label: "Day and Night" },
        { value: "Available Now", label: "Available Now" },
        { value: "Not Available", label: "Not Available" },
        { value: "In Call", label: "In Call" },
        { value: "Out Call", label: "Out Call" },
    ];

    const [itemShow, setItemShow] = useState(20);
     


return (
<>
    {/* Start CTA Section */}
    <form action="#" onSubmit={handleSubmit(handleSearch)} >
    <Div className="cs-gradient_bg_1 cs-shape_wrap_3 listsection">
        <Div class="cs-height_60 cs-height_lg_20"></Div>
        <Div className="container">
            <Div className="row">
                <Div className="col-12">
                    <h3>Clubs / Agencies</h3>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> */}
                </Div>
            </Div>
            <Div class="cs-height_20 cs-height_lg_20"></Div>
            <Div className="row g-2">
                <Div className="col-12 col-sm-5 col-md-5">
                    <Div className="form-group">
                        <label class="cs-primary_color">Search name</label>
                        <input type="text" name="searchName" onChange={e => setSearchName(e.target.value)} class="form-control" placeholder="e.g. Agency/Club"></input>
                    </Div>
                </Div>
                <Div className="col-12 col-sm-5 col-md-5">
                    <Div className="form-group">
                        <label class="cs-primary_color">Location</label>
                        <Select
                            //
                            onChange={e => setLocation(e.value)}                            
                            name="location"
                            options={regions}
                            placeholder="Select..."
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#0C0C0C',
                                primary: '#ED145B',
                                },
                            })}
                        />
                    </Div>
                </Div>
                {/* <Div className="col-6 col-sm-6 col-md-2">
                    <Div className="form-group">
                        <label class="cs-primary_color">Availability</label>
                        <Select
                            // {...register("availability")}
                            onChange={e => setAvailability(e.value)}
                            name="availability"
                            options={availability}
                            placeholder="Select..."
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#0C0C0C',
                                primary: '#ED145B',
                                },
                            })}
                        />
                    </Div>
                </Div> */}
                {/* <Div className="col-6 col-sm-6 col-md-2">
                    <Div className="form-group">
                        <label class="cs-primary_color">Services</label>
                        <Select
                            onChange={e => setService(e.value)}                               
                            name="service"
                            options={services}
                            placeholder="Select..."
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#0C0C0C',
                                primary: '#ED145B',
                                },
                            })}
                        />
                    </Div>
                </Div> */}
                <Div className="col-12 col-sm-12 col-md-2">
                    <Div className="form-group">
                        <button type="submit"  className="w-100 cs-btn cs-style1 cs-type1 primary-btn">
                        <input type="hidden" {...register("isSearch")} value="1" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.282" height="15.285" viewBox="0 0 15.282 15.285">
                            <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M15.074,13.215,12.1,10.239a.716.716,0,0,0-.507-.209H11.1A6.206,6.206,0,1,0,10.03,11.1v.487a.716.716,0,0,0,.209.507l2.976,2.976a.713.713,0,0,0,1.012,0l.845-.845A.72.72,0,0,0,15.074,13.215ZM6.209,10.03A3.821,3.821,0,1,1,10.03,6.209,3.819,3.819,0,0,1,6.209,10.03Z" fill="#fff"/>
                        </svg> <span>Search</span>
                        </button>
                    </Div>
                </Div>
            </Div>
            <Div class="cs-height_60 cs-height_lg_60"></Div>
                            
            {/* User Data */}
            {
                // loading ? (
                // <div className="loader-container">
                //     <div className="spinner"></div>
                // </div>
                // ) :
                <>
                    <Div className="row g-2 userlistcol">
                        {portfolioData && portfolioData.slice(0, itemShow).map((item, index) => (
                            <Div
                            className={`${
                                index === 0 || index === 3 || index === 4
                                ? 'col-sm-6'
                                : 'col-sm-6'
                            }`}
                            key={index}
                            >
                            <PortfolioAgencyList
                                name={item.name}
                                location={item.location}
                                href={item.href}
                                src={item.src}
                                variant="cs-style1 cs-type2 cs-size3 agency-wrap"
                            />
                    
                            </Div>
                        ))}
                        {
                            portfolioData.length==0 && <div>No Search Results Found.</div>
                        }
                    </Div>
                    <Div className="text-center">
                        {portfolioData.length <= itemShow ? (
                            ''
                        ) : (
                            <>
                            <Spacing lg="65" md="40" />
                            <span
                                className="cs-text_btn"
                                onClick={() => setItemShow(itemShow + 10)}
                            >
                                <span>Load More</span>
                                <Icon icon="bi:arrow-right" />
                            </span>
                            </>
                        )}
                    </Div>
                </>
            }

            {/* User Data End */}
            <Div class="cs-height_60 cs-height_lg_60"></Div>
        </Div>      
        
    </Div>
    </form>
    {/* End CTA Section */}
</>

)
}