import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { useForm } from "react-hook-form";
import { PostNoAuthRequest } from '../../utills/common';
import parse from 'html-react-parser';

  export default function Privacy() {
    
    const [Title, setPageTitle] = useState("");
    const [pageDetail, setPageDetail] = useState("");
    const [PageBanner, setPageBanner] = useState("");    

  useEffect(() => {
      window.scrollTo(0, 0);
      // setTimeout(() => {
      //   setisSaved(false);
      // }, 6000);    

    try {
      async function fetchData() {
        let pageData = await PostNoAuthRequest("get-cmspages", {'page_id': 1});
        if (pageData.success) {
            setPageTitle(pageData.data?.title);
            setPageDetail(pageData.data?.description);
            setPageBanner(pageData.data?.image_file)
        }
      }
      fetchData();
    } catch (error) {
      // setLoading(false); // Stop loading in case of error
      console.error(error);
    }
  }, []);

  pageTitle(Title);

  return (
    <>
      <PageHeading
        title={Title}
        bgSrc={PageBanner}
        pageLinkText={Title}
      />
      <Spacing lg="100" md="40" />
      <Div className="container">
        <Div className="row">
          {/* <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon ContactDetail={ContactDetail}  />
            <Spacing lg="0" md="50" />
          </Div> */}
          <Div className="col-lg-10">
            <Spacing lg="55" md="30" />            
            { pageDetail && parse(pageDetail) }

            <Spacing lg="0" md="50" />

          </Div>
        </Div>
      </Div>
      <Spacing lg="100" md="40" />
    </>
  );
}
