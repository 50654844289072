import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import './header.scss';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import Div from '../Div';
import DropDown from './DropDown';
import { useAuth } from '../../auth/AuthContext';
// import { PostNoAuthRequest } from "../../utills/common";

export default function Header({ variant,  RegionList}) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [displayName, setDisplayName] = useState('');

  const { user, token, logout } = useAuth();
  const navigate = useNavigate();

  const Logout = ()=>{   
    logout();
    localStorage.clear();
    localStorage.setItem('user-token', '');    
    setisLoggedIn(false);
    navigate('/');
  }
  useEffect( () => { 
    try{
      // async function fetchData() {
      //   let regionsData = await PostNoAuthRequest("get-regions");
      //   console.log("regionsData www", regionsData);
      //   if (regionsData.success) {
      //     setRegionList(regionsData.data);
      //   }
      // }
      // fetchData();
    } catch (error) {
      // setLoading(false); // Stop loading in case of error
      console.error(error);
    }
   // window.addEventListener('scroll', () => {
    //   if (window.scrollY > 0) {
    //     setIsSticky(true);
    //   } else {
    //     setIsSticky(false);
    //   }
    // }); 
   ( localStorage.getItem("user-token") ) ? setisLoggedIn(true) :  setisLoggedIn(false) ;
    if(isLoggedIn) {
        setDisplayName( JSON.parse(localStorage.getItem('user')).name )
    }
  }, [displayName]);

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ''
        } cs-sticky_header ${isSticky ? 'cs-sticky_header_active' : ''}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              
              <Div className="cs-main_header_left">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul className="cs-nav_list column-gap-4">
                    {/* <li>
                      <NavLink
                        to="favourites"
                        onClick={() => setMobileToggle(false)}
                      >
                      <span className='me-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.335" height="15.078" viewBox="0 0 16.335 15.078">
                          <path id="Icon_ionic-md-heart" data-name="Icon ionic-md-heart" d="M11.542,19.578,10.358,18.51c-4.206-3.862-6.983-6.368-6.983-9.49A4.442,4.442,0,0,1,7.867,4.5a4.832,4.832,0,0,1,3.675,1.726A4.831,4.831,0,0,1,15.218,4.5,4.442,4.442,0,0,1,19.71,9.019c0,3.122-2.777,5.629-6.983,9.49Z" transform="translate(-3.375 -4.5)" fill="#ed145b"/>
                        </svg>
                      </span>
                       Favourites
                      </NavLink>
                      </li> */}
                      <li>
                        <NavLink
                        to="faq"
                        onClick={() => setMobileToggle(false)}
                      >
                      <span className='me-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.986" height="15.986" viewBox="0 0 15.986 15.986">
                          <g id="Icon_feather-help-circle" data-name="Icon feather-help-circle" transform="translate(-2.25 -2.25)">
                            <path id="Path_8" data-name="Path 8" d="M17.486,10.243A7.243,7.243,0,1,1,10.243,3,7.243,7.243,0,0,1,17.486,10.243Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <path id="Path_9" data-name="Path 9" d="M13.635,11.945a2.173,2.173,0,0,1,4.223.724c0,1.449-2.173,2.173-2.173,2.173" transform="translate(-5.5 -3.875)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <path id="Path_10" data-name="Path 10" d="M18,25.5h0" transform="translate(-7.757 -11.635)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                          </g>
                        </svg>
                      </span>
                       Need Help
                      </NavLink>
                      </li>
                      <li>
                        <NavLink
                        to="contact"
                        onClick={() => setMobileToggle(false)}
                        >
                        <span className='me-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14.244" height="14.243" viewBox="0 0 14.244 14.243">
                            <path id="Icon_ionic-ios-call" data-name="Icon ionic-ios-call" d="M18.322,15.655a11.964,11.964,0,0,0-2.5-1.672c-.749-.36-1.023-.352-1.554.03-.441.319-.727.616-1.235.5a7.366,7.366,0,0,1-2.481-1.835A7.313,7.313,0,0,1,8.719,10.2c-.108-.512.189-.794.5-1.235.382-.53.393-.8.03-1.554a11.727,11.727,0,0,0-1.672-2.5c-.545-.545-.667-.426-.968-.319a5.507,5.507,0,0,0-.886.471A2.673,2.673,0,0,0,4.662,6.188c-.211.456-.456,1.305.79,3.523A19.656,19.656,0,0,0,8.908,14.32h0l0,0,0,0h0a19.732,19.732,0,0,0,4.609,3.456c2.217,1.246,3.066,1,3.523.79a2.628,2.628,0,0,0,1.124-1.064,5.507,5.507,0,0,0,.471-.886C18.749,16.322,18.871,16.2,18.322,15.655Z" transform="translate(-4.49 -4.502)" fill="#fff"/>
                          </svg>
                        </span>
                        Contact Us
                      </NavLink>
                      </li>
                  </ul>
                  
                </Div>
              </Div>
              <Div className="cs-main_header_center">
                <Link className="cs-site_branding" to="/">
                  <img src="/images/logo.svg" alt="Logo" />
                </Link>
              </Div>
              <Div className="cs-main_header_right">

                {/* After Login User button show  remove class to show div (d-none)*/}
                {isLoggedIn &&
                <>
                <Div className="cs-toolbox afterlogin">
                <a class="cs-btn cs-style1 cs-type1 me-2" href="/profile">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.051" height="16.682" viewBox="0 0 15.051 16.682">
                          <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-5 -3.5)">
                            <path id="Path_6" data-name="Path 6" d="M19.051,27.394V25.763A3.263,3.263,0,0,0,15.788,22.5H9.263A3.263,3.263,0,0,0,6,25.763v1.631" transform="translate(0 -8.212)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path id="Path_7" data-name="Path 7" d="M18.525,7.763A3.263,3.263,0,1,1,15.263,4.5,3.263,3.263,0,0,1,18.525,7.763Z" transform="translate(-2.737)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                          </g>
                        </svg>Hi, {JSON.parse(localStorage?.getItem('user')).name}</span>
                      </a>
                                   
                    <a class="cs-btn cs-style1 cs-type1 primary-btn" href="/" onClick={Logout}><span>LOG OUT</span></a>
                  </Div>                  
                </> 
                }

                {/* Before Login default button show */}                
                  {!isLoggedIn &&                    
                      <>
                      <Div className="cs-toolbox">
                       <a class="cs-btn cs-style1 cs-type1 me-2" href="/login"><span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="15.051" height="16.682" viewBox="0 0 15.051 16.682">
                            <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-5 -3.5)">
                              <path id="Path_6" data-name="Path 6" d="M19.051,27.394V25.763A3.263,3.263,0,0,0,15.788,22.5H9.263A3.263,3.263,0,0,0,6,25.763v1.631" transform="translate(0 -8.212)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                              <path id="Path_7" data-name="Path 7" d="M18.525,7.763A3.263,3.263,0,1,1,15.263,4.5,3.263,3.263,0,0,1,18.525,7.763Z" transform="translate(-2.737)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </g>
                          </svg> Escort Login</span>
                        </a>
                        <a class="cs-btn cs-style1 cs-type1 primary-btn" href="/signup"><span>Post Your Ad</span></a>
                        </Div>
                      </>
                    }
                
              </Div>
            </Div>
       
              <Div className="cs-sub_header">
                <Div className="cs-main_header_full">
                  <Div className="cs-nav cs-primary_font cs-medium">
                    <ul
                      className="cs-nav_list"
                      style={{ display: `${mobileToggle ? 'block' : 'none'}` }}
                    >
                      
                      <li>
                        <NavLink
                          to="service"
                          onClick={() => setMobileToggle(false)}
                        >
                          Newly Listed Escorts
                        </NavLink>
                      </li>
                    {
                      RegionList && RegionList.map((item)=>{
                        return <>
                                  <li>
                                    <NavLink
                                      to={"/"+item.slug+"/"+item.id}
                                      onClick={() => setMobileToggle(false)}
                                    >
                                      {item.name}
                                    </NavLink>
                                </li>
                              </>                        
                      })
                    }
                     
                      <li>
                        <NavLink
                          to="agencies"
                          onClick={() => setMobileToggle(false)}
                        >
                        Clubs / Agencies
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="men"
                          onClick={() => setMobileToggle(false)}
                        >
                        Men
                        </NavLink>
                      </li>
                    </ul>
                    <span
                      className={
                        mobileToggle
                          ? 'cs-munu_toggle cs-toggle_active'
                          : 'cs-munu_toggle'
                      }
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      <span></span>
                    </span>
                  </Div>
                </Div>
              </Div>
           
          </Div>
        </Div>
      </header>

      <Div
        className={
          sideHeaderToggle ? 'cs-side_header active' : 'cs-side_header'
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div className="cs-side_header_in">
          <Div className="cs-side_header_shape" />
          <Link className="cs-site_branding" to="/">
            <img src="/images/footer_logo.svg" alt="Logo" />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">
              Do you have a project in your <br /> mind? Keep connect us.
            </h2>
          </Div>
          <Div className="cs-side_header_box">
            <ContactInfoWidget title="Contact Us" withIcon />
          </Div>
          <Div className="cs-side_header_box">
            <Newsletter
              title="Subscribe"
              subtitle="At vero eos et accusamus et iusto odio as part dignissimos ducimus qui blandit."
              placeholder="example@gmail.com"
            />
          </Div>
          <Div className="cs-side_header_box">
            <SocialWidget />
          </Div>
        </Div>
      </Div>
    </>
  );
}
