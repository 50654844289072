// LoginForm.js
import React, { useState , useEffect} from 'react';
import { PostNoAuthRequest, addBodyClass,removeBodyClass } from '../../utills/common';
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';

const EmailVerify = () => {
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [IsSuccess, setIsSuccess] = useState(true);
    const navigate = useNavigate();
    const  { user_id } = useParams('user_id');
    const  { email } = useParams('email'); 
    const  { token } = useParams('token'); 
         

    useEffect( () => {
        try {  
            async function fetchData() {
                let EmailVerify = await PostNoAuthRequest("auth/email-verify",{ 'user_id':user_id, "email":email, "token":token });
                if (EmailVerify && EmailVerify.success) {
                    setIsSuccess(true)
                }else{
                    setMessage(EmailVerify.message)
                }
            }
            fetchData();
        } catch (error) {
            // setLoading(false); // Stop loading in case of error
            console.error(error);
        }
        
    }, []);

    
    const loginAgain = async (event)=>{
        event.preventDefault();
        navigate('/login');
    }
  return (
    <div className="nk-body bg-white npc-general pg-auth" >
       <div className="nk-app-root">
        <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content ">
                    <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                        <div className="card card-bordered">
                            {       (IsSuccess) ?

                                <div class="card-inner card-inner-lg">
                                    <div class="nk-block-head">
                                        <div class="nk-block-head-content text-center">
                                            <img class="logo-icon mb-4" src="/images/email.svg" alt="logo" />
                                            <h4 class="nk-block-title mb-2">Your Email Verification is Completed Successfully.</h4>
                                                             
                                        </div>
                                    </div>
                                    <div class="text-center"><a class="btn btn-md btn-primary" onClick={loginAgain} href="#">Login</a></div>
                                    <div class="form-note-s2 text-center mt-4"> Need help? <a href="mailto:info@naughtynewzealand.co.nz"><strong>Contact Us</strong></a></div>
                                    
                                </div>

                            : {message}
                            
                            }

                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default EmailVerify;
