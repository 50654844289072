
import React, { useState , useEffect} from 'react';
import Header from './Header/index.jsx'
import Content from './Content/index.jsx'
// import { useAuth } from '../../auth/AuthContext';
import { useNavigate } from "react-router-dom";
import { PostAuthRequest, getRecords, PostNoAuthRequest } from '../../utills/common'; //, addBodyClass, removeBodyClass


export default function Profile() {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    let user_id = JSON.parse(localStorage.getItem('user')).id;
    const [UserProfile, setUserProfile] = useState('');
    const [IsActivated, setIsActivated] = useState('');
    const [ClubsData, setClubsData] = useState('');
    const [RegionsData, setRegionsData] = useState('');
    const [ServicesList, setServicesList] = useState("");
    const [Notifications, setNotifications] = useState('');
    if (user==null) {
        navigate('/login'); // User not logged in
    }

    useEffect(() => {
        async function fetchData() {  

            let Userdetail = await PostAuthRequest("get-profile-detail",{'user_id':user_id});
            let ClubsDataGet = await getRecords("club/get-clubs");
            let RegionsDataGet = await PostNoAuthRequest("get-regions");
            let servicesData = await getRecords("service/get-services");
            //user_id =77;
            let Notifications = await PostAuthRequest("user/get-notification-list",{'user_id':user_id});

            //console.log(" Notifications ", Notifications);
            if (Notifications?.success) {
                setNotifications(Notifications.data);
            }

            if (RegionsDataGet.success) {
                setRegionsData(RegionsDataGet.data);
            }

            if (ClubsDataGet.success) {
                setClubsData(ClubsDataGet.data);
            }

            if (servicesData.success) {
                setServicesList(servicesData.data);
            }
             
            // console.log("User data ", Userdetail);
            if (Userdetail?.success) {
                setUserProfile(Userdetail.data)
                if(Userdetail?.data.is_public){
                    setIsActivated('Activated') 
                                     
                }else{     
                    setIsActivated('Not Activated')                    
                }
                // setLoading(false);
                // removeBodyClass('MyLoaderClass');
            }else{
                // setLoading(false);
                // removeBodyClass('MyLoaderClass');
            }      
          }
          fetchData();        
    }, []);

    return (
        <>
            { user!=null &&
                <div className="nk-body npc-invest bg-lighter ">
                <div className="nk-app-root">       
                    <div className="nk-wrap ">
                        <Header name={user && user.name} IsActivated={IsActivated} UserProfile={UserProfile} Notifications={Notifications} />
                        <Content name={ user && user.name} clubs={ClubsData} regions={RegionsData && RegionsData } ServicesList = {ServicesList} />
                    </div>
                </div>
                

            </div>
            }       
        </>        
    )
}

