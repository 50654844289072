import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import './portfolio.scss';

export default function PortfolioAgencyList({href, src, name, location, variant, description }) {
  return (
    
    <Div className={`cs-port-agency cs-bg ${variant ? variant : 'cs-style1'}`}>
      <>
        
        {/* Add class "active" to selected favourite */}
        {/* <Div className="heart-ico">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.597" height="19.513" viewBox="0 0 20.597 19.513">
          <path id="Icon_ionic-md-heart" data-name="Icon ionic-md-heart" d="M12.673,21.666,11.325,20.45c-4.789-4.4-7.95-7.25-7.95-10.8A5.057,5.057,0,0,1,8.489,4.5a5.5,5.5,0,0,1,4.184,1.965A5.5,5.5,0,0,1,16.857,4.5a5.057,5.057,0,0,1,5.114,5.145c0,3.555-3.161,6.408-7.95,10.8Z" transform="translate(-2.375 -3.5)" fill="none" stroke="#fff" stroke-width="2"/>
        </svg>
        </Div> */}

        {/* Call Mail Buttons */}
      
        <Link to={href}>
          {/* Verified User */}
      
          <Div
            className="cs-portfolio_bg cs-bg"
            style={{ backgroundImage: `url("${src}")` }}
          />
          <Div className="cs-portfolio_info">            
            <Div className="cs-portfolio_info_bg cs-accent_bg" />
            {/* <Div className="cs-portfolio_subtitle available"> */}
              {/* <span className='me-1'>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                  <g id="Group_60" data-name="Group 60" transform="translate(-158 -1169)">
                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="6" cy="6" r="6" transform="translate(158 1169)" fill="#5ccc17" opacity="0.34"/>
                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="4" cy="4" r="4" transform="translate(160 1171)" fill="#5ccc17" opacity="0.37"/>
                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="2" cy="2" r="2" transform="translate(162 1173)" fill="#5ccc17"/>
                  </g>
                </svg>
              </span> */}
              {/* {available} */}
              {/* {available && available.map((x, i) => {x} )} */}
            {/* </Div> */}
            <h2 className="cs-portfolio_title">{name}</h2>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> */}
            
            {/* <Div className="cs-portfolio_subtitle">{location}</Div> */}
          </Div>
        </Link>
      </>
      </Div>
  );
}
