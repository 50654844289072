import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import CustomCursor from '../CustomCursor';
import Footer from '../Footer';
import Header from '../Header';
import { PostNoAuthRequest } from "../../utills/common";


export default function Layout({ headerVariant }) {
  let regionsData ='';
    const [RegionList, setRegionList] = useState('');
    // const [loading, setLoading] = useState(false)

    const [Title, setTitle] = useState('');
    const [CopyRight, setCopyRight] = useState('');
    const [FooterContent, setFooterContent] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    // setLoading(true);   
    async function fetchData() {

      let SettingData = await PostNoAuthRequest('get-site-setting');
      if (SettingData.success) {
        setCopyRight(SettingData.data?.copyright);
        setTitle(SettingData.data?.footer_title);
        setFooterContent(SettingData.data?.footer);
      }

      regionsData = await PostNoAuthRequest("get-regions");
      if (regionsData.success) {
        setRegionList(regionsData.data);
        // setLoading(false); 
      }else{
        // setLoading(false);    
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <React.Fragment>
            <Header variant={headerVariant} RegionList={RegionList} /> 
        <Outlet />
        {/* <CustomCursor /> */}
            <Footer Title={Title}  CopyRight={CopyRight} FooterContent={FooterContent} />
      </React.Fragment>
    </>
  );
}
