// LoginForm.js
import React, { useState } from 'react';
import { useAuth } from '../../auth/AuthContext';
import { login, setUserSession, PostNoAuthRequest } from '../../utills/common';
import { useNavigate, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";


const ForgetPassword = () => {      
  const { uselogin,  user, token } = useAuth();
  const navigate = useNavigate();
  const {reset, register, handleSubmit, formState: { errors, isDirty, isSubmitting, isValid } } = useForm();
  const [passwordType, setPasswordType] = useState("password");  
  const [message, setMessage] = useState('');
  const [sendTxt, setSendTxt] = useState('Send Reset Link');
  const [IsEmailExists, setIsEmailExists] = useState(true);
  const [DisbaleLink, setDisbaleLink]     = useState(false); 
  const [IsInvalidEmail, setIsInvalidEmail] = useState(false);

  const handleSendLink = async (data) => {      
    setSendTxt('Sending..')
    try {
        let EmailVerify = await PostNoAuthRequest("auth/forgot-password",{ "email":data.email });
        if (EmailVerify && EmailVerify.success) {
            setMessage(EmailVerify.message)
            setSendTxt('Resend Again')
        }else{
            setMessage(EmailVerify.message)
        }
        
    } catch (error) {
      console.log("error ", error);
    }
  };

  const togglePassword =(event)=>{
    event.preventDefault();
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }

  const handleChange = async  (event) => {
    let email = event.target.value;
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
   
    if(re.test(email)){
        setIsInvalidEmail(false)
    }else{
        setIsInvalidEmail(true)
    }
    try {
        let EmailVerify = await PostNoAuthRequest("auth/check-email",{ "email":email });
        if (EmailVerify && EmailVerify.success && EmailVerify.data.exists) {
            setIsEmailExists(true);
            setDisbaleLink(false);
        }else{
            setIsEmailExists(false);
            setDisbaleLink(true);
        }
        
    } catch (error) {
      console.log("error ", error);
    }

  };

  return (
     <>
            {
            
                (
                    <div className="nk-body bg-white npc-general pg-auth" >      
                    <div className="nk-app-root">
                    <div className="nk-main ">
                        <div className="nk-wrap nk-wrap-nosidebar">
                            <div className="nk-content ">
                                <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                                    <div className="card card-bordered">
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head">
                                                <div className="nk-block-head-content text-center">
                                                    <img className="logo-icon mb-3" src="./images/logo-icon.svg" alt="logo" />
                                                    <h4 className="nk-block-title">Forget Password</h4>
                                                    <div className="nk-block-des">
                                                        <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit(handleSendLink)}>
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="email">Email</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" className="form-control form-control-lg" id="email" placeholder="Enter your email" {...register("email", { 
                                                            required: true, 
                                                            onChange: (e) => { handleChange(e) },
                                                            onBlur: (e) => { handleChange(e) },
                                                            pattern: {
                                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: 'Invalid email address',
                                                        } })}  />
                                                        {/* {errors.email && <p>Email is required.</p>} */}
                                                        {errors.email?.type === "required" && <span className="invalid" >Email is required.</span>}
                                                        { (errors.email?.type === "pattern" ) || IsInvalidEmail && (
                                                            <span className="invalid" >Invalid email.</span>
                                                        )}
                                                       
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <button className={ (DisbaleLink)? "btn btn-lg btn-primary btn-block disabled-link" : "btn btn-lg btn-primary btn-block "} type  >{sendTxt}</button>
                                                </div>
                                                <div className="form-group">
                                                    {message}
                                                    {
                                                        (!IsEmailExists)? <span className="invalid" >Email does not exists.</span> : ""
                                                    }
                                                </div>
                                            </form>
                                            <div className="form-note-s2 text-center pt-4"> Back to? <NavLink to="/login"><strong>Login</strong></NavLink>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                )
            }
        </>
  );
  
};

export default ForgetPassword;
