import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, useLocation } from 'react-router-dom';
 import 'bootstrap/dist/css/bootstrap.min.css';
 import "slick-carousel/slick/slick.css"; 
 import './scss/index.scss';

// let location = useLocation();
// if(location.pathname!="/login" || location.pathname!="/profile"){
  // require('bootstrap/dist/css/bootstrap.min.css');
  // require('slick-carousel/slick/slick.css');
  // require('./scss/index.scss');
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

