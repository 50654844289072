// LoginForm.js
import React, { useState } from 'react';
import { useAuth } from '../../auth/AuthContext';
import {  PostNoAuthRequest } from '../../utills/common';
import { useNavigate, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";

const ResetPassword = () => {
  const { uselogin } = useAuth();
  const [message, setMessage] = useState('');  
  const [passwordType, setpasswordType] = useState('password');
  const [confirmPwType, setconfirmPwType] = useState('password');
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors }} = useForm();
  const ChangePassword = async (data) => {
    try { 
        if(data.password_confirmation!='' && data.password!=''){
            let EmailVerify = await PostNoAuthRequest("auth/change-password",{ "password":data.password, 'password_confirmation':data.password_confirmation});
            if (EmailVerify && EmailVerify.success) {
                setMessage(EmailVerify.message)
                navigate('/reset-password')
            }else{
                setMessage(EmailVerify.message)
            }
        }
        
    } catch (error) {
      console.error(error.message);
    }
  };
      
  const togglePassword =()=>{
    if(passwordType==="password")
    {
        setpasswordType("text")
        return;
    }
    setpasswordType("password")
}

const toggleConfirmPassword =()=>{
    if(confirmPwType==="password")
    {
        setconfirmPwType("text")
        return;
    }
    setconfirmPwType("password")
}
  return (
    <div className="nk-body bg-white npc-general pg-auth" >
       <div className="nk-app-root">
        <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content ">
                    <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                        <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">
                                <div className="nk-block-head">
                                    <div className="nk-block-head-content text-center">
                                        <img className="logo-icon mb-3" src="/images/logo-icon.svg" alt="logo" />
                                        <h4 className="nk-block-title">Reset Your password</h4>
                                        <div className="nk-block-des">
                                            <p>Ensure your password consists of a minimum of 8 characters, incorporating a combination of numbers and letters.</p>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(ChangePassword)}>
              
                                    <div class="form-group">
                                        <label class="form-label" for="password">Password</label>
                                        <div class="form-control-wrap">
                                            <a onClick={togglePassword} class="form-icon form-icon-right passcode-switch lg" data-target="password">                                                        
                                                { passwordType==="password"? <em class="passcode-icon icon-show icon ni ni-eye"></em> : <em class="passcode-icon icon-hide icon ni ni-eye-off"></em> }                                                        
                                            </a>
                                            <input type={passwordType} class="form-control form-control-lg" id="password" placeholder="Enter your passcode" required   {...register("password", { required: 'Field is required' })} />
                                            {errors.password && <p>Password is required.</p>}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="form-label" for="password_confirmation">Confirm Password</label>
                                        <div class="form-control-wrap">
                                            <a onClick={toggleConfirmPassword} class="form-icon form-icon-right passcode-switch lg" data-target="password_confirmation">
                                            { confirmPwType==="password"? <em class="passcode-icon icon-show icon ni ni-eye"></em> : <em class="passcode-icon icon-hide icon ni ni-eye-off"></em> }
                                            </a>
                                            <input type={confirmPwType} class="form-control form-control-lg" id="password_confirmation" placeholder="Confirm your passcode"  {...register("password_confirmation", { required: 'Field is required' })} />
                                            {errors.password_confirmation && <p>Confirm password is required.</p>}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-lg btn-primary btn-block" >Reset</button>
                                    </div>
                                    <div className="form-group">
                                        {message}
                                    </div>
                                </form>
                                <div className="form-note-s2 text-center pt-4"><NavLink to="/login"><strong>Login</strong></NavLink>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default ResetPassword;
