import React, { useState, useEffect, useRef } from "react";
import { PostAuthRequest } from "../../../../utills/common";
import { useForm } from "react-hook-form";
import { ProfileSidebar } from './ProfileSidebar';

import { Editor } from "@tinymce/tinymce-react"




export function PersonalDetail({
  user_id,
  setActiveTab,
  NextStep,
  PrevStep,
  currentStep,
  profileData,
  notify,
  DisplayName,
  clubs,
  regions,
  NotifySomeError
}) {
  const [suburbs, setSuburbs] = useState({});
  // const [clubs, setClubs] = useState({});
  const [isClub, setIsClub] = useState();
  const [ErrorClass, setErrorClass] = useState(false);
  const [SelectedResion, setSelectedResion] = useState("");
  const [SelectedSuburb, setSelectedSuburb] = useState("");  
  const [UserDetail, setUserDetail] = useState('');
  const [ClubSelected, setClubSelected] = useState('');
  const [gender, setGender] = useState(false);
  const [wordCount, setWordCount] = useState(false);
  const [AgeError, setAgeError] = useState(false);
  const [DisbaleLink, setDisbaleLink]     = useState(false);

  const {reset, register, handleSubmit, formState: { errors, isDirty, isSubmitting, isValid } } = useForm();
  let regionOpt = "";
  let suburbOpt = "";
  let clubOpt = "";
  
  const [desc, setDesc] = useState(profileData?.description ?? '');

  const editorRef = useRef(null);
  const [dirty, setDirty] = useState(false);
  useEffect(() => setDirty(false), [profileData?.description]);

  useEffect(() => setDesc(profileData?.description ?? ''), [profileData?.description]);

  const onErrors = errors => { //console.error(errors) 
  };

  const errorBorderClass = {
    border: '1px solid red;'
  };
  
  useEffect(() => {
    let defaultValues = {};
    try {
      async function fetchData() {
        if(profileData?.region_id!=undefined){
          let cityData = await PostAuthRequest("get-suburbs",{'region_id': profileData?.region_id});
          if (cityData.success) {
            setSuburbs(cityData.data); 
          }  
        }   
        if(profileData?.profile_type ==="club"){
          setIsClub(true);
        }
        // if (profileData.success) {
          setUserDetail(profileData);

          defaultValues.profile_name = ( profileData?.profile_name)? profileData.profile_name : DisplayName;
          defaultValues.phone = ( profileData?.phone)? profileData.phone : "";
          defaultValues.description = (profileData?.description)? profileData.description : "";
          defaultValues.age = ( profileData?.age)? profileData.age : "";
          defaultValues.height = ( profileData?.height)? profileData.height : "";
          defaultValues.eyes_color = ( profileData?.eyes_color)? profileData.eyes_color : "";
          defaultValues.bust_size = ( profileData?.bust_size)? profileData.bust_size : "";
          defaultValues.hair_color = ( profileData?.hair_color)? profileData.hair_color : "";
          defaultValues.body_type = ( profileData?.body_type)? profileData.body_type : "";
          defaultValues.nationality = ( profileData?.nationality)? profileData.nationality : "";
          defaultValues.language = ( profileData?.language)? profileData.language : "";
          defaultValues.fan_link = ( profileData?.fan_link)? profileData.fan_link : "";
          defaultValues.club_id = ( profileData?.club_id)? profileData.club_id : "";
          defaultValues.region_id = ( profileData?.region_id)? profileData.region_id : "";
          defaultValues.city_id = ( profileData?.city_id)? profileData.city_id : "";
          defaultValues.gender = ( profileData?.gender)? profileData?.gender : "";

          setGender(profileData?.gender)

          newWordCount(defaultValues.description)

          reset({ ...defaultValues });
          setClubSelected(profileData?.club_id)

          // 
        // }
      }
      fetchData();

      
    } catch (error) {
      // setLoading(false); // Stop loading in case of error
      console.error(error);
    }
  }, [profileData, reset]);

  // const log = () => {
  //   if (editorRef.current) {
  //     console.log("dddd dd",editorRef.current);
  //   }
  // };

  const newWordCount = (value) => {
   let content = value.replace(/(\r\n|\n|\r)/gm, "");
    content = content.replace(/<[^>]+>/g, ' ');
    setWordCount(content.trim().split(/\s+/).length)
  }

const handleSave = async (formData) => {
    try {
        
      if (editorRef.current.getContent()) {
        let content = editorRef.current.getContent();
        setDirty(false);
        editorRef.current.setDirty(false);  
      }

      if (editorRef.current.getContent()) {
        formData.description = editorRef.current.getContent()
        setErrorClass(false)
      }else{
        errors.description=true;
        setErrorClass(true)
        NotifySomeError("About Yourself field is required."); // Notify the Save data
        return false;
      }
      

      // if(wordCount<150){
      //   errors.description=true;
      //   setErrorClass(true)
      //   NotifySomeError("Write About Yourself in 150 Words."); // Notify the Save data
      //   return false;
      // }
    


      // if(isValid){
        const Res = await PostAuthRequest('user/save-profile', formData);
        if(Res.success){
          handleNotify(); // Notify the Save data
         handleNext();
        }else{
          console.error(Res.message);
        }       
      // }      
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleNext = async (e) => {
    try {
      NextStep();
    } catch (error) {
      console.error(error.message);
    }
  };

  const handlePrev = (e) => {
    PrevStep();
  };

  const handleNotify= (e) => {
    notify("Profile step saved successfully."); // Notify the Save data
  }

    if (regions?.length > 0) {
        regionOpt = regions?.map((region, i) => (
        <option key={i} value={region.id} selected={( profileData?.region_id ===region.id ) ? true : false }>
            {region.name}
        </option>
        ));
    }

    if (suburbs?.length > 0) {
        suburbOpt = suburbs?.map((suburb, i) => (
        <option key={i} value={suburb.id} selected={( profileData?.city_id===suburb.id ) ? true : false }>
            {suburb.name}
        </option>
        ));
    }
  
    if (clubs?.length > 0) {
        clubOpt = clubs?.map((club, i) => (          
          //  
        <option key={i} value={club.id} selected={( profileData?.club_id === club.id) ? true : false }>  
            {club.name}
        </option>
        ));
    }


    const handleRegionChange = async  event => {
      let value = event.target.value;
      setSelectedResion(event.target.value);
      let cityData = await PostAuthRequest("get-suburbs",{'region_id':value});
        
        if (cityData.success) {
          setSuburbs(cityData.data);
        }
    };

    const handleSuburbChange = (value) => {
      setSelectedSuburb(value);
    };

    const onTypePersonal = function (e) {
      setIsClub(false)
    }
    
    const onTypeClub = function (e) {
      setIsClub(true)
    }
    
  const isChecked = function(item1, item2){    
      if(item2===undefined){
        return false
      }

      if(item1==item2){
        return 'true';
      }
      return false;
  }  

  const handleChange = async  (event) => {
    let age = event.target.value;
    
    if(!isNaN(+age) && age < 18){
      setAgeError(true)
      setDisbaleLink(true);
    }else{
      setAgeError(false)
      setDisbaleLink(false);
    }
  };

  return (
    <>




      <form
        action="#"
        className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix"
        id="wizard-02"
        role="application"
      >
        <ProfileSidebar setActiveTab={setActiveTab} currentStep={currentStep} profileData={profileData} />

        <div className="content clearfix">
          <div className="nk-wizard-head title current" id="wizard-02-h-0">
            <div className="step-text">
              <div className="lead-text">Personal Details</div>
              <div className="sub-text">Enter the Personal information</div>
            </div>
          </div>
          <div
            className="nk-wizard-content body current"
            id="wizard-02-p-0"
            role="tabpanel"
            aria-labelledby="wizard-02-h-0"
            aria-hidden="false"
          >
            <div className="mb-5">
              <h5 className="title mb-2 text-primary">Personal Details</h5>
              <p className="text-soft">
                All details in this section are accessible to the public through
                your profile.
              </p>
            </div>
            <div className="row gy-4">
              { profileData?.profile_step!=-1 && <>
                <div className="col-md-12">
                  <div className="nk-stepper-step ">
                    <div className="form-group">
                      <label className="form-label">Choose Profile Type *</label>
                      <ul className="row g-3 text-center">
                        <li className="col-sm-6">
                          <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="profile_type"
                              value="personal"
                              {...register("profile_type", {
                                  required: "Field is required",
                                })}
                              onChange={onTypePersonal}
                              defaultChecked={ profileData && isChecked("personal", profileData?.profile_type) }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="profile_type"
                            >
                              <span className="d-flex flex-column text-center px-sm-3">
                                <em className="icon icon-circle icon-circle-lg bg-teal ni ni-user"></em>
                                <span className="lead-text mb-1 mt-3">
                                  Personal Profile 
                                </span>
                                <span className="sub-text">
                                  Create your personal profile
                                </span>
                              </span>
                            </label>
                          
                          </div>
                        </li>
                        <li className="col-sm-6">
                          <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full">
                            <input
                              type="radio"
                              className="custom-control-input"
                              name="cp1-profile-type"
                              id="profile_type_club"
                              value="club"
                              {...register("profile_type", {
                                  required: "Field is required",
                                })}
                              onChange={onTypeClub}
                              // checked={( profileData?.profile_type=='club') ? true : false }
                              defaultChecked={ profileData && isChecked("club", profileData?.profile_type) }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="profile_type_club"
                            >
                              <span className="d-flex flex-column text-center px-sm-3">
                                <em className="icon icon-circle icon-circle-lg bg-orange ni ni-briefcase"></em>
                                <span className="lead-text mb-1 mt-3">
                                  Club/Agency Profile
                                </span>
                                <span className="sub-text">
                                  Create your agency profile
                                </span>
                              </span>
                            </label>
                          </div>
                          {errors.profile_type && <span className="invalid" >This field is required.</span>}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                </>
              }
                { 
                  (isClub) &&
                  <div className="col-sm-12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="clubagency">
                            Select Your Club/Agency *
                        </label>
                        <div className="form-control-wrap ">
                            <div className="form-control-select">
                                <select
                                  className="form-control valid"
                                  id="clubagency"
                                  // aria-describedby="clubagency-error"
                                  // defaultValue={ClubSelected && ClubSelected}
                                  // aria-invalid="false"
                                  {...register("club_id", {
                                      required: "Field is required",
                                  })}                     
                                  >
                                <option value="" disabled>Select Club</option>
                                {clubOpt}
                              </select>
                              {errors.club_id && <span className="invalid" >This field is required.</span>}

                            </div>
                        </div>                        
                      </div>
                  </div>
                }
                
                <div className="col-sm-12">
                  <hr className="preview-hr" />
                  <span className="preview-title-lg overline-title">
                    Personal Information
                  </span>
                </div>
                
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="gender">
                    Select Gender *
                  </label>
                  <div className="form-control-wrap custom-control">
                    <ul className="custom-control-group">
                      <li>
                        <div className="custom-control custom-radio custom-control-sm custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="sex-female"
                            value="female"
                            {...register("gender", {
                              required: "Field is required",
                            })}
                            // defaultChecked={ profileData && profileData?.gender === 'female' }
                            // checked={ profileData && profileData?.gender === 'female' }
                            // defaultChecked={()=>{ profileData && isChecked("female", profileData?.gender) } }
                            checked={( gender === 'female') ? 'true' : false }
                            onChange={(e) => setGender(e.target.value)} 
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="sex-female"
                          > 
                            Female
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-sm custom-control-pro">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="sex-male"
                              value="male"
                              {...register("gender", {
                                    required: "Field is required",
                              })}
                              // defaultChecked={ profileData && profileData?.gender === 'male' }
                              // checked={ profileData && profileData?.gender === 'male' }
                              // defaultChecked={ profileData &&  isChecked("male", profileData?.gender) }
                              // defaultChecked={()=>{ profileData && isChecked("male", profileData?.gender) } }
                              checked={( gender === 'male') ? 'true' : false }
                              onChange={(e) => setGender(e.target.value)}
                            />
                          <label
                            className="custom-control-label"
                            htmlFor="sex-male"
                          > 
                            Male
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-sm custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="sex-other"
                            value="other"
                            {...register("gender", {
                                required: "Field is required",
                              })}
                                                            
                              // defaultChecked={ profileData && profileData?.gender === 'other' }
                              // checked={ profileData && profileData?.gender === 'other' }
                              checked={( gender === 'other') ? 'true' : false }
                              onChange={(e) => setGender(e.target.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="sex-other"
                          >
                            Others
                          </label>
                        </div>
                      </li>
                      {errors.gender && <span className="invalid" >This field is required.</span>}
                    </ul>                    
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="profile_name">
                    Profile Name *
                  </label>
                  <div className="form-control-wrap ">
                    <input
                      type="text"
                      className="form-control valid"
                      id="profile_name"
                      placeholder="e.g. Jazzy public view"
                      {...register("profile_name", {
                        required: "Field is required",
                        minLength: 3,
                        // maxLength: 10
                      })}                      
                    />
                    {errors.profile_name && <span
                      id="phone-error"
                      className="invalid"                      
                    >This field is required.</span>}
                    
                    {/* <input type="text" className="form-control valid" id="profile_name" placeholder="e.g. Jazzy htmlFor public view" required="" aria-invalid="false" /> */}
                  </div>
                  <span className="form-note">
                    Choose your working name (min. 3 characters)
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Phone Number *
                  </label>
                  <div className="form-control-wrap ">
                    <input
                      type="tel"
                      className="form-control valid"
                      id="phone"
                      placeholder="(02) 9876 5432"
                      aria-describedby="phone-error"
                      aria-invalid="false"
                      {...register("phone", {
                        required: "Field is required", 
                        // pattern:/^[0-9+-]+$/, 
                        // minLength: 6, 
                        // maxLength: 12
                        
                      })}
                     // defaultValue={( profileData?.phone)? UserDetail.phone : ""}
                    />
                     {errors.phone && <span
                      id="phone-error"
                      className="invalid"                      
                    >This field is required.</span>}
                    
                  </div>
                  <span className="form-note">
                    The number posted on your profile to receive bookings
                    through
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="region_id">
                    Select Region *
                  </label>
                  <div className="form-control-wrap ">
                    <div className="form-control-select">
                      <select onChange={handleRegionChange} className="form-control valid" 
                      {...register('region_id', { required: "Field is required" , onChange: e => handleRegionChange(e) })}
                      >
                        <option value="">Select region</option>
                        { regionOpt }
                      </select>
                      
                      {errors.region_id && <span className="invalid" >This field is required.</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="city">
                    Select Suburbs
                  </label>
                  <div className="form-control-wrap ">
                    <div className="form-control-select">
                      <select
                        className="form-control valid"
                        onChange={handleSuburbChange}
                        id="city"
                        aria-invalid="false"
                        {...register("city_id", {
                            required: "Field is required",
                          })}
                      >
                        <option value="">Select Suburb</option>
                        {suburbOpt}
                      </select>
                      {errors.city_id && <span className="invalid" >This field is required.</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="about">
                    Provide information about yourself (150 to 500 words)*
                  </label>
                  <div className="form-control-wrap">
                  <Editor
                      apiKey="tes3zg0xii4lla4i3t1vjd8f4p2tjmqub4l57zhrgttkit7y" //{process.env.TinyMCE_KEY} // //
                      // apiKey="uqp2csj0g406ewnu6i9lnsnzqzwtw2ng8z84l3htnvyvjkf0"
                      onInit={(evt, editor) => editorRef.current = editor}
                      onDirty={() => setDirty(true)}
                      id='description'                  
                      value={desc}
                      onEditorChange={(newValue, editor) => {
                        setDesc(newValue)
                        newWordCount(newValue)                        
                      }}
                      initialValue={profileData?.description}
                      className={errorBorderClass}
                      {...register("description", {
                        required: {
                          value:false,
                          message: "Field is required"
                        }, // and  & Max Length 500
                        // maxLength: {
                        //   value: 1000,
                        //     message: "Max Length 500"
                        // }
                        })}
                      init={{           
                        toolbar: 'paste',
                        paste_as_text: true,             
                        height: 300,
                        menubar: false,
                        // statusbar: false,
                        branding: false,
                        selector: "textarea",
                        placeholder: "Hi, guys 😊 💋 I'm an adventurous individual with a vibrant personality, flaunting golden locks and captivating blue eyes. I provide an array of exciting and sensual experiences, always eager to delve into the realms of intimacy. Feel free to inquire about the diverse range of services I offer—I'm passionate about exploring desires and ensuring a memorable encounter.",
                        plugins: [
                          "ai preview powerpaste casechange footnotes tinycomments searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed advtemplate codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker mergetags a11ychecker editimage help formatpainter permanentpen pageembed charmap quickbars linkchecker emoticons advtable export mentions typography markdown importword",
                        ],
                        toolbar:
                          "undo redo | importword | aidialog aishortcuts | blocks fontsizeinput | bold italic | align numlist bullist | link image | table media pageembed | lineheight  outdent indent | strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | code fullscreen preview | save print export | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck",
                        importword_service_url: "add.url.here",
                        templates: [],
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }",
                      }}
                    />
                    <span className="form-note">
                    { (wordCount > 0 ) ? "Total words you have written : "+wordCount : 'You have not written about your self.' }
                    </span>
                    {/* {ErrorClass && <span
                      id="about-error"
                      className="invalid"
                    >Minimum 150 words required in about yourself field.</span>} */}

                    {/* <textarea
                      className="form-control textarea-md invalid"
                      // minLength="150"
                      id="editor_description"
                      placeholder="Hi, guys 😊 💋 I'm an adventurous individual with a vibrant personality, flaunting golden locks and captivating blue eyes. I provide an array of exciting and sensual experiences, always eager to delve into the realms of intimacy. Feel free to inquire about the diverse range of services I offer—I'm passionate about exploring desires and ensuring a memorable encounter."
                      required=""
                      aria-describedby="about-error"
                      aria-invalid="true"
                      {...register("description", {
                        required: {
                          value:true,
                          message: "Field is required"
                        }, // and  & Max Length 500
                        maxLength: {
                          value: 150,
                          message: "Min Length 150"
                       },
                        maxLength: {
                          value: 500,
                          message: "Max Length 500"
                       }
                      })}
                    ></textarea> */}
                    {/* {dirty && <p>You have unsaved content!</p>} */}
                    

                  </div>
                  <span className="form-note">
                    Most important thing, write a good and unique profile. With
                    minimum of 150 letters. Please see sample above:
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="age">
                    Age *
                  </label>
                  <div className="form-control-wrap ">
                    <input
                      type="text"
                      className="form-control valid"
                      id="age"
                      placeholder="e.g. 37"
                      required=""
                      aria-invalid="false"
                      {...register("age", {
                        required: "Field is required",
                        onChange: (e) => { handleChange(e) },
                        onBlur: (e) => { handleChange(e) },
                      })}
                    />
                    {errors.age && <span
                      id="about-error"
                      className="invalid"
                    >This field is required.</span>}
                    {AgeError && <span
                      id="about-error"
                      className="invalid"
                    >Age should be number and greater than 18 years.</span>}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="height">
                    Height
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control valid"
                      id="height"
                      placeholder="e.g. 180cm"
                      aria-invalid="false"
                      {...register("height", {
                        required: false,
                      })}
                     // defaultValue={( profileData?.height)? UserDetail.height : "" }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="eyescolor">
                    Eyes Color
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control valid"
                      id="eyescolor"
                      placeholder="ee.g. Brown"
                      aria-invalid="false"
                      {...register("eyes_color", {
                        required: false,
                      })}
                     // defaultValue={( profileData?.eyes_color)? UserDetail.eyes_color : "" }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="bustsize">
                    Bust Size
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control valid"
                      id="bustsize"
                      placeholder="e.g. Natural C"
                      aria-invalid="false"
                      {...register("bust_size", {
                        required: false,
                      })}
                      //defaultValue={( profileData?.bust_size)? UserDetail.bust_size : "" }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="haircolor">
                    Hair Color/Style
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control valid"
                      id="haircolor"
                      placeholder="e.g. Long Brown"
                      aria-invalid="false"
                      {...register("hair_color", {
                        required: false,
                      })}
                      // defaultValue={( profileData?.hair_color)? UserDetail.hair_color : "" }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="bodytype">
                    Body Type
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control valid"
                        id="bodytype"
                        aria-invalid="false"
                        {...register("body_type", {
                            required: false,
                          })}
                      >
                        <option value=""> Please Select</option>
                        <option value="Athletic" selected={( profileData?.body_type ==="Athletic") ? true : false } > Athletic</option>
                        <option value="Busty" selected={( profileData?.body_type ==="Busty") ? true : false }> Busty</option>
                        <option value="Curvy" selected={( profileData?.body_type ==="Curvy") ? true : false } > Curvy</option>
                        <option value="Thicc" selected={( profileData?.body_type ==="Thicc") ? true : false } > Thicc</option>
                        <option value="Slim" selected={( profileData?.body_type ==="Slim") ? true : false } > Slim</option>
                        <option value="Petite" selected={( profileData?.body_type ==="Petite") ? true : false } > Petite</option>
                        <option value="BBW" selected={( profileData?.body_type ==="BBW") ? true : false }> BBW</option>
                        <option value="Average" selected={( profileData?.body_type ==="Average") ? true : false }> Average</option>
                      </select>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="nationality">
                    Your Nationality
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control valid"
                      id="nationality"
                      placeholder=""
                      aria-invalid="false"
                      {...register("nationality", {
                        required: false,
                      })}
                     // defaultValue={( profileData?.nationality)? UserDetail.nationality : "" }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="nationality">
                    Your Languages
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control valid"
                      id="language"
                      placeholder=""
                      aria-invalid="false"
                      {...register("language", {
                        required: false,
                      })}
                     // defaultValue={( profileData?.language)? UserDetail.language : "" }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="gender">
                    Meeting with: Interested in providing the services to
                  </label>
                  <div className="form-control-wrap ">
                    <ul className="custom-control-group">
                      <li>
                        <div className="custom-control custom-radio custom-control-sm custom-control-pro checked">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="men"
                            value="men"
                            {...register("interested_in", {
                                required: false,
                              })}
                            defaultChecked={ profileData && profileData?.interested_in === 'men' }

                          />
                          <label className="custom-control-label" htmlFor="men">
                            Men
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-sm custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="women"
                            value="women"
                            {...register("interested_in", {
                                required: false,
                              })} 
                              defaultChecked={ profileData && profileData?.interested_in === 'women' }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="women"
                          >
                            Women
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-sm custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="groupsex"
                            value="groupsex"
                            {...register("interested_in", {
                                required: false,
                              })}
                              
                              defaultChecked={ profileData && profileData?.interested_in === 'groupsex' } 
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="groupsex"
                          >
                            Group Sex
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-sm custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="menwomen"
                            value="menwomen"
                            {...register("interested_in", {
                                required: false,
                              })}
                              defaultChecked={ profileData && profileData?.interested_in === 'menwomen' }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="menwomen"
                          >
                            Men and women
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-radio custom-control-sm custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="pairs"
                            value="pairs"
                            {...register("interested_in", {
                                required: false,
                              })}
                              defaultChecked={ profileData && profileData?.interested_in === 'pairs' }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="pairs"
                          >
                            Pairs
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="contactmethod">
                    Preferred contact method {profileData?.contact_method}
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control"
                        id="contactmethod"
                        {...register("contact_method", {
                            required: false,
                          })}
                      >
                        <option value="SMS_and_Phone" selected={( profileData?.contact_method ==="SMS_and_Phone") ? true : false } > SMS and Phone</option>
                        <option value="Phone_Only" selected={( profileData?.contact_method ==="Phone_Only") ? true : false } >Phone Only</option>
                        <option value="SMS_only" selected={( profileData?.contact_method ==="SMS_only") ? true : false } > SMS-only</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="fanslink">
                    Only Fans link (if you have)
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      id="fanslink"
                      placeholder=""
                      {...register("fan_link", {
                        required: false,
                      })}
                      //defaultValue={( profileData?.fan_link)? UserDetail.fan_link : "" }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="actions clearfix">
          <ul role="menu" aria-label="Pagination">
            {currentStep > 1 && (
              <li className="" aria-disabled="false">
                <a href="#previous" role="menuitem" onClick={handlePrev}>
                  Prev
                </a>
              </li>
            )}
            {currentStep < 7 && (
              <li aria-hidden="false" aria-disabled="false">
                <a
                  href="#next"
                  role="menuitem"
                  onClick={handleSubmit(handleSave, onErrors)}
                  className={ (DisbaleLink)? "disabled-link" : ""}
                >
                  Next
                </a>              
              </li>
            )}
            {currentStep === 7 && (
              <li aria-hidden="true">
                <a href="#finish" role="menuitem">
                  Activate My Account
                </a>
              </li>
            )}
          </ul>
        </div>
        <input type="hidden" value={user_id} {...register("user_id")} />
        <input type="hidden" value={currentStep} {...register("profile_step")} />
      </form>
    </>    
  );
}
