import React from 'react';
import { useAuth } from '../../auth/AuthContext';

const Dashboard = () => {
  const { user, token, logout } = useAuth();

  if (!user || !token) {
    return null; // User not logged in
  }

  return (
    <div>
      <button onClick={logout}>Logout</button>
      <h2>Welcome, {user}</h2>
      <p>Token: {token}</p>
      
    </div>
  );
};

export default Dashboard;
