
import React, { useState , useEffect} from 'react';
import { PostAuthRequest,  addBodyClass, removeBodyClass } from '../../../utills/common';
import { useForm } from "react-hook-form";
import { PersonalDetail } from './steps/PersonalDetail';
import { Availbility } from './steps/Availbility';
import { ExtraServices } from './steps/ExtraServices';
import { PriceInfo } from './steps/PriceInfo';
import { ProfileVerify } from './steps/ProfileVerify';
import { ReviewSubmit } from './steps/ReviewSubmit';
import { MyPhotos } from './steps/MyPhotos';
// import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Content({name, clubs, regions, ServicesList }) {    
    const [currentStep, setcurrentStep] = useState(1);
    const [completedStep, setcompletedStep] = useState(0);
    const {formState: { errors }} = useForm();
    let user_id = JSON.parse(localStorage.getItem('user')).id;
    // const navigate = useNavigate();
    const [profileInfo, setprofileInfo] = useState({});
    const [IsCompleted, setIsCompleted] = useState(false);
    const [loading, setLoading] = useState(true)
    const notify = (message) => toast.success(message);
    const MaximumFileUpload = () => toast.error("You can only upload 10 maximum files.");
    const NotifySomeError = (error) => toast.error(error);
    const  NextStep= async (e) =>{
        try {   
            UpdateProfileData();
            if(profileInfo.profile_step==-1){
                if(currentStep < 5){
                    setcurrentStep(currentStep + 1)
                    setcompletedStep(currentStep)  
                }
                
            }  else{
                setcurrentStep(currentStep + 1)
                setcompletedStep(currentStep)
            }                   
        } catch (error) {
        console.error(error.message);
        }
    };
    const PrevStep = (e) =>{
        UpdateProfileData();
        setcurrentStep(currentStep - 1)
    };
    const UpdateProfileData = async ()=>{
        let profiledetail = await PostAuthRequest("get-profile-detail",{'user_id':user_id}); // Update the recored in react View
        if (profiledetail?.success) {
            setprofileInfo(profiledetail.data)
            if(profiledetail.data.profile_step ==-1){
                setIsCompleted(true)                    
            }else{
                setIsCompleted(false)         
                // let newStep =  parseInt(profiledetail.data.profile_step)+ parseInt(1);
                // profiledetail.data.profile_step && setcurrentStep(newStep)                
            }
        }
    }

    const setActiveTab = async (e, tabId) =>{
        e.preventDefault();        
        UpdateProfileData();

        if(IsCompleted){
            setcurrentStep(tabId)
        }else{
            if(tabId<=completedStep){
                setcurrentStep(tabId)
            }
        }
    };

    useEffect(() => {
        async function fetchData() {  
            setLoading(true);
            addBodyClass("MyLoaderClass")      
            let profiledetail = await PostAuthRequest("get-profile-detail",{'user_id':user_id});
            if (profiledetail?.success) {
                setprofileInfo(profiledetail.data)
                if(profiledetail.data.profile_step ==-1){
                    setIsCompleted(true)                    
                }else{
                    setIsCompleted(false)         
                    let newStep =  parseInt(profiledetail.data.profile_step)+ parseInt(1);
                    profiledetail.data.profile_step && setcurrentStep(newStep)
                    
                }
                setLoading(false);
                removeBodyClass('MyLoaderClass');
            }else{
                setLoading(false);
                removeBodyClass('MyLoaderClass');
            }      
          }
          fetchData();        
    }, []);

    return (
      <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition= "Bounce"
        /> */}    
        <div className="nk-content nk-content-lg nk-content-fluid">
            <div className="container-xl wide-lg">
            <div className="nk-content-inner">
                <div className="nk-content-body">                
                    <div className="nk-block-head nk-block-head-lg pt-4">
                        <div className="nk-block-head-content text-center">                        
                            <h2 className="nk-block-title fw-normal">Hello, {name}</h2>
                            <div className="nk-block-des">
                                <p className="lead">Please complete all information in the fields below.</p>
                            </div>
                        </div>
                    </div>   
                    {
                        // loading ? (
                        // <div className="loader-container">
                        //     <div className="spinner"></div>
                        // </div>
                        // ) :
                            <>          
                                <div className="nk-block nk-block-lg">                        
                                    <div className="card card-bordered">
                                        <div className="card-inner p-0">                                            
                                            { currentStep==1 && <PersonalDetail user_id={user_id} setActiveTab={setActiveTab} NextStep={NextStep} PrevStep={PrevStep} currentStep={currentStep} profileData={profileInfo} notify ={ notify } DisplayName ={ name } regions ={ regions } clubs ={ clubs } NotifySomeError= {NotifySomeError} />   }   
                                            { currentStep==2 && <Availbility user_id={user_id} setActiveTab={setActiveTab} NextStep={NextStep} PrevStep={PrevStep} currentStep={currentStep} profileData={profileInfo} notify ={ notify }  NotifySomeError={ NotifySomeError } /> }
                                            { currentStep==3 && <PriceInfo user_id={user_id} setActiveTab={setActiveTab} NextStep={NextStep} PrevStep={PrevStep} currentStep={currentStep} profileData={profileInfo} notify ={ notify } />  }
                                            { currentStep==4 && <MyPhotos user_id={user_id} setActiveTab={setActiveTab} NextStep={NextStep} PrevStep={PrevStep} currentStep={currentStep} profileData={profileInfo} notify ={ notify }  MaximumFileUpload ={ MaximumFileUpload } NotifySomeError={ NotifySomeError }/> }  
                                            { currentStep==5 && <ExtraServices user_id={user_id} setActiveTab={setActiveTab} NextStep={NextStep} PrevStep={PrevStep} currentStep={currentStep} profileData={profileInfo} notify ={ notify } ServicesList={ServicesList} /> }
                                            { currentStep==6 && (IsCompleted == false) && <ProfileVerify user_id={user_id} setActiveTab={setActiveTab} NextStep={NextStep} PrevStep={PrevStep} currentStep={currentStep} profileData={profileInfo} notify ={ notify } NotifySomeError={ NotifySomeError } /> }
                                            { currentStep==7 && (IsCompleted == false ) && <ReviewSubmit user_id={user_id} setActiveTab={setActiveTab} NextStep={NextStep} PrevStep={PrevStep} currentStep={currentStep} profileData={profileInfo} notify ={ notify } /> }                              
                                        
                                        </div>                          
                                    </div>
                                </div> 
                            </>            
                    }      
                     <div className="nk-block">
                        <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">
                                <div className="align-center flex-wrap flex-md-nowrap g-4">
                                    <div className="nk-block-image w-120px flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 118">
                                            <path d="M8.916,94.745C-.318,79.153-2.164,58.569,2.382,40.578,7.155,21.69,19.045,9.451,35.162,4.32,46.609.676,58.716.331,70.456,1.845,84.683,3.68,99.57,8.694,108.892,21.408c10.03,13.679,12.071,34.71,10.747,52.054-1.173,15.359-7.441,27.489-19.231,34.494-10.689,6.351-22.92,8.733-34.715,10.331-16.181,2.192-34.195-.336-47.6-12.281A47.243,47.243,0,0,1,8.916,94.745Z" transform="translate(0 -1)" fill="#f6faff" />
                                            <rect x="18" y="32" width="84" height="50" rx="4" ry="4" fill="#fff" />
                                            <rect x="26" y="44" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                            <rect x="50" y="44" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                            <rect x="74" y="44" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                            <rect x="38" y="60" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                            <rect x="62" y="60" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                            <path d="M98,32H22a5.006,5.006,0,0,0-5,5V79a5.006,5.006,0,0,0,5,5H52v8H45a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H73a2,2,0,0,0,2-2V94a2,2,0,0,0-2-2H66V84H98a5.006,5.006,0,0,0,5-5V37A5.006,5.006,0,0,0,98,32ZM73,94v4H45V94Zm-9-2H54V84H64Zm37-13a3,3,0,0,1-3,3H22a3,3,0,0,1-3-3V37a3,3,0,0,1,3-3H98a3,3,0,0,1,3,3Z" transform="translate(0 -1)" fill="#1473ed" />
                                            <path d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z" transform="translate(0 -1)" fill="#1473ed" />
                                            <path d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z" transform="translate(0 -1)" fill="none" stroke="#1473ed" stroke-miterlimit="10" stroke-width="2" />
                                            <line x1="40" y1="22" x2="57" y2="22" fill="none" stroke="#fffffe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="40" y1="27" x2="57" y2="27" fill="none" stroke="#fffffe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="40" y1="32" x2="50" y2="32" fill="none" stroke="#fffffe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            <line x1="30.5" y1="87.5" x2="30.5" y2="91.5" fill="none" stroke="#1473ed" stroke-linecap="round" stroke-linejoin="round" />
                                            <line x1="28.5" y1="89.5" x2="32.5" y2="89.5" fill="none" stroke="#1473ed" stroke-linecap="round" stroke-linejoin="round" />
                                            <line x1="79.5" y1="22.5" x2="79.5" y2="26.5" fill="none" stroke="#1473ed" stroke-linecap="round" stroke-linejoin="round" />
                                            <line x1="77.5" y1="24.5" x2="81.5" y2="24.5" fill="none" stroke="#1473ed" stroke-linecap="round" stroke-linejoin="round" />
                                            <circle cx="90.5" cy="97.5" r="3" fill="none" stroke="#1473ed" stroke-miterlimit="10" />
                                            <circle cx="24" cy="23" r="2.5" fill="none" stroke="#1473ed" stroke-miterlimit="10" />
                                        </svg>
                                    </div>
                                    <div className="nk-block-content">
                                        <div className="nk-block-content-head px-lg-4">
                                            <h5>We’re here to help you!</h5>
                                            <p className="text-soft">Ask a question or file a support ticket, manage request, report an issues. Our team support team will get back to you by email.</p>
                                        </div>
                                    </div>
                                    <div className="nk-block-content flex-shrink-0">
                                        <a href="mailto:info@naughtynewzealand.co.nz" className="btn btn-lg btn-outline-primary">Get Support Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div> 
        <ToastContainer />
      </>
    );
}

export default Content;