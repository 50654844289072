import React, { useState , useEffect} from 'react';
import { PostAuthRequest, getRecords, PostUploadFiles } from '../../../../utills/common';
import { useForm } from "react-hook-form";
import { ProfileSidebar } from './ProfileSidebar';

export function ProfileVerify({user_id,setActiveTab, NextStep, PrevStep, currentStep,
    profileData, notify, NotifySomeError }){
    const { register, handleSubmit, formState: { errors, isDirty, isSubmitting, isValid } } = useForm();
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [pasport, setPasportStatus] = useState("");    // <"initial" | "uploading" | "success" | "fail">
    const [dla, setDLAStatus] = useState(""); 
    const [dlb, setDLBStatus] = useState(""); 
    const [status, setStatus] = useState(""); 
    const [uncomplete, setUnComplete] = useState(false);
    const [UserData, setUserData]         = useState(profileData);

    const handleUpload = async (data) => {
        if (file) {
            setStatus("uploading");          
            let formData = new FormData();
            // formData.append("identity", file);
            formData.append("user_id", data.user_id); 
            formData.append("profile_step", data.profile_step);
            try {               
                const res = await PostUploadFiles('user/store-user-identity', formData );              
                if(res.success){
                    handleNotify(); // Notify the Save data
                    handleNext();
                }else{
                console.error(res.message);
                } 
                setFileUrl('');
                setStatus("success");
            } catch (error) {
                console.log(error);
                setStatus("fail");
            }
        }else{
            handleNext();
        }
    };

    const MoveToNext = async (data) => {
        console.log("currentStep ", currentStep, user_id);
        const Res = await PostAuthRequest('user/save-profile', {user_id:user_id, profile_step:currentStep});
        if(Res.success){
            handleNotify(); // Notify the Save data
            handleNext();
        }
    };
    
    const handleFileChange = async (e, type) => {
              
            if (e.target.files) {     
                let formData = new FormData();                
                formData.append("user_id", user_id); 
                // formData.append("profile_step", currentStep);
                if(e.target.files[0].size >= 5e6){
                    NotifySomeError(" File size should be less than 5 MB.")                          
                    e.target.value = ""
                    return false
                } 

                if(type==='passport'){
                    setPasportStatus("uploading");
                    formData.append("passport", e.target.files[0]);
                }

                if(type==='drive_l_a'){
                    setDLAStatus("uploading");
                    formData.append("drive_l_a", e.target.files[0]);
                }

                if(type==='drive_l_b'){
                    setDLBStatus("uploading");
                    formData.append("drive_l_b", e.target.files[0]);
                }
                
                if(type==='identity'){
                    setStatus("uploading");
                    formData.append("identity", e.target.files[0]);
                }

                const res = await PostUploadFiles('user/store-user-identity', formData );           
                if(res.success){
                    if(type==='passport'){
                        setPasportStatus("success");
                    }
                    if(type==='drive_l_a'){
                        setDLAStatus("success");
                    }
                    if(type==='drive_l_b'){
                        setDLBStatus("success");
                    }
                    if(type==='identity'){
                        setStatus("success");
                    }
                }else{
                console.error(res.message);
                } 
                
                setFile(e.target.files[0]);
                setFileUrl(
                    URL.createObjectURL(e.target.files[0])
                );

                setTimeout(() => {                   
                    setFileUrl('');
                    setPasportStatus("");
                    setDLAStatus("");
                    setDLBStatus("");          
                    setStatus("")
                }, 3000);

                let Userdetail = await PostAuthRequest("get-profile-detail",{'user_id':user_id});
                if(Userdetail?.success){                        
                    setUserData(Userdetail.data);
                }
               // console.log(" Userdetail ", Userdetail.data.identity_passport);
            }
        };

    const  handleNext= async (e) =>{
        try {
            NextStep();
        } catch (error) {
        console.error(error.message);
        }
    };

    const handlePrev = (e) =>{
        e.preventDefault();
        console.log("handlePrev");
        PrevStep();
    };

    const removeImage = (e) =>{
        setFile('');
        setFileUrl('');
    }

    const handleNotify= (e) => {
        notify("Profile step saved successfully."); // Notify the Save data
    }

    return (
        <>
          <form action="#" encType='multipart/form-data'  className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix" id="wizard-02" role="application" novalidate="novalidate">
                        <div className="steps clearfix">
                            <ul role="tablist">
                                <li role="tab" className={currentStep == 1 ? "current first" : "done first" } aria-disabled="false" aria-selected="true" onClick={(e) => setActiveTab(e,1)}>
                                    <a id="wizard-02-t-0" href="" aria-controls="wizard-02-p-0" >
                                        <span className="current-info audible">current step: </span>
                                        <span className="number">01</span>
                                        <div className="step-text">
                                            <div className="lead-text">Personal Details</div>
                                            <div className="sub-text">Enter the Personal information</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 2 ? "current" : currentStep > 2 ? "done" : " " }  aria-disabled="false" aria-selected="false" onClick={(e) => setActiveTab(e,2)}>
                                    <a id="wizard-02-t-1" href="" aria-controls="wizard-02-p-1">
                                        <span className="number">02</span>
                                        <div className="step-text">
                                            <div className="lead-text">Your Availability</div>
                                            <div className="sub-text">Enter your working hours</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 3 ? "current" : currentStep > 3 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-2" href="" aria-controls="wizard-02-p-2" onClick={(e) => setActiveTab(e,3)}>
                                        <span className="number">03</span>
                                        <div className="step-text">
                                            <div className="lead-text">Price Information</div>
                                            <div className="sub-text">Enter your Price</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 4 ? "current" : currentStep > 4 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-3" href="" aria-controls="wizard-02-p-3" onClick={(e) => setActiveTab(e,4)}>
                                        <span className="number">04</span>
                                        <div className="step-text">
                                            <div className="lead-text">My Photos</div>
                                            <div className="sub-text">Upload your photos</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 5 ? "current" : currentStep > 5 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-4" href="" aria-controls="wizard-02-p-4" onClick={(e) => setActiveTab(e,5)}>
                                        <span className="number">05</span>
                                        <div className="step-text">
                                            <div className="lead-text">My Extras / Services</div>
                                            <div className="sub-text">Select extra services</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 6 ? "current" : currentStep > 6 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-5" href="" aria-controls="wizard-02-p-5" onClick={(e) => setActiveTab(e,6)}>
                                        <span className="number">06</span>
                                        <div className="step-text">
                                            <div className="lead-text">Verification</div>
                                            <div className="sub-text">Verify your profile</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 7 ? "current last" : currentStep > 7 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-6" href="" aria-controls="wizard-02-p-6" onClick={(e) => setActiveTab(e,7)}>
                                        <span className="number">07</span>
                                        <div className="step-text">
                                            <div className="lead-text">Completed</div>
                                            <div className="sub-text">Review and Submit</div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="content clearfix">
                        <div className="nk-wizard-head title" id="wizard-02-h-5" tabindex="-1">
                                <div className="step-text">
                                    <div className="lead-text">Verification</div>
                                    <div className="sub-text">Verify your profile</div>
                                </div>
                                </div>
                                <div className="nk-wizard-content body" id="wizard-02-p-5" role="tabpanel" aria-labelledby="wizard-02-h-5" aria-hidden="true" >
                                <div className="mb-5">
                                    <h5 className="title mb-2 text-primary">Verify Your Profile</h5>
                                </div>
                                <div className="row gy-4">
                                    <div className="col-sm-12">
                            
                                    <span className="h5 mb-4 d-block">Hi, Welcome to NaughtyNewZealand</span>
                                    <p>To ensure the identity verification process is robust and secure, it is essential to confirm that the individual using the account is the same person whose identification was provided during registration.</p>
                                    <hr className="preview-hr" />
                                    <div className="row gy-4 row-reverse">
                                        <div className="col-sm-4 offset-sm-1">
                                            <div className="img-girl text-center">
                                                <img src="/images/dlpas.png" />
                                            </div>
                                        </div>
                                        <div className="col-sm-7">
                                    
                                            <span className="h5 mb-4 d-block">Document Required</span>
                                            <p>Please provide a government-issued ID or passport. Ensure that the document matches the ID used during the registration process.</p>
                                            <ul className="list list-checked">
                                                <li>Make sure your face are clearly visible.</li>
                                                <li>Please upload files with valid extension(.jpeg, .png, .jpg).</li>
                                                <li>Image size should be less than 2 MB.</li>
                                                {/* <li>This information is solely htmlFor our records and will not be published on NaughtyNewZealand.</li> */}
                                            </ul>
                                            {/* <p>2. Once you've submitted all your details and documents they will be verified against information in the account details section and activate your account. It may take up to 1-2 working days.</p> */}
                                            <div className="form-group mt-4">
                                            
                                                <div className="form-control-wrap">                                                
                                                    <label className="form-label" htmlFor="customVerify">Proof of Identity</label>
                                                    <div className="form-control form-file d-flex align-items-center justify-content-between py-4 px-1">
                                                        <input type="file" className="form-file-input" accept="image/*"  id="customVerify"   
                                                            {...register("passport", {
                                                                required: false,
                                                            })} 
                                                            onChange={(e)=> handleFileChange(e, 'passport')} 
                                                        />              
                                                        { pasport && pasport}
                                                        {/* <input type="button" className="btn btn-gray" onClick={handleSubmit(handleUpload)} value="Upload" /> */}
                                                    
                                                    </div> 
                                                    {  
                                                        UserData.identity_passport && <>
                                                        {/* <span>Proof of Identity</span> */}
                                                            <div className={"upload__img-box p-0 mt-3 mb-0"}>
                                                                <div className='img-bg' style={{ backgroundImage: `url(${UserData.identity_passport})` }} >                                  
                                                                </div>
                                                            </div>
                                                        </>  
                                                    }   
                                                    {/* OR                                        
                                                    <br />
                                                    
                                                    <label className="form-label" htmlFor="customVerify">Upload Driving Licence A</label>
                                                    <div className="form-control form-file d-flex align-items-center justify-content-between py-4 px-1">
                                                        <input type="file" className="form-file-input" accept="image/*"  id="customVerify"   
                                                            {...register("drive_l_a", {
                                                                required: false,
                                                            })} 
                                                            onChange={(e)=> handleFileChange(e, 'drive_l_a')} 
                                                        />              
                                                        { dla && dla}
                                                    
                                                    
                                                    </div>
                                                    <br />
                                                    
                                                    <label className="form-label" htmlFor="customVerify">Upload Driving Licence B</label>
                                                    <div className="form-control form-file d-flex align-items-center justify-content-between py-4 px-1">
                                                        <input type="file" className="form-file-input" accept="image/*"  id="customVerify"   
                                                            {...register("drive_l_b", {
                                                                required: false,
                                                            })} 
                                                            onChange={(e)=> handleFileChange(e, 'drive_l_b')} 
                                                        />              
                                                        { dlb && dlb}
                                                    
                                                    
                                                    </div>
                                                    OR
                                                    */}
                                                    <br />
                                                    <label className="form-label" htmlFor="customVerify">Additional Document</label>
                                                    <div className="form-control form-file d-flex align-items-center justify-content-between py-4 px-1">
                                                        <input type="file" className="form-file-input" accept="image/*"  id="customVerify"   
                                                            {...register("image", {
                                                                required: false,
                                                            })} 
                                                            onChange={(e)=> handleFileChange(e, 'identity')} 
                                                        />              
                                                        { status && status}
                                                       
                                                        
                                                    </div>
                                                    {
                                                        UserData.identity_attachment_file && <>
                                                        {/* <span>Additional Document</span> */}
                                                            <div className={"upload__img-box p-0 mt-3 mb-0"}>
                                                                <div className='img-bg' style={{ backgroundImage: `url(${UserData.identity_attachment_file})` }} >                                  
                                                                </div>
                                                            </div>
                                                        </>
                                                    } 
                                                    {/* { fileUrl && <img src={fileUrl} height="200" width="200"/> } */}
                                                    {/* <div className="upload__img-wrap">
                                                        {
                                                        fileUrl &&  <div className="upload__img-box">
                                                                <div className='img-bg' style={{ backgroundImage: `url(${fileUrl})` }} >
                                                                    <div className="upload__img-close" onClick={(event)=>{ removeImage(event) }} ></div>                                    
                                                                </div>
                                                            </div>
                                                        }
                                                    </div> */}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                       
                                        {/* {
                                            UserData.driving_licence_a && <>
                                            <span>Driving Licence A</span>
                                            <div className={"upload__img-box "}>
                                                <div className='img-bg' style={{ backgroundImage: `url(${UserData.driving_licence_a})` }} >                                  
                                                </div>
                                            </div>
                                            </> 
                                        } 
                                        {
                                            UserData.driving_licence_b && <>
                                            <span>Driving Licence B</span>
                                                <div className={"upload__img-box "}>
                                                    <div className='img-bg' style={{ backgroundImage: `url(${UserData.driving_licence_b})` }} >                                  
                                                    </div>
                                                </div>
                                            </>
                                        }  */}
                                        
                                                                           
                                    <hr className="preview-hr" />
                                    <div className="row gy-4 row-reverse">
                                        <div className="col-sm-4 offset-sm-1">
                                            <div className="img-girl text-center">
                                                <img src="/images/verifyprofile.png" />
                                            </div>
                                        </div>
                                        <div className="col-sm-7">
                                            <span className="h5 mb-4 d-block">Steps to Capture the Photo:</span>
                                            <p>1. Take a portrait or selfie while holding the same ID used during registration.</p>
                                            <p>2. Additionally, hold a piece of paper displaying the following details:</p>
                                            <ul className="list list-checked">
                                                <li><strong>&quot;Name&quot; - The name written on the paper must match the name used during
                                                registration.</strong></li>
                                                <li><strong>&quot;Naughty New Zealand&quot;</strong></li>
                                                <li><strong>&quot;967542&quot;</strong></li>
                                            </ul>
                                            <p>3. Please reply to this email <a href="mailto:info@naughtynewzealand.co.nz">info@naughtynewzealand.co.nz</a> and attach the picture.</p>
                                            <p>Your cooperation in adhering to these guidelines is greatly appreciated. This will help us expedite the verification process effectively.</p>
                                            <p>Thank you.<br />
                                            NaughtyNewZealand</p>
                                            
                                        </div>
                                    </div>
                                  
                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="actions clearfix">
                        <ul role="menu" aria-label="Pagination">
                            {
                                currentStep > 1 && (
                                    <li className="" aria-disabled="false">
                                        <a href="" role="menuitem"  onClick={handlePrev} >Prev</a>
                                    </li>
                                )
                            }  
                            {                                      
                                currentStep < 7 && (<li aria-hidden="false" aria-disabled="true">
                                    <a href="" role="menuitem" onClick={handleSubmit(MoveToNext)} className={uncomplete}>Next</a>
                                    </li>
                                )
                            }               
                            {
                                currentStep==7 && (<li aria-hidden="true">
                                        <a href="#finish" role="menuitem">Activate My Account</a>
                                    </li>
                                )
                            }                                            
                        </ul>
                </div>
                <input type="hidden" value={user_id} {...register("user_id")} />
                <input type="hidden" value={currentStep} {...register("profile_step")} />
            </form>
            
        </>
    )
}