import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import CustomCursor from '../CustomCursor';

import { Helmet } from 'react-helmet';


// import "../../utills/assets/css/dashlite.css";
// import "../../utills/assets/css/theme.css"

export default function LayoutSignup({ headerVariant }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
        <link rel="stylesheet" href="/assets/css/dashlite.css" /> 
        <link rel="stylesheet" href="/assets/css/theme.css" /> 
      </Helmet>
      <React.Fragment>
        <Outlet />
        {/* <CustomCursor /> */}
      </React.Fragment>
      <Helmet>
        <link rel="stylesheet" href="/assets/js/bundle.js?ver=2.9.1" /> 
        <link rel="stylesheet" href="/assets/js/scripts.js?ver=2.9.1" /> 
      </Helmet>
    </>
  );
}
