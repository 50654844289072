import Core from './components/Pages/Core';
import { AuthProvider } from './auth/AuthContext';
import RoutePath from './routes/router';
function App() {
  return (
    <> 
        <AuthProvider>
          <RoutePath />
        </AuthProvider>        
    </>
  );
}

export default App;
