import React, { useState , useEffect} from 'react';
import { PostAuthRequest, getRecords } from '../../../../utills/common';
import { useForm } from "react-hook-form";
import { useNavigate, NavLink } from "react-router-dom";
import { ProfileSidebar } from './ProfileSidebar';

export function ReviewSubmit({user_id, setActiveTab, NextStep, PrevStep, currentStep,
    profileData, notify}){
    const { register, handleSubmit, formState: { errors }} = useForm();
    const navigate = useNavigate();
    // const handleSave = async (data) => {
    //     try {    
    //         console.log("Form completed!");
    //         // let parms = {user_id:22,profile_name:"Janab", profile_type:11,"age":35}
    //         const Res = await PostAuthRequest('user/save-profile', {profile_step:data.profile_step, user_id: data.user_id});
    //         if(Res.success){
    //             handleNotify(); // Notify the Save data
    //             // setTimeout(()=>{
    //             //     window.location.reload();
    //             //     //  navigate('/');
    //             // }, 2000);                
    //         }else{
    //          console.error(Res.message);
    //         }
    //     } catch (error) {
    //         console.error(error.message);
    //     }
    // };

    useEffect(() => {
        async function fetchData() {
            const Res = await PostAuthRequest('user/save-profile', {profile_step: -1 , user_id: user_id}); 
            if(Res.success){
                console.log("Response ",Res);
               // handleNotify();          
            }else{
             console.error(Res.message);
            }       
          }
          setTimeout(() => {
            fetchData();  
          }, 2000);
                
    }, []);

    const  handleNext= async (e) =>{
        try {   
            console.log("handleNext");
            NextStep();
        } catch (error) {
        console.error(error.message);
        }
    };

    const handlePrev = (e) =>{
        console.log("handlePrev");
        PrevStep();
    };

    const handleNotify= (e) => {
        notify("Your account activation will be processed within the next 24 hours. We appreciate your cooperation."); // Notify the Save data
    }

    return(
        <>
            {/*   onSubmit={handleSubmit(handleSave) } */}
        <form action="#"  className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix all_done" id="wizard-02" role="application" novalidate="novalidate">
                <div className="steps clearfix">
                    <ul role="tablist">
                        <li role="tab" className={currentStep == 1 ? "current first" : "done first" } aria-disabled="false" aria-selected="true" onClick={(e) => setActiveTab(e,1)}>
                            <a id="wizard-02-t-0" href="" aria-controls="wizard-02-p-0" >
                                <span className="current-info audible">current step: </span>
                                <span className="number">01</span>
                                <div className="step-text">
                                    <div className="lead-text">Personal Details</div>
                                    <div className="sub-text">Enter the Personal information</div>
                                </div>
                            </a>
                        </li>
                        <li role="tab" className={currentStep == 2 ? "current" : currentStep > 2 ? "done" : " " }  aria-disabled="false" aria-selected="false" onClick={(e) => setActiveTab(e,2)}>
                            <a id="wizard-02-t-1" href="" aria-controls="wizard-02-p-1">
                                <span className="number">02</span>
                                <div className="step-text">
                                    <div className="lead-text">Your Availability</div>
                                    <div className="sub-text">Enter your working hours</div>
                                </div>
                            </a>
                        </li>
                        <li role="tab" className={currentStep == 3 ? "current" : currentStep > 3 ? "done" : "disabled " } aria-disabled="true">
                            <a id="wizard-02-t-2" href="" aria-controls="wizard-02-p-2" onClick={(e) => setActiveTab(e,3)}>
                                <span className="number">03</span>
                                <div className="step-text">
                                    <div className="lead-text">Price Information</div>
                                    <div className="sub-text">Enter your Price</div>
                                </div>
                            </a>
                        </li>
                        <li role="tab" className={currentStep == 4 ? "current" : currentStep > 4 ? "done" : "disabled " } aria-disabled="true">
                            <a id="wizard-02-t-3" href="" aria-controls="wizard-02-p-3" onClick={(e) => setActiveTab(e,4)}>
                                <span className="number">04</span>
                                <div className="step-text">
                                    <div className="lead-text">My Photos</div>
                                    <div className="sub-text">Upload your photos</div>
                                </div>
                            </a>
                        </li>
                        <li role="tab" className={currentStep == 5 ? "current" : currentStep > 5 ? "done" : "disabled " } aria-disabled="true">
                            <a id="wizard-02-t-4" href="" aria-controls="wizard-02-p-4" onClick={(e) => setActiveTab(e,5)}>
                                <span className="number">05</span>
                                <div className="step-text">
                                    <div className="lead-text">My Extras / Services</div>
                                    <div className="sub-text">Select extra services</div>
                                </div>
                            </a>
                        </li>
                        <li role="tab" className={currentStep == 6 ? "current" : currentStep > 6 ? "done" : "disabled " } aria-disabled="true">
                            <a id="wizard-02-t-5" href="" aria-controls="wizard-02-p-5" onClick={(e) => setActiveTab(e,6)}>
                                <span className="number">06</span>
                                <div className="step-text">
                                    <div className="lead-text">Verification</div>
                                    <div className="sub-text">Verify your profile</div>
                                </div>
                            </a>
                        </li>
                        <li role="tab" className={currentStep == 7 ? "current last" : currentStep > 7 ? "done" : "disabled " } aria-disabled="true">
                            <a id="wizard-02-t-6" href="" aria-controls="wizard-02-p-6" onClick={(e) => setActiveTab(e,7)}>
                                <span className="number">07</span>
                                <div className="step-text">
                                    <div className="lead-text">Completed</div>
                                    <div className="sub-text">Review and Submit</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="content clearfix">
                <div className="nk-wizard-head title" id="wizard-02-h-6" tabindex="-1">
                    <div className="step-text">
                        <div className="lead-text">Completed</div>
                        <div className="sub-text">Review and Submit</div>
                    </div>
                    </div>
                    <div className="nk-wizard-content body" id="wizard-02-p-6" role="tabpanel" aria-labelledby="wizard-02-h-6" aria-hidden="true" >
                    <div className="mb-5 text-center">
                        <h3 className="title mb-2 text-primary">You Are Done.</h3>
                        <p>Congrats! Successfully created your Profile</p>
                        <div className="my-5 doneimage">
                        <img src="./images/alldone.svg" alt="" />
                        </div>
                    </div> {/* Show After Submitted Activate */} 
                    {/* <div className="alert alert-icon alert-success" role="alert">
                        <em className="icon ni ni-check-circle-fill"></em>
                        <strong>You Are Done.</strong>
                    </div> */}

                    {/* <div className="alert alert-icon alert-success" role="alert">
                        <em className="icon ni ni-check-circle-fill"></em>
                        <strong>Thank you, </strong> Your account activation will be processed within the next 24 hours. We appreciate your cooperation.
                    </div> */}
                </div>
                </div>
                <div className="actions clearfix">
                    <ul role="menu" aria-label="Pagination">
                        {/* {
                            currentStep > 1 && (
                                <li className="" aria-disabled="false">
                                    <a href="#previous" role="menuitem" onClick={handlePrev} >Prev</a>
                                </li>
                            )
                        }   */}
                        {/* 
                        {                                      
                            currentStep < 7 && (<li aria-hidden="false" aria-disabled="true">
                                    <a href="#next" role="menuitem" onClick={handleSubmit(handleSave)} >Next</a>
                                </li>
                            )
                        }
                        */}
                        {
                            currentStep==7 && (
                                <>
                                    <li className="" aria-disabled="false">
                                        <a href="/" role="menuitem" >Back to Home</a>
                                    </li>
                                    {/* <li aria-hidden="true">
                                        <a href="#finish" onClick={handleSubmit(handleSave)} role="menuitem">Activate My Account</a>
                                    </li> */}
                                </>                                
                            )
                        }                                            
                    </ul>
                </div>
                {/* <input type="hidden" value={user_id} {...register("user_id")} />
                <input type="hidden" value={-1} {...register("profile_step")} /> */}
            </form>            
        </>
    )
}
