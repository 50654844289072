import React, { useState , useEffect} from 'react';
// import { useAuth } from '../../../auth/AuthContext';
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Header({name , IsActivated, UserProfile, Notifications}) {
    let username = name;
    // const { logout } = useAuth();
    const navigate = useNavigate();
    const [itemShow, setItemShow] = useState(10);

    const Logout = (event) => {
        event.preventDefault();
        // const { logout } = useAuth();
        // // logout();
        localStorage.clear();
        localStorage.setItem('user-token', '');    
        navigate('/');
    }

    const FirstChars = (strName)=>{
        var matches = strName.match(/\b(\w)/g);
        return matches
    }

    return (
        <>
          <div className="nk-header nk-header-fluid nk-header-fixed is-theme">
            <div className="container-xl wide-lg">
                <div className="nk-header-wrap">               
                    <div className="nk-header-brand">
                        <a href="/" className="logo-link">
                            <img className="logo-light logo-img" src="./images/logo.svg" alt="logo" />
                            <img className="logo-dark logo-img" src="./images/logo.svg" alt="logo-dark" />                      
                        </a>
                    </div>
                    
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                            <li className="dropdown notification-dropdown">
                                <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                    <div className="icon-status icon-status-info"><em className="icon ni ni-bell"></em></div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
                                    <div className="dropdown-head">
                                        <span className="sub-title nk-dropdown-title">Notifications</span>
                                        
                                    </div>
                                    <div className="dropdown-body">
                                        <div className="nk-notification">
                                            {
                                                (Notifications.NotificationList?.length > 0)?                                               
                                                <>
                                                    { Notifications.NotificationList && Notifications.NotificationList.slice(0, itemShow).map((item, index) => (
                                                        <div className="nk-notification-item dropdown-inner">
                                                            <div className="nk-notification-icon">
                                                                <em className="icon icon-circle bg-danger ni ni-happy"></em>
                                                            </div>
                                                            <div className="nk-notification-content">
                                                                <div className="nk-notification-text">{item.title}</div>
                                                                <div className="nk-notification-time">{item.time_ago}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>                                           
                                                :
                                                <div className="nk-notification-item dropdown-inner">
                                                    No Notification
                                                </div>
                                            }                                          
                                            
                                        </div>
                                    </div>
                                    {/* <div className="dropdown-foot center">
                                        <a href="#">View All</a>
                                    </div> */}
                                </div>
                            </li>
                            <li className=""><a href="" onClick={Logout} className="nk-quick-nav-icon"><em className="icon ni ni-signout"></em></a></li>                            
                           
                            <li className="dropdown user-dropdown order-first">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                    <div className="user-toggle">
                                        <div className="user-avatar sm">
                                            <em className="icon ni ni-user-alt"></em>
                                        </div>
                                        <div className="user-info d-none d-xl-block">
                                            <div className={ (IsActivated)? 'user-status  user-status-verified' : 'user-status  user-status-unverified' } >{IsActivated}</div>
                                            <div className="user-name">Hi, {username}</div>
                                        </div>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 is-light">
                                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                        <div className="user-card">
                                            <div className="user-avatar">
                                                <span>{ FirstChars(username) }</span>
                                            </div>
                                            <div className="user-info">
                                                <span className="lead-text">{username}</span>
                                                <span className="sub-text">{UserProfile?.email}</span>
                                            </div>
                                        
                                        </div>
                                    </div>
                            
                                    <div className="dropdown-inner">
                                        <ul className="link-list">
                                            <li><a href={UserProfile?.profile_url}><em className="icon ni ni-user-alt"></em><span>View Profile</span></a></li> 
                                            <li><a href={'/change-password'}><em className="icon ni ni-setting-alt"></em><span>Change Passsword</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="dropdown-inner">
                                        <ul className="link-list">
                                            <li><a href="" onClick={Logout} ><em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
            <>
                <Helmet>
                    <script src="/assets/js/bundle.js?ver=2.9.1" type="text/javascript" />
                    {/* <script src="/assets/js/scripts.js?ver=2.9.1" type="text/javascript" /> */} 
                </Helmet> 
            </>
        </>
    );
  }