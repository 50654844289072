import Config from '../config/default.json';

// return the user data from the session storage

export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
   
  // return the token from the session storage
  export const getToken = () => {
    // localStorage.removeItem('user-token');
    // console.log("token get ", localStorage.getItem('user-token'));
    return localStorage.getItem('user-token') || null;
  }
   
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    localStorage.removeItem('user-token');
    localStorage.removeItem('user');
  }
   
  // set the token and user from the session storage
  export const setUserSession = (token, user) => {
    // console.log("New Set Token ", token)
    localStorage.setItem('user-token', token);
    localStorage.setItem('user', JSON.stringify(user));
   
  }


// export const loginUserApi = async (credentials) => {
//   try {
//     let res = await  fetch(Config.api_host + 'auth/login', {
//       method: 'POST',
//       headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(credentials)
//       });
//       let resJson  = await res.json(); 
//       if (resJson.success == true) {
//         setUserSession(resJson.data.token, resJson.data);
//       }else{
//         localStorage.setItem('user-token',""); // set the storeage
//       }     
    
//     return resJson;

//   } catch (err) {
//     console.log(err);
//     localStorage.setItem('user-token',""); // set the storeage
//   }

// }



const login = async (credentials) => {
  const API_URL = Config.api_host + 'auth/login';
  try {
    const response = await fetch(`${API_URL}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
    });

    const data = await response.json();
    // if (!data.success) {
    //   // throw new Error(data.message);
    //   console.log(" error ",error);
    // }
    // console.log(" New Login details ", data);
    return data; // Assume the API returns { user, token }
  } catch (error) {
    // throw new Error('Login failed');
    console.log(" error ",error);
  }
};

export { login };

export const PostNoAuthRequest = async (path,params) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(params)
    };
    
    const response = await fetch(Config.api_host + path, requestOptions);
    const data =  await response.json();
    if (!data.success) {
        // get error message from body or default to response status
        const error = (data && data.message) || data.status;
        console.log("Error ", error);
        // return Promise.reject(error);
        return data
    } 
    return data;

}


export const PostAuthRequest = async (path, params) => {
 const token =  getToken();
  const requestOptions = {
      method: 'POST',
      headers: { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      "Access-Control-Allow-Credentials" : true
      },
      body: JSON.stringify(params)
  };

  const response = await fetch(Config.api_host + path, requestOptions);
  const data =  await response.json();
  if (!data.success) {
      // get error message from body or default to response status
      const error = (data && data.message) || data.status;
      console.log("Error ", error);
      // return Promise.reject(error);
      return data
  } 
  return data;

}


export const PostUploadFiles = async (path, params) => {
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTMwMzIzMTM4Nzc0MGIyYWE3MjdhNzNiMGZkMTY1MWNmMWIzMTk3NjgxOGIzMTFlZTQ2YWI5OTgzOWZiMTlhZjU3ZDk5ZDU2ZGRlMDNjY2IiLCJpYXQiOjE3MDU0NDk1MjUuMzU3MDA2LCJuYmYiOjE3MDU0NDk1MjUuMzU3MDE3LCJleHAiOjE3MDgxMjc5MjUuMjM1ODg2LCJzdWIiOiIyMSIsInNjb3BlcyI6W119.NS3-MYj0058S9K2OTR8WIniODeP8tcFbpdFOrhjrom8qla68VdECWvaMU3uVZT76D4hUaO60wVc6rCYl5SXGD4oFx-SPHKPE9uo-lYRz5WRM28aCiKRuQaBlGV1Z06WYUIW3vY9ukoHBHpNGjs_1WmHy5OnUIGzqfdmjmbkJp0mMkhJFoul5bS_20BIr1gxaJuKRvnpDfSmD3eoIsMQKTKsHOcOxGZmHqYrlqXNIHKf4FAcH1kQ7kAUS3SAfY42X2K4dXsfyJNWVpdqYiS4PyEnyMsF697WofUWUFeDoXbV_O55ZPEXcPjggVKw70Zqv37EvwRB_Um1HgG3zrPJaiQrct74kYzkEcXl41ZdUSzEEUGuW0enusaS_QMHpPCP7eUADA5_cmTaZG9GoFCZN8spSrz5CLwqCknBFI_11wGYu22d_UnPHjWnvwCbPt2eiYIchbP6cMlWoSNPtOXGKwuZefXRRcds-rPgpSYxg7Nj5aK6-qVnhQ0clkWQoop0dfggNNdlLsc1Tkgk2XbDS4OBj1cBLTUAc1z9m6VbBoEFhEc3dKL9AkfxLsNrFDiv_09sgMTPoR-DvxS8dCdV-qHEXzpwKddkGSkDbghGolvMeKSV9P5E94QgI7eYsIHhkmHR9IhNAZLFCtChb80DqWjjd_szVSb9e0DRF_nWlxLE'; //getToken();
  const token =  getToken();
  const requestOptions = {
      method: 'POST',
      headers: { 
      // header ('Content-Type: image/jpeg');
      // 'Accept': 'application/json, *.*',
      // 'Content-Type': 'application/json',
      // "Content-Type": "multipart/form-data; charset=utf-8; boundary=----19679527153991285751414616421" ,
      'Authorization': `Bearer ${token}`,  
      // "Access-Control-Allow-Origin": "*",
      },
      body: params
      // body: {
      //   param_one: 'value 1', 
      //   param_two: 'value '

      // }
  };
  const response = await fetch(Config.api_host + path, requestOptions);
  const data =  await response.json();
    if (!data.success) {
      // get error message from body or default to response status
      const error = (data && data.message) || data.status;
      console.log("Error ", error);
      // return Promise.reject(error);
      return data
  } 
  return data;

}

  // API is only works with Laravel API's
  export const getRecords = async (path) => {
    // const parms = {"id": 16}
    // const Res = await getRecords('user/get_user_by_id', parms);
    const token = getToken();

    const requestOptions = {
        method: 'GET',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
  
    const response = await fetch(Config.api_host + path, requestOptions);
    const data =  await response.json();
    if (!data.success) {
        // get error message from body or default to response status
        const error = (data && data.message) || data.status;
        console.log("Error ", error);
        // return Promise.reject(error);
        return data;
    } 
    return data;
  }

  export const addBodyClass = className => document.body.classList.add(className);
  export const removeBodyClass = className => document.body.classList.remove(className);