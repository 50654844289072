import  { useState,  } from "react";
import React from 'react';
import { Route, Routes , useLocation } from 'react-router-dom';
import AboutPage from '../components/Pages/AboutPage';
import ContactPage from '../components/Pages/ContactPage';
import ErrorPage from '../components/Pages/ErrorPage';
import Home from '../components/Pages/Home';
import PortfolioDetailsPage from '../components/Pages/PortfolioDetailsPage';
import ProfileDetailPage from '../components/Pages/ProfileDetailPage';
import ServicesPage from '../components/Pages/ServicesPage';
import TeamPage from '../components/Pages/TeamPage';
import PortfolioPage from '../components/Pages/PortfolioPage';
import TeamDetails from '../components/Pages/TeamDetails';
import CaseStudyShowcaseHome from '../components/Pages/CaseStudyShowcaseHome';
import Layout from '../components/Layout';
import LayoutSignup from '../components/LayoutSignup';

import LoginForm from '../components/Login/LoginForm';
import Signup from '../components/Signup/Signup';
import ForgetPassword from '../components/ForgetPassword';
import ResetPassword from '../components/ResetPassword';

import ForgetPasswordVerify from '../components/ForgetPasswordVerify';
import EmailVerify from '../components/EmailVerify';
import FaqPage from '../components/Pages/FaqPage';
import Profile from "../components/Profile";
import LayoutProfile from "../components/LayoutProfile";
import Dashboard from "../components/Pages/Dashboard";
import RegionWiseList from "../components/Pages/RegionWiseList";
import AgencyWiseList from "../components/Pages/AgencyWiseList";
import ChangePassword from "../components/ChangePassword";
import AgencyDetailPage from '../components/Pages/AgencyDetailPage';
import TermsOfUsePage from "../components/Pages/TermsOfUsePage";
import Privacy from "../components/Pages/Privacy";
import MenPage from '../components/Pages/MenPage';

const RoutePath = () => {
    let location = useLocation();
    let isSignup = false;
    React.useEffect(() => {      
        if(location.pathname=="/login"){
            isSignup = true;
        }
        
      }, [location]);
    return (
        <Routes>        
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="service" element={<ServicesPage />} />
                <Route path="men" element={<MenPage />} />
                <Route path="profile-detail/:user_id" element={<ProfileDetailPage />} />
                <Route path="portfolio" element={<PortfolioPage />} />
                <Route path="portfolio/:portfolioDetailsId" element={<PortfolioDetailsPage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="team" element={<TeamPage />} />
                <Route path="team/:teamDetails" element={<TeamDetails />} />
                <Route path="faq" element={<FaqPage />} />
                <Route path="/:region_name/:region_id" element={<RegionWiseList />} />
                <Route path="agencies" element={<AgencyWiseList />} />
                <Route path="agencies/:slug_name" element={<AgencyDetailPage />} />
                <Route path="terms-of-use" element={<TermsOfUsePage />} />
                <Route path="privacy" element={<Privacy />} />                
            </Route>
                <Route path="/"  element={<Layout headerVariant="cs-site_header_full_width" />} >
                    <Route path="case-study-showcase" element={<CaseStudyShowcaseHome />} />
                </Route>                
             
            <Route path="/" element={<LayoutSignup />}>
                <Route path="login" element={<LoginForm />} />
                <Route path="signup" element={<Signup />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="email-verify/:user_id/:email/:token" element={<EmailVerify />} />

                <Route path="forget-password" element={<ForgetPassword />} />
                <Route path="verify-forgetpass-token/:user_id/:email/:token" element={<ForgetPasswordVerify />} />
                
                <Route path="reset-password" element={<ResetPassword />} />


            </Route>
            <Route path="/" element={<LayoutProfile />}>
                <Route path="profile" element={<Profile />} />
                <Route path="change-password" element={<ChangePassword />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
      </Routes>   
    )}

export default RoutePath