import React, { useState , useEffect} from 'react';
import { PostAuthRequest, getRecords } from '../../../../utills/common';
import { useForm } from "react-hook-form";
import { ProfileSidebar } from './ProfileSidebar';

export function PriceInfo({user_id, setActiveTab, NextStep, PrevStep, currentStep,
    profileData, notify }){
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, isValid },
      } = useForm();
      const [uncomplete, setUnComplete] = useState(false);
      const handleSave = async (formData) => {
        try {
          // let parms = {user_id:22,profile_name:"Janab", profile_type:11,"age":35}
          if(isValid){
            const Res = await PostAuthRequest('user/save-profile', formData);
            if(Res.success){
                handleNotify(); // Notify the Save data
                handleNext();
            }else{
            console.error(Res.message);
            } 
          }      
        } catch (error) {
          console.error(error.message);
        }
      };
    
      const handleNext = async (e) => {
        try {
          NextStep();
        } catch (error) {
          console.error(error.message);
        }
      };
    
    const handlePrev = (e) => {
        PrevStep();
    };

    const handleNotify= (e) => {
        notify("Profile step saved successfully."); // Notify the Save data
    }

    useEffect(() => {
        let defaultValues = {};
        defaultValues.price_1_hour = ( profileData?.price_1_hour)? profileData.price_1_hour : "";
        defaultValues.price_2_hour = ( profileData?.price_2_hour)? profileData.price_2_hour : "";
        defaultValues.price_30_min = ( profileData?.price_30_min)? profileData.price_30_min : "";
        defaultValues.price_additional_info = ( profileData?.price_additional_info)? profileData.price_additional_info : "";
        reset({ ...defaultValues });
    }, []);
    return (
        <>

        <form action="#"  className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix" id="wizard-02" role="application">
                        {/* <div className="steps clearfix">
                            <ul role="tablist">
                                <li role="tab" className={currentStep == 1 ? "current first" : "done first" } aria-disabled="false" aria-selected="true" onClick={(e) => setActiveTab(e,1)}>
                                    <a id="wizard-02-t-0" href="" aria-controls="wizard-02-p-0" >
                                        <span className="current-info audible">current step: </span>
                                        <span className="number">01</span>
                                        <div className="step-text">
                                            <div className="lead-text">Personal Details</div>
                                            <div className="sub-text">Enter the Personal information</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 2 ? "current" : currentStep > 2 ? "done" : " " }  aria-disabled="false" aria-selected="false" onClick={(e) => setActiveTab(e,2)}>
                                    <a id="wizard-02-t-1" href="" aria-controls="wizard-02-p-1">
                                        <span className="number">02</span>
                                        <div className="step-text">
                                            <div className="lead-text">Your Availability</div>
                                            <div className="sub-text">Enter your working hours</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 3 ? "current" : currentStep > 3 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-2" href="" aria-controls="wizard-02-p-2" onClick={(e) => setActiveTab(e,3)}>
                                        <span className="number">03</span>
                                        <div className="step-text">
                                            <div className="lead-text">Price Information</div>
                                            <div className="sub-text">Enter your Price</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 4 ? "current" : currentStep > 4 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-3" href="" aria-controls="wizard-02-p-3" onClick={(e) => setActiveTab(e,4)}>
                                        <span className="number">04</span>
                                        <div className="step-text">
                                            <div className="lead-text">My Photos</div>
                                            <div className="sub-text">Upload your photos</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 5 ? "current" : currentStep > 5 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-4" href="" aria-controls="wizard-02-p-4" onClick={(e) => setActiveTab(e,5)}>
                                        <span className="number">05</span>
                                        <div className="step-text">
                                            <div className="lead-text">My Extras / Services</div>
                                            <div className="sub-text">Select extra services</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 6 ? "current" : currentStep > 6 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-5" href="" aria-controls="wizard-02-p-5" onClick={(e) => setActiveTab(e,6)}>
                                        <span className="number">06</span>
                                        <div className="step-text">
                                            <div className="lead-text">Verification</div>
                                            <div className="sub-text">Verify your profile</div>
                                        </div>
                                    </a>
                                </li>
                                <li role="tab" className={currentStep == 7 ? "current last" : currentStep > 7 ? "done" : "disabled " } aria-disabled="true">
                                    <a id="wizard-02-t-6" href="" aria-controls="wizard-02-p-6" onClick={(e) => setActiveTab(e,7)}>
                                        <span className="number">07</span>
                                        <div className="step-text">
                                            <div className="lead-text">Completed</div>
                                            <div className="sub-text">Review and Submit</div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                        <ProfileSidebar setActiveTab={setActiveTab} currentStep={currentStep} profileData={profileData} />
                        
                        <div className="content clearfix">
                        <div className="nk-wizard-head title" id="wizard-02-h-2" tabindex="-1">
                            <div className="step-text">
                                <div className="lead-text">Price Information</div>
                                <div className="sub-text">Enter your Price</div>
                            </div>
                            </div>
                            <div className="nk-wizard-content body" id="wizard-02-p-2" role="tabpanel" aria-labelledby="wizard-02-h-2" aria-hidden="true">
                            <div className="mb-5">
                                <h5 className="title mb-2 text-primary">Price Information</h5>
                                <p className="text-soft">Enter your rates that will show on your public profile. You can change anytime</p>
                            </div>
                            <div className="row gy-4">
                                <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="price_30_min">Price 30 mins *</label>
                                    <div className="form-control-wrap">
                                    <input type="text" className="form-control" id="price_30_min" placeholder="e.g. $200"  
                                    {...register("price_30_min", {
                                        required: "Field is required",
                                    })} 
                                    />
                                    </div>
                                    {errors.price_30_min && <span
                                    id="phone-error"
                                    className="invalid"                      
                                    >This field is required.</span>}
                                </div>
                                </div>
                                <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="price_1_hour">Price 1 hour *</label>
                                    <div className="form-control-wrap">
                                    <input type="text" className="form-control" id="price_1_hour" placeholder="e.g. $500"
                                    {...register("price_1_hour", {
                                        required: "Field is required",
                                    })}  />
                                    </div>
                                    {errors.price_1_hour && <span
                                    id="phone-error"
                                    className="invalid"                      
                                    >This field is required.</span>}
                                </div>
                                </div>
                                <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="price_2_hour">Price 2 hours *</label>
                                    <div className="form-control-wrap">
                                    <input type="text" className="form-control" id="price_2_hour" placeholder="e.g. $1000" 
                                    {...register("price_2_hour", {
                                        required: "Field is required",
                                    })} />
                                    </div>
                                    {errors.price_2_hour && <span
                                    id="phone-error"
                                    className="invalid"                      
                                    >This field is required.</span>}
                                </div>
                                </div>
                                <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="additionalprice">Additional price information</label>
                                    <p className="form-note">In Additional price and extra services, client can talk to girl whatever their requirements are in terms of Overnight or dinner date etc.</p>
                                    <div className="form-control-wrap">
                                    <textarea className="form-control textarea-sm no-resize" id="additionalprice" placeholder="" {...register("price_additional_info", {
                                        required: false,
                                    })} ></textarea>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions clearfix">
                    <ul role="menu" aria-label="Pagination">
                        {currentStep > 1 && (
                        <li className="" aria-disabled="false">
                            <a href="#previous" role="menuitem" onClick={handlePrev}>
                            Prev
                            </a>
                        </li>
                        )}
                        {currentStep < 7 && (
                        <li aria-hidden="false" aria-disabled="false">
                            <a
                            href="#next"
                            role="menuitem"
                            onClick={handleSubmit(handleSave)}
                            className={uncomplete}
                            >
                            Next
                            </a>              
                        </li>
                        )}
                        {currentStep == 7 && (
                        <li aria-hidden="true">
                            <a href="#finish" role="menuitem">
                            Activate My Account
                            </a>
                        </li>
                        )}
                    </ul>
                    </div>
                    <input type="hidden" value={user_id} {...register("user_id")} />
                    <input type="hidden" value={currentStep} {...register("profile_step")} />
                </form>

            
        </>
    )
}