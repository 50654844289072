import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Div from '../Div';
import { defaultTheme } from 'react-select';
import './selectlist.scss';
import Portfolio from '../Portfolio';
import Spacing from '../Spacing';
import { Icon } from '@iconify/react';
// import portfolioData from '../../portfolioData.json';
import { PostAuthRequest, PostNoAuthRequest, getRecords, addBodyClass, removeBodyClass } from "../../utills/common";
import { useForm } from "react-hook-form";

export default function SelectList({ regions, services, showloading } ) {   
    const [perPage, setPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(0);

    const [portfolioData, setPortfolioData] = useState([]);
    const [loading, setLoading] = useState(true)
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, isValid },
      } = useForm();
    const [suburbs, setSuburbs] = useState({});

    const [searchName, setSearchName] = useState();
    const [available, setAvailability] = useState();
    const [service, setService] = useState();
    const [region_id, setRegion] = useState();
    const [isSearch, setIsSearch] = useState();

    const handleSearch = async (formData) => {
        try {
        setIsSearch(formData.isSearch);
        setPage(0)
        let searchFilds = {
            searchName,
            available,
            service,
            region_id,
            isSearch:formData.isSearch,
            perpage:perPage,
            page:0
        }
          if(isValid){
            setLoading(true);
            addBodyClass("MyLoaderClass")
            let userListData = await PostNoAuthRequest("get-user-profiles",searchFilds);
            if(userListData.success){           
                setLoading(false);     
                setPortfolioData(userListData.data);
                setTotalPages(userListData.total_pages); 
                // setPerPage(userListData.perpage_limit);
                // setPortfolioData([]); // Reset Searched Items 
                // setPortfolioData([...portfolioData, ...userListData.data]); // Update the New searched data
                  
                removeBodyClass('MyLoaderClass');          
            }else{               
                 console.error(userListData.message);
                 setLoading(false);
                 removeBodyClass('MyLoaderClass');
            }       
          }      
        } catch (error) {
          console.error(error.message);
        }
      };

    useEffect(() => {
        try {       
            async function fetchData() {
                const inputdata = {
                    perpage:perPage,
                    page:page
                }
                let userListData  = await PostNoAuthRequest("get-user-profiles",inputdata);
                setLoading(true);
                addBodyClass("MyLoaderClass")
                if (userListData?.success) {
                    // setTotalPages([]);
                    setTotalPages(userListData.total_pages);
                    setPortfolioData(userListData.data);
                    // setPerPage(userListData.perpage_limit);
                    // setPortfolioData([...portfolioData, ...userListData.data]);
                    setLoading(false);
                    removeBodyClass('MyLoaderClass');
                }else{
                    setLoading(false);
                }
                  
            }
            fetchData();       
    } catch (error) {
        //   setLoading(false); // Stop loading in case of error
        console.error(error);
    }
    }, []);

    const LoadMore = async () => {
        setPage(page + 1)
        let newPage = page + 1;

        const inputdata = {
            searchName,
            available,
            service,
            region_id,
            isSearch:isSearch,
            perpage:perPage,
            page:newPage
        }

        let userListData  = await PostNoAuthRequest("get-user-profiles",inputdata);
        setLoading(true);
        addBodyClass("MyLoaderClass")
        if (userListData?.success) {
            setTotalPages([]);
            setTotalPages(userListData.total_pages);
            // setPerPage(userListData.perpage_limit);
            // setPortfolioData(userListData.data);
            setPortfolioData([...portfolioData, ...userListData.data]);
            setLoading(false);
            removeBodyClass('MyLoaderClass');
        }
    }


    const { colors } = defaultTheme;
    const availability = [
        {value:"", label:"Select Availabilty"},
        { value: "any", label: "Any" },
        { value: "Available Now", label: "Available Now" },
        { value: "In Call", label: "In Call" },
        { value: "Out Call", label: "Out Call" },
    ];
    const [itemShow, setItemShow] = useState(20);


return (
<>
    {/* Start CTA Section */}
    <form action="#" onSubmit={handleSubmit(handleSearch)} >
    <Div className="cs-gradient_bg_1 cs-shape_wrap_3 listsection">
        <Div class="cs-height_60 cs-height_lg_20"></Div>
        <Div className="container">
            <Div className="row">
                <Div className="col-12">
                    <h3>Newly Listed Escorts</h3>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> */}
                </Div>
            </Div>
            <Div class="cs-height_20 cs-height_lg_20"></Div>
            <Div className="row g-2">
                <Div className="col-6 col-sm-6 col-md-4">
                    <Div className="form-group">
                        <label class="cs-primary_color">Search name</label>
                        <input type="text" name="searchName" onChange={e => setSearchName(e.target.value)} class="form-control" placeholder="e.g. Niki, Gee"></input>
                    </Div>
                </Div>
                <Div className="col-6 col-sm-6 col-md-2">
                    <Div className="form-group">
                        <label class="cs-primary_color">Location</label>
                        <Select
                            //
                            onChange={e => setRegion(e.value)}                            
                            name="region_id"
                            options={regions}
                            placeholder="Select..."
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#0C0C0C',
                                primary: '#ED145B',
                                },
                            })}
                        />
                    </Div>
                </Div>
                <Div className="col-6 col-sm-6 col-md-2 mb-hide">
                    <Div className="form-group">
                        <label class="cs-primary_color">Availability</label>
                        <Select
                            // {...register("availability")}
                            onChange={e => setAvailability(e.value)}
                            name="availability"
                            options={availability}
                            placeholder="Select..."
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#0C0C0C',
                                primary: '#ED145B',
                                },
                            })}
                        />
                    </Div>
                </Div>
                <Div className="col-6 col-sm-6 col-md-2 mb-hide">
                    <Div className="form-group">
                        <label class="cs-primary_color">Services</label>
                        <Select
                            onChange={e => setService(e.value)}                               
                            name="service"
                            options={services}
                            placeholder="Select..."
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#0C0C0C',
                                primary: '#ED145B',
                                },
                            })}
                        />
                    </Div>
                </Div>
                <Div className="col-12 col-sm-12 col-md-2">
                    <Div className="form-group">
                        <button type="submit"  className="w-100 cs-btn cs-style1 cs-type1 primary-btn">
                        <input type="hidden" {...register("isSearch")} value="1" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.282" height="15.285" viewBox="0 0 15.282 15.285">
                            <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M15.074,13.215,12.1,10.239a.716.716,0,0,0-.507-.209H11.1A6.206,6.206,0,1,0,10.03,11.1v.487a.716.716,0,0,0,.209.507l2.976,2.976a.713.713,0,0,0,1.012,0l.845-.845A.72.72,0,0,0,15.074,13.215ZM6.209,10.03A3.821,3.821,0,1,1,10.03,6.209,3.819,3.819,0,0,1,6.209,10.03Z" fill="#fff"/>
                        </svg> <span>Search</span>
                        </button>
                    </Div>
                </Div>
            </Div>
            <Div class="cs-height_60 cs-height_lg_60"></Div>                            
            {/* User Data */}
            {
                // loading ? (
                // <div className="loader-container">
                //     <div className="spinner"></div>
                // </div>
                // ) :
                <>
                    <Div className="row g-2 userlistcol">
                        {portfolioData && portfolioData.slice(0, itemShow).map((item, index) => (
                            <Div
                                className={`${
                                    index === 0 || index === 3 || index === 4
                                    ? 'col-sm-3'
                                    : 'col-sm-3'
                                }`}
                                key={index}
                                >
                                <Portfolio
                                    name={item.name}
                                    location={item.location}
                                    source={item.source}
                                    price={item.price}
                                    available={item.available}
                                    href={item.href}
                                    tel={item.tel}
                                    sms={item.sms}
                                    src={item.attachment_thumbnail}
                                    contact_method={item.contact_method}
                                    is_verified={item.is_verified}
                                    variant="cs-style1 cs-type2 cs-size3"
                                />
                    
                            </Div>
                        ))}
                        {
                            portfolioData?.length==0 && <div>No Search Results Found.</div>
                        }
                    </Div>
                    <Div className="text-center">              
                        <>
                        <Spacing lg="65" md="40" />
                        { (totalPages !== page) && (totalPages >1) && (
                            <span className="cs-text_btn" onClick={LoadMore}  >
                            {loading ? 'Loading...' : 'Load More'}
                                <Icon icon="bi:arrow-right" />
                            </span>
                            )}
                        </>
                    </Div>
                </>      
            }            
            {/* User Data End */}
            <Div class="cs-height_60 cs-height_lg_60"></Div>
        </Div>
    </Div>
    </form>
    {/* End CTA Section */}
</>

)
}