import React from 'react';
import { useState, useEffect } from 'react';
// import { Modal, Button } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap"
import './style.scss';
import Div from '../Div';
import Cookies from 'universal-cookie';



export default function ModalApp() { 
  const [displayPopUp, setDisplayPopUp] = useState(true);
  
  const cookies = new Cookies();
  const today = new Date();
  const tomorrow =  today.setDate( today.getDate() + 1 ) 

  const closePopUp = () => {
      // window.opener = null;
      // window.open("", "_self");
      // window.close();
      // let new_window = window.open("", "_self"); 
      //   // Close this window
      //   new_window.close();
 
      //   // return false;
      // return false;

      setDisplayPopUp(false);  
      cookies.set('firstPopup', true, { path: '/', maxAge: 86400 }); 
  };

  useEffect(() => {
    const firstPopup = cookies.get("firstPopup");
    if (!firstPopup || firstPopup==undefined) {
      setDisplayPopUp(true);
    }else{
      setDisplayPopUp(false);
    }
      
  }, []);
  
  

  return (
    <>
    { displayPopUp && (
        <Modal show={displayPopUp} onHide={closePopUp} backdrop="static" keyboard={false} centered >
        {/* <Modal.Header closeButton>
          <Modal.Title>Login Form</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
        
          <Div className="text-center">
            <Div className="cs-site_branding mb-5" to="/">
              <img src="/images/logo.svg" alt="Logo" />
            </Div>
            <h2>Age Verification</h2>
            <p>NaughtyNewZealand showcases adult content and advertisements contributed by independent individuals across New Zealand. Access to this website is limited to individuals aged 18 years and above. By entering, you assert that you are not a minor. Upon proceeding beyond the initial pop-up display, you explicitly consent to comply with our terms and conditions. It is important to emphasize that the administration of NaughtyNewZealand is not associated with advertisersand bears no responsibility for the conduct or content of the advertisements.</p>
            <Div className="text-center">
            <Button className="cs-btn cs-style1 cs-type1 primary-btn" onClick={closePopUp}><span>I'm 18 or older - Enter</span></Button>
            <a href="https://www.google.co.nz/"><small>I disagree, exit</small></a>
            </Div>
          </Div>

        
        </Modal.Body>
        {/* <Modal.Footer>
          <Button class="cs-btn cs-style1 cs-type1 primary-btn" variant="secondary" onClick={closeModal}>I'm 18 or older - Enter</Button>
        </Modal.Footer> */}
      </Modal>

    ) }

      
    </>
  );
}


