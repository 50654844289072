import React , {useState, useEffect}from 'react'
import Div from '../Div'
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'
import Cta from '../Cta';
import { getRecords , PostNoAuthRequest} from '../../utills/common';

export default function Footer({copyrightText, logoSrc, logoAlt, text, Title, CopyRight, FooterContent}) {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  // const [Title, setTitle] = useState('');
  // const [CopyRight, setCopyRight] = useState('');
  // const [FooterContent, setFooterContent] = useState('');
  useEffect(  () => {

    ( localStorage.getItem("user-token") ) ? setisLoggedIn(true) :  setisLoggedIn(false) ;

    async function fetchData() {
      // let SettingData = await PostNoAuthRequest('get-site-setting');
      // if (SettingData.success) {
      //   setCopyRight(SettingData.data?.copyright);
      //   setTitle(SettingData.data?.footer_title);
      //   setFooterContent(SettingData.data?.footer);
      // }
    }

    fetchData();    
   
   })
  const copyrightLinks = [
    {
      title: 'Terms of Use',
      href: '/terms-of-use'
    },
    {
      title: 'Privacy Policy',
      href: '/privacy'
    },
    {
      title: 'FAQ',
      href: '/faq'
    },
    {
      title: 'Contact',
      href: '/contact'
    }
  ]
  
  return ( 

    <footer className="cs-fooer">
      {/* Start CTA Section */}     
      <Div className="container">        
        {
            // !isLoggedIn && (
              <>
                 <Spacing lg="50" md="50" />
                  <Cta
                  title={ Title}
                  description={ FooterContent }
                  btnText="Post Your Advertisement"
                  btnLink="/signup"
                  // bgSrc="/images/cta_bg.jpeg"
                    />
                <Spacing lg="50" md="50" />
              </>             
            // )
        }        
      </Div>
      {/* End CTA Section */}

      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-md-6 col-sm-12">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/footer_logo.svg' 
                  logoAlt='Logo'
                  
                />
                <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
              </Div>
            </Div>
            <Div className="col-md-6 col-sm-12 ">
              <Div className="cs-footer_item text-right">
                <SocialWidget/>
                <Div className="cs-copyright mt-4">© {CopyRight}</Div>
              </Div>
            </Div>
            {/* <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Services'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter 
                  title='Subscribe' 
                  subtitle='At vero eos et accusamus et iusto odio as part dignissimos ducimus qui blandit.' 
                  placeholder='example@gmail.com'
                />
              </Div>
            </Div> */}
          </Div>
        </Div>
      </Div>
      {/* <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">© NaughtyNewZealand, 2024</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div>
        </Div>
      </Div> */}
    </footer>
  )
}
