import React, { useState , useEffect, useRef} from 'react';
import { PostAuthRequest, getRecords , PostUploadFiles} from '../../../../utills/common';
import { useForm } from "react-hook-form";
import { ProfileSidebar } from './ProfileSidebar';

export function MyPhotos({user_id,setActiveTab, NextStep, PrevStep, currentStep,
    profileData, notify, MaximumFileUpload, NotifySomeError }){
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, isValid },
      } = useForm();
      const [DisbaleLink, setDisbaleLink]     = useState(false); 
      const [files, setFiles]               = useState([]);
      const [fileUrls, setFileUrls]         = useState([]);
      const [status, setStatus]             = useState(""); // <"initial" | "uploading" | "success" | "fail">
      const [errorMessage, setErrorMessage] = useState("");
      const maxSize = 2 * 2024; // 2MB
      const [UserData, setUserData]         = useState(profileData);

    const handleFileChange = (e) => {
        if(( parseInt(e.target.files.length) + parseInt(UserData?.user_images.length) ) >10 ){
            MaximumFileUpload();
            setErrorMessage("You can only upload 10 maximum image files");
            setTimeout(() => {
                setErrorMessage("");
            }, 5000);

            return false;
        }
            if (e.target.files) {
                const allowedTypes = ["image/jpeg", "image/png", "image/gif"]; 
                    // showCurrentImages(); // Update the current images 
                    // if(document.querySelectorAll(".temp_true")!=undefined){
                    //     document.querySelectorAll(".temp_true").forEach(el => el.remove())
                    // }

                    [e.target.files].forEach( async (file) => {
                        // if ( file.size <= maxSize) {
                            setFiles(file)
                            // files.push(file)
                        // }else{
                        //     setErrorMessage("File size should be less then 2 MB, Seems "+file[0].name +" contains file size "+file[0].size);
                        // }
                    });

                    // Files for preview
                    let ImagesArray = Object.entries(e.target.files).map((e) =>{
                        return  { image:URL.createObjectURL(e[1]), temp:' temp_true' };
                        }                         
                    );

                    setFileUrls([...fileUrls, ...ImagesArray]);
                    setErrorMessage("");
               
            }
    };

    const handleFileOnChangeSave = async (e) => {
            const formData = new FormData();
            formData.append("user_id", user_id);
            formData.append("profile_step", currentStep);

            if(( parseInt(e.target.files.length) + parseInt(UserData?.user_images.length) ) >10 ){
                MaximumFileUpload();
                setErrorMessage("You can only upload 10 maximum image files");
                return false;
            }

            if (e.target.files) {
                    [e.target.files].forEach( async (file) => {
                        setFiles(file)
                    });

                    for (let i = 0; i < e.target.files.length; i += 1) {
                        
                        if(e.target.files[i].size <= 5e6){
                            formData.append('images[]', e.target.files[i]); 
                        }else{
                            NotifySomeError(" File size should be less than 5 MB.")                          
                            e.target.value = ""
                            return false
                        }  
                    }
                    // Files for preview
                    // let ImagesArray = Object.entries(e.target.files).map((e) =>{
                    //     return  { image:URL.createObjectURL(e[1]), temp:' temp_true' };
                    //     }                         
                    // );
                    // setFileUrls([...fileUrls, ...ImagesArray]);
                    // Show Temp Files

                    setErrorMessage("");           
                   
                    if( e.target.files.length > 0){
                        setStatus(" uploading");
                        const Res = await PostUploadFiles('user/store-user-images', formData);                    
                        if(Res.success){
                            setStatus(" success");
                        }else{                    
                            NotifySomeError(Res.message)
                        }
                   }
                    setTimeout(() => {
                        setStatus("")
                    }, 3000);

                    let Userdetail = await PostAuthRequest("get-profile-detail",{'user_id':user_id});
                    if(Userdetail?.success){                        
                        setUserData(Userdetail.data);

                        setFiles([])
                        setFileUrls([]) 
                        document.querySelectorAll(".upload__img-box").forEach(el => el.remove()) 
                        let ImagesArray = Userdetail.data?.user_images.map((item) =>{
                            return { image: item.attachment_file, id: item.id } ;
                        }                    
                        );
                        setFileUrls([...fileUrls, ...ImagesArray]);
                        // parseInt(document.querySelectorAll(".temp_true").length) + 
                        Userdetail && Userdetail.data?.user_images.length >= 2? setDisbaleLink(false) : setDisbaleLink(true);

                       
                    }  
                 
            }
            // getUserProfile();
    };

    const getUserProfile = async ()=>{
        let Userdetail = await PostAuthRequest("get-profile-detail",{'user_id':user_id});
        if(Userdetail?.success){
            setUserData(Userdetail.data);
        }        
    }

    const MoveToNext = async () => {
            let Userdetail = await PostAuthRequest("get-profile-detail",{'user_id':user_id});
            if(Userdetail?.success){
                if(Userdetail.data.user_images.length > 1){
                    getUserProfile();
                    handleNotify(); // Notify the Save data
                    handleNext();
                }else{
                    NotifySomeError('Least 2 images are required. Please add more images.')
                    return false;
                }
            } 
            
    };

     
    const handleSave = async (data) => {
        // if (files && (files.length >=2)) {
        // if(( parseInt(files.length) + parseInt(UserData?.user_images.length) ) >=2 ){            
            const formData = new FormData();
            formData.append("user_id", data.user_id);
            formData.append("profile_step", data.profile_step);            
            for (let i = 0; i < files.length; i += 1) {
                // if(files[i].size <= maxSize){
                    formData.append('images[]', files[i]); 
                // }else{
                //     setErrorMessage("");
                //     setErrorMessage("File size should be less then 2 MB");
                // }  
            } 
            try {              
               if( files.length > 0){
                    setStatus("uploading");
                    const Res = await PostUploadFiles('user/store-user-images', formData);                    
                    if(Res.success){
                        setStatus("success");                   
                    }else{                    
                        NotifySomeError(Res.message)
                    }
               }                
            } catch (error) {
                NotifySomeError('Upload File failed')
                setStatus("fail");
            }
        // }else{
        //     setErrorMessage("");
        //     setErrorMessage("Please select minmum 2 image files of types (image, png, jpg, jpeg)");
        // }

        if(( parseInt(files.length) + parseInt(UserData?.user_images.length) ) >=2 ){
            handleNotify(); // Notify the Save data
            handleNext();
        }else{
            let error ="Please select minmum 2 image files of types (image, png, jpg, jpeg)";
            setErrorMessage("");
            setErrorMessage(error);
            // NotifySomeError(error)
        }
    };

    useEffect(() => {
        getUserProfile();     
        showCurrentImages();
        parseInt(UserData?.user_images.length) >= 2? setDisbaleLink(false) : setDisbaleLink(true);      
        
    }, []);

    const showCurrentImages = async () => {
        setFiles([])
        setFileUrls([]) 
        document.querySelectorAll(".upload__img-box").forEach(el => el.remove())      
        let ImagesArray = UserData?.user_images.map((item) =>{
                return { image: item.attachment_file, id: item.id } ;
            }                    
            );
        setFileUrls([...fileUrls, ...ImagesArray]);
    }
    
    const handleNext = async (e) => {
        try {
            NextStep();
        } catch (error) {
            console.error(error.message);
        }
    };
    
    const handlePrev = (e) => {
        PrevStep();
    };

    const removeProfileImage = async(e, ind, image_id) =>{
           try { 
            if(window.confirm("Do you want to delete this image?")){
                // if(UserData?.user_images.length <= 2){
                //     NotifySomeError('Least 2 images are required, So you can not delete images.')
                // }else{
    
                if(UserData?.user_images.length > 0){
                    const formData = new FormData();
                    formData.append("image_id", image_id);
                    try {          
                        const Res = await PostUploadFiles('user/delete-user-image', formData);        
                        
                        if(Res.success){    
                            notify('Image delete success')       
                                
                        }
                        else{
                            NotifySomeError(Res.message)     
                            setStatus("fail");           
                        }
                    } catch (error) {
                        console.error(error);
                        // NotifySomeError(error)
                        setStatus("fail");
                    }
    
                    const urlsList = fileUrls.filter((item, index) => index !== ind);
                    setFileUrls(urlsList)
                    const updatedFiles = [...files];
                    updatedFiles.splice(ind, 1);
                    setFiles(updatedFiles);

                    let Userdetail = await PostAuthRequest("get-profile-detail",{'user_id':user_id});
                    if(Userdetail?.success){
                        if(Userdetail.data.user_images.length > 1 ){
                            setDisbaleLink(false)
                        }else{
                            setDisbaleLink(true)
                        }
                    }                   
                    // getUserProfile();
                }                
                // }  
            }

        } catch (error) {
            console.error(error);
            // NotifySomeError(error)
            // setStatus("fail");
        }   
       
    
    }

    const removeImage = (e, ind) =>{
        const urlsList = fileUrls.filter((item, index) => index !== ind);
        setFileUrls(urlsList)
        const updatedFiles = [...files];
        updatedFiles.splice(ind, 1);
        setFiles(updatedFiles);      
        getUserProfile(); 
    }
    const handleNotify= (e) => {
        notify("Profile step saved successfully.");   // Notify the Save data
    } 

    return(
        <>
          <form action="#" className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix" id="wizard-02" role="application"  encType="multipart/form-data" >
                
                <ProfileSidebar setActiveTab={setActiveTab} currentStep={currentStep} profileData={UserData} />
                
                <div className="content clearfix">
                <div className="nk-wizard-head title" id="wizard-02-h-3" tabindex="-1">
                <div className="step-text">
                    <div className="lead-text">My Photos</div>
                    <div className="sub-text">Upload your photos</div>
                </div>
                </div>
                <div className="nk-wizard-content body" id="wizard-02-p-3" role="tabpanel" aria-labelledby="wizard-02-h-3" aria-hidden="true">
                <div className="mb-5">
                    <h5 className="title mb-2 text-primary">My Photos</h5>
                    <p className="text-soft">You have the option to update your photos at a later time, but initially, <mark className="fw-bold">you must upload a minimum of 2 photos</mark>. It is essential that at least two of your photos is in portrait style. Ensure that all your photos meet high-quality standards, are non-pornographic, and have no filters or stickers applied. </p>
                    <p className="text-soft">Please select file to upload. Only .jpg, .png and .jpeg photos files are supported!</p>
                    <div className="alert alert-gray" role="alert">If you're facing difficulties with uploads or any issues, connect with our <a href="mailto:info@naughtynewzealand.co.nz">Email Support</a>. We aim to address your concerns within 24 hours, providing timely assistance and solutions. Your satisfaction is our priority. </div>
                </div>
                <div className="row gy-4">
                    <div className="col-sm-12">
                    <div className="form-group">
                        <div className="upload__box">
                        
                        <div className="upload__btn-box text-center">
                            <label className="upload__btn btn btn-lg btn-secondary">
                            <p className="mb-0">Upload Photos - Max 6 or Max 8</p>
                            <input type="file" multiple data-min_length="1" data-max_length="7" accept="image/png, image/jpeg, image/gif" className="upload__inputfile" 
                            {...register("my_photos[]", {
                                required: false,
                            })} 
                            onChange={handleFileOnChangeSave}
                            />
                            </label>
                            {status}
                        </div>
                        <div className="upload__img-wrap">

                            { UserData?.user_images.length > 0 ?
                             <>
                                { fileUrls && fileUrls.map((item, index) => (
                                    <>
                                    <div>
                                    
                                    </div>
                                
                                    <div className={"upload__img-box "+item.temp}>
                                        <div className='img-bg' style={{ backgroundImage: `url(${item.image})` }} >
                                            <div className="upload__img-close uu" onClick={(event)=>{ removeProfileImage(event, index, item.id ) }} ></div>                                    
                                        </div>
                                    </div>
                                    </>
                                )) } 
                             </> :
                                <>
                                    { fileUrls && fileUrls.map((item, index) => (
                                        <div className={"upload__img-box "+item.temp}>
                                            <div className='img-bg' style={{ backgroundImage: `url(${item.image})` }} >
                                                <div className="upload__img-close vvv" onClick={(event)=>{ removeImage(event, index ) }} ></div>                                    
                                            </div>
                                        </div>
                                    )) } 
                                </>
                            }
                                                       
                        </div>
                        {errorMessage && <p className="validation-message">{errorMessage}</p>}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                
                <div className="actions clearfix">
                    <ul role="menu" aria-label="Pagination">
                        {currentStep > 1 && (
                        <li className="" aria-disabled="false">
                            <a href="#previous" role="menuitem" onClick={handlePrev}>
                            Prev
                            </a>
                        </li>
                        )}
                        {currentStep < 7 && (
                        <li aria-hidden="false" aria-disabled="false">
                            <a
                            href="#next"
                            role="menuitem"                            
                            // onClick={handleSubmit(handleSave)}
                            onClick={handleSubmit(MoveToNext)}
                            className={ (DisbaleLink)? "disabled-link" : ""}
                            >
                            Next
                            </a>              
                        </li>
                        )}
                        {currentStep == 7 && (
                        <li aria-hidden="true">
                            <a href="#finish" role="menuitem">
                            Activate My Account
                            </a>
                        </li>
                        )}
                    </ul>
                    </div>
                    <input type="hidden" value={user_id} {...register("user_id")} />
                    <input type="hidden" value={currentStep} {...register("profile_step")} />       
            </form>
        
        </>
    )
}