// LoginForm.js
import React, { useState , useEffect} from 'react';
import { useAuth } from '../../auth/AuthContext';
import { login } from '../../utills/common';
import { useNavigate, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PostAuthRequest, getRecords, PostNoAuthRequest } from "../../utills/common";
import Header from '../Profile/Header'

const ChangePassword = () => {
  const { uselogin } = useAuth();  
  const [passwordType, setpasswordType] = useState('password');
  const [confirmPwType, setconfirmPwType] = useState('password');
  const [Email, setEmail] = useState('');
  const [ProfileName, setProfileName] = useState('');
  const navigate = useNavigate();  
  const [UserProfile, setUserProfile] = useState('');
  const [message, setMessage] = useState('');  

  const {reset, register, handleSubmit, formState: { errors }} = useForm();
  let user_id = JSON.parse(localStorage.getItem('user')).id;

  const ChangePassword = async (data) => {
    try { 
        if(data.password_confirmation!='' && data.password!=''){
            let EmailVerify = await PostNoAuthRequest("auth/change-password",{ "user_id": user_id,"password":data.password, 'password_confirmation':data.password_confirmation});
            if (EmailVerify && EmailVerify.success) {
                setMessage(EmailVerify.message)
            }else{
                setMessage(EmailVerify.message)
            }
        }
        
    } catch (error) {
      console.error(error.message);
    }
  };

    useEffect(() => {
        let defaultValues = {};
        async function fetchData() {       
            let profileData = await PostAuthRequest("get-profile-detail",{'user_id':user_id});            
            if (profileData?.success) {
                setUserProfile(profileData.data)
                setProfileName(profileData.data?.profile_name)
                setEmail(profileData.data?.email)
            }      
        }
        fetchData();               
        // reset({ ...defaultValues });
        
    }, []);
      
  const togglePassword =()=>{
    if(passwordType==="password")
    {
        setpasswordType("text")
        return;
    }
    setpasswordType("password")
}

const toggleConfirmPassword =()=>{
    if(confirmPwType==="password")
    {
        setconfirmPwType("text")
        return;
    }
    setconfirmPwType("password")
}
  return (

    <>
                <div className="nk-body npc-invest bg-lighter ">
                <div className="nk-app-root">       
                    <div className="nk-wrap ">
                        <Header name={ProfileName && ProfileName}  UserProfile={UserProfile}   />
                        <div className="nk-content nk-content-lg nk-content-fluid">
                            <div className="container-xl wide-lg">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                    
                                        <div className="nk-block-head nk-block-head-lg pt-4">
                                            <div className="nk-block-head-content text-center">
                                            
                                                <h2 className="nk-block-title fw-normal">Hello, {ProfileName}</h2>
                                                <div className="nk-block-des">
                                                    <p className="lead">Kindly fill in all the required details in the fields below to update your password.</p>
                                                </div>
                                            </div>
                                        </div>

                                
                                        <div className="nk-block nk-block-lg">
                                            
                                            <div className="card card-bordered">
                                                <div className="card-aside-wrap">
                                                    <div className="card-inner card-inner-lg">
                                                        <div className="nk-block-head nk-block-head-lg">
                                                            <div className="nk-block-between">
                                                                <div className="nk-block-head-content">
                                                                    <h4 className="nk-block-title">Change Password</h4>
                                                                    
                                                                
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                        <form onSubmit={handleSubmit(ChangePassword)}>
                                                            <div className="nk-block">
                                                                
                                                                <div className="nk-data data-list">
                                                            
                                                                    <div className="row gy-4">
                                                                        <div className="col-sm-4">
                                                                            <div className="form-group">
                                                                                <label className="form-label" for="name">Your Name</label>
                                                                                
                                                                                <div className="form-control-wrap ">
                                                                                    <input type="text" className="form-control required" id="name" disabled value={ProfileName}/>
                                                                                </div>
                                                                            
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <div className="form-group">
                                                                                <label className="form-label" for="email">Your Email</label>
                                                                                
                                                                                <div className="form-control-wrap ">
                                                                                    <input type="text" className="form-control required" id="email" value={Email} disabled />
                                                                                </div>
                                                                            
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className="col-sm-12">
                                                                            <hr className="preview-hr" />
                                                                            <span className="preview-title-lg overline-title">Set Your New Password.</span>
                                                                            <div className="nk-block-des mt-4 mb-2">
                                                                                <div className="alert alert-danger " role="alert">Ensure your password consists of a minimum of 8 characters, incorporating a combination of numbers and letters. Please be aware that your username and password are case-sensitive.</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <div className="form-group">
                                                                                <label className="form-label" for="newpassword">New Password</label>
                                                                                
                                                                                <div className="form-control-wrap ">
                                                                                    <a onClick={togglePassword} class="form-icon form-icon-right passcode-switch lg" data-target="password">                                                        
                                                                                        { passwordType==="password"? <em class="passcode-icon icon-show icon ni ni-eye"></em> : <em class="passcode-icon icon-hide icon ni ni-eye-off"></em> }                                                        
                                                                                    </a>
                                                                                    <input type={passwordType} className="form-control required" id="newpassword" placeholder="" {...register("password", { required: 'Field is required' })} />
                                                                                </div>
                                                                                {errors.password && <span className="invalid" >This field is required.</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <div className="form-group">
                                                                                <label className="form-label" for="password_confirmation">Confirm Password</label>
                                                                                
                                                                                <div className="form-control-wrap ">
                                                                                    <a onClick={toggleConfirmPassword} class="form-icon form-icon-right passcode-switch lg" data-target="password_confirmation">
                                                                                        { confirmPwType==="password"? <em class="passcode-icon icon-show icon ni ni-eye"></em> : <em class="passcode-icon icon-hide icon ni ni-eye-off"></em> }
                                                                                    </a>
                                                                                    <input type={confirmPwType} className="form-control required" id="password_confirmation" placeholder=""  {...register("password_confirmation", { required: 'Field is required' })} />
                                                                                </div>
                                                                                {errors.password_confirmation && <span className="invalid" >This field is required.</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 mt-3">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn btn-primary">Update Password</button>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                {message}
                                                                            </div>
                                                                        </div>                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nk-block">
                                            <div className="card card-bordered">
                                                <div className="card-inner card-inner-lg">
                                                    <div className="align-center flex-wrap flex-md-nowrap g-4">
                                                        <div className="nk-block-image w-120px flex-shrink-0">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 118">
                                                                <path d="M8.916,94.745C-.318,79.153-2.164,58.569,2.382,40.578,7.155,21.69,19.045,9.451,35.162,4.32,46.609.676,58.716.331,70.456,1.845,84.683,3.68,99.57,8.694,108.892,21.408c10.03,13.679,12.071,34.71,10.747,52.054-1.173,15.359-7.441,27.489-19.231,34.494-10.689,6.351-22.92,8.733-34.715,10.331-16.181,2.192-34.195-.336-47.6-12.281A47.243,47.243,0,0,1,8.916,94.745Z" transform="translate(0 -1)" fill="#f6faff" />
                                                                <rect x="18" y="32" width="84" height="50" rx="4" ry="4" fill="#fff" />
                                                                <rect x="26" y="44" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                                                <rect x="50" y="44" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                                                <rect x="74" y="44" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                                                <rect x="38" y="60" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                                                <rect x="62" y="60" width="20" height="12" rx="1" ry="1" fill="#e5effe" />
                                                                <path d="M98,32H22a5.006,5.006,0,0,0-5,5V79a5.006,5.006,0,0,0,5,5H52v8H45a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H73a2,2,0,0,0,2-2V94a2,2,0,0,0-2-2H66V84H98a5.006,5.006,0,0,0,5-5V37A5.006,5.006,0,0,0,98,32ZM73,94v4H45V94Zm-9-2H54V84H64Zm37-13a3,3,0,0,1-3,3H22a3,3,0,0,1-3-3V37a3,3,0,0,1,3-3H98a3,3,0,0,1,3,3Z" transform="translate(0 -1)" fill="#1473ed" />
                                                                <path d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z" transform="translate(0 -1)" fill="#1473ed" />
                                                                <path d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z" transform="translate(0 -1)" fill="none" stroke="#1473ed" stroke-miterlimit="10" stroke-width="2" />
                                                                <line x1="40" y1="22" x2="57" y2="22" fill="none" stroke="#fffffe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                                <line x1="40" y1="27" x2="57" y2="27" fill="none" stroke="#fffffe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                                <line x1="40" y1="32" x2="50" y2="32" fill="none" stroke="#fffffe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                                <line x1="30.5" y1="87.5" x2="30.5" y2="91.5" fill="none" stroke="#1473ed" stroke-linecap="round" stroke-linejoin="round" />
                                                                <line x1="28.5" y1="89.5" x2="32.5" y2="89.5" fill="none" stroke="#1473ed" stroke-linecap="round" stroke-linejoin="round" />
                                                                <line x1="79.5" y1="22.5" x2="79.5" y2="26.5" fill="none" stroke="#1473ed" stroke-linecap="round" stroke-linejoin="round" />
                                                                <line x1="77.5" y1="24.5" x2="81.5" y2="24.5" fill="none" stroke="#1473ed" stroke-linecap="round" stroke-linejoin="round" />
                                                                <circle cx="90.5" cy="97.5" r="3" fill="none" stroke="#1473ed" stroke-miterlimit="10" />
                                                                <circle cx="24" cy="23" r="2.5" fill="none" stroke="#1473ed" stroke-miterlimit="10" />
                                                            </svg>
                                                        </div>
                                                        <div className="nk-block-content">
                                                            <div className="nk-block-content-head px-lg-4">
                                                                <h5>We’re here to help you!</h5>
                                                                <p className="text-soft">Ask a question or file a support ticket, manage request, report an issues. Our team support team will get back to you by email.</p>
                                                            </div>
                                                        </div>
                                                        <div className="nk-block-content flex-shrink-0">
                                                            <a href="mailto:info@naughtynewzealand.co.nz" className="btn btn-lg btn-outline-primary">Get Support Now</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


    </>
            

            
    // <div className="nk-body bg-white npc-general pg-auth" >
    //    <div className="nk-app-root">
    //     <div className="nk-main ">
    //         <div className="nk-wrap nk-wrap-nosidebar">
    //             <div className="nk-content ">
    //                 <div className="nk-block nk-block-middle nk-auth-body wide-xs">
    //                     <div className="card card-bordered">
    //                         <div className="card-inner card-inner-lg">
    //                             <div className="nk-block-head">
    //                                 <div className="nk-block-head-content text-center">
    //                                     <img className="logo-icon mb-3" src="./images/logo-icon.svg" alt="logo" />
    //                                     <h4 className="nk-block-title">Reset Your password</h4>
    //                                     <div className="nk-block-des">
    //                                         <p>Ensure your password consists of a minimum of 8 characters, incorporating a combination of numbers and letters.</p>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                             <form onSubmit={handleSubmit(ResetPassword)}>
              
    //                                 <div className="form-group">
    //                                     <label className="form-label" for="password">Password</label>
    //                                     <div className="form-control-wrap">
    //                                         <a onClick={togglePassword} className="form-icon form-icon-right passcode-switch lg" data-target="password">                                                        
    //                                             { passwordType==="password"? <em className="passcode-icon icon-show icon ni ni-eye"></em> : <em className="passcode-icon icon-hide icon ni ni-eye-off"></em> }                                                        
    //                                         </a>
    //                                         <input type={passwordType} className="form-control form-control-lg" id="password" placeholder="Enter your passcode" required   {...register("password", { required: 'Field is required' })} />
    //                                         {errors.password && <p>Password is required.</p>}
    //                                     </div>
    //                                 </div>

    //                                 <div className="form-group">
    //                                     <label className="form-label" for="confirm_password">Confirm Password</label>
    //                                     <div className="form-control-wrap">
    //                                         <a onClick={toggleConfirmPassword} className="form-icon form-icon-right passcode-switch lg" data-target="confirm_password">
    //                                         { confirmPwType==="password"? <em className="passcode-icon icon-show icon ni ni-eye"></em> : <em className="passcode-icon icon-hide icon ni ni-eye-off"></em> }
    //                                         </a>
    //                                         <input type={confirmPwType} className="form-control form-control-lg" id="confirm_password" placeholder="Confirm your passcode"  {...register("confirm_password", { required: 'Field is required' })} />
    //                                         {errors.confirm_password && <p>Confirm password is required.</p>}
    //                                     </div>
    //                                 </div>
    //                                 <div className="form-group">
    //                                     <button className="btn btn-lg btn-primary btn-block" >Reset</button>
    //                                 </div>
    //                             </form>
    //                             <div className="form-note-s2 text-center pt-4"><NavLink to="/login"><strong>Login</strong></NavLink>
    //                             </div>
                               
    //                         </div>
    //                     </div>
    //                 </div>                  
    //             </div>
    //         </div>
    //     </div>
    // </div>
    // </div>
  );
};

export default ChangePassword;
