import { Icon } from '@iconify/react';
import React, { useState, useEffect } from "react";
import Div from '../Div';
import Spacing from '../Spacing';
import ModalImage from 'react-modal-image';

export default function MasonryGallery({profileDetail}) {
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(10);
  // const portfolioData = [
  //   {
  //     src: "http://localhost:8000/storage/uploads/slider/Slider1707353859_pexels-arnie-chou-1557843.jpg",
  //     srcLg: "http://localhost:8000/storage/uploads/slider/Slider1707353859_pexels-arnie-chou-1557843.jpg",
  //   },
  //   {
  //     src: "http://localhost:8000/storage/uploads/slider/Slider1707353850_pexels-quang-anh-ha-nguyen-902030.jpg",
  //     srcLg: "http://localhost:8000/storage/uploads/slider/Slider1707353850_pexels-quang-anh-ha-nguyen-902030.jpg",
  //   },
  //   {
  //     src: "http://localhost:8000/storage/uploads/slider/Slider1707353859_pexels-arnie-chou-1557843.jpg",
  //     srcLg: "http://localhost:8000/storage/uploads/slider/Slider1707353859_pexels-arnie-chou-1557843.jpg",
  //   },
  //   {
  //     src: "http://localhost:8000/storage/uploads/slider/Slider1707353850_pexels-quang-anh-ha-nguyen-902030.jpg",
  //     srcLg: "http://localhost:8000/storage/uploads/slider/Slider1707353850_pexels-quang-anh-ha-nguyen-902030.jpg",
  //   },
    
  //   {
  //     src: "http://localhost:8000/storage/uploads/slider/Slider1707353840_pexels-juliana-stein-1898555.jpg",
  //     srcLg: "http://localhost:8000/storage/uploads/slider/Slider1707353840_pexels-juliana-stein-1898555.jpg",
  //   },
  //   {
  //     src: "http://localhost:8000/storage/uploads/slider/Slider1707353850_pexels-quang-anh-ha-nguyen-902030.jpg",
  //     srcLg: "http://localhost:8000/storage/uploads/slider/Slider1707353850_pexels-quang-anh-ha-nguyen-902030.jpg",
  //   },    
  // ];

  const portfolioData = [];
  if (  profileDetail?.user_images) {
      profileDetail.user_images?.map((item, i) => (
          (item.index > 2)? 
            portfolioData.push( { src: item.attachment_file , srcLg:item.attachment_file } )
            : ""
      ));
  }
  return (
    <>
    
      <Div className="cs-masonry_4_col">
        {portfolioData && portfolioData.slice(0, itemShow).map((item, index) => (         
            <Div className="cs-portfolio cs-style1 cs-type2" >
              {/* <Div className="cs-lightbox_item">
                <ModalImage
                  small={item.src}
                  large={item.srcLg}
                />
              </Div> */}
              {/* <Div className="cs-portfolio_hover" /> */}
              {/* <span className="cs-plus" /> */}
              <Div className="cs-portfolio_bg cs-bg" style={{ backgroundImage: `url("${item.src}")` }} />            
            </Div>
         
        ))}
      </Div>
     
    </>
  );
}
