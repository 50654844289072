import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Div from '../Div';
import Spacing from '../Spacing';
import {PostNoAuthRequest } from "../../utills/common";
import parse from 'html-react-parser';
import PortfolioAgencyItems from '../PortfolioAgencyItems';
import PortfolioSliderAgency from '../Slider/PortfolioSliderAgency';

export default function AgencyDetailPage() {
  pageTitle('Agency Detail');  
  const [AgencyDetail, setAgencyDetail] = useState({});
  const [portfolioData, setPortfolioData] = useState([]);
  const  { slug_name } = useParams('slug_name');   
  const [itemShow, setItemShow] = useState(20); 
  let weekDays = ['Monday', 'Tuesday','Wednesday','Thursday','Friday','Saturday', 'Sunday'];
  useEffect(() => {
    try {  
      async function fetchData() {
        let agencyData = await PostNoAuthRequest("get-club-detail",{slug_name:slug_name});
        if (agencyData.success) {
          setAgencyDetail(agencyData.data);
          setPortfolioData(agencyData.data.club_members);       
        }
      }
      fetchData();
    } catch (error) {
      // setLoading(false); // Stop loading in case of error
      console.error(error);
    }
    window.scrollTo(0, 0)
  }, [])
  
  return (
    
    <>         
    <Div className="bgdark">
     <Spacing lg='50' md='50'/>
     <Div className="container">
        <Div className="row">
          <Div className="col-xl-8 col-lg-7">
            <Div className="cs-radius_5 cs-shine_hover_1 img-grid-box">
              <div className='col'>
                  <img src={AgencyDetail?.attachment_file} alt="Service" className='cs-radius_5 w-100' />
              </div>              
            </Div>
          </Div>
          <Div className="infobox col-xl-4 col-lg-5">
            {/* Escort Name */}
            <h2 className="cs-font_38 mb-1">{ AgencyDetail?.name && AgencyDetail?.name}</h2>            
            <p>{AgencyDetail?.region?.name && AgencyDetail?.region.name}</p>
            {/* Contact Buttons */}            
            <Div className="row">
              {
                <>
                  {
                    AgencyDetail?.phone &&
                    <Div class="actionbtn">
                      <a class="callbutton me-5" href={'tel:'+AgencyDetail.phone}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><g id="Group_22" data-name="Group 22" transform="translate(-357 -1235)"><circle id="Ellipse_6" data-name="Ellipse 6" cx="16.5" cy="16.5" r="16.5" transform="translate(357 1235)" fill="#5ccc17"></circle><path id="Icon_material-call" data-name="Icon material-call" d="M7.729,11.449a13.513,13.513,0,0,0,5.879,5.879l1.963-1.963a.887.887,0,0,1,.91-.214,10.176,10.176,0,0,0,3.185.508.9.9,0,0,1,.892.892v3.113a.9.9,0,0,1-.892.892A15.164,15.164,0,0,1,4.5,5.392.9.9,0,0,1,5.392,4.5H8.514a.9.9,0,0,1,.892.892,10.134,10.134,0,0,0,.508,3.185.9.9,0,0,1-.223.91Z" transform="translate(361.056 1239.056)" fill="#fff"></path></g>
                        </svg>  {AgencyDetail?.phone}
                      </a>
                    </Div>
                  }
                  {
                    AgencyDetail?.email &&
                    <Div class="actionbtn">
                      <a class="mailbutton" href={'mailto:'+AgencyDetail.email}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 27 27">
                        <g id="Group_21" data-name="Group 21" transform="translate(-357 -1198)">
                          <circle id="Ellipse_7" data-name="Ellipse 7" cx="13.5" cy="13.5" r="13.5" transform="translate(357 1198)" fill="#17b2f5"/>
                          <g id="Icon_ionic-ios-mail" data-name="Icon ionic-ios-mail" transform="translate(359.625 1198.125)">
                            <path id="Path_11" data-name="Path 11" d="M18.15,10.336,14.3,14.261a.069.069,0,0,0,0,.1l2.7,2.872a.465.465,0,0,1,0,.659.467.467,0,0,1-.659,0L13.649,15.03a.073.073,0,0,0-.1,0l-.655.666a2.883,2.883,0,0,1-2.055.867,2.941,2.941,0,0,1-2.1-.892l-.63-.641a.073.073,0,0,0-.1,0L5.316,17.892a.467.467,0,0,1-.659,0,.465.465,0,0,1,0-.659l2.7-2.872a.076.076,0,0,0,0-.1L3.5,10.336a.071.071,0,0,0-.122.05v7.853a1.149,1.149,0,0,0,1.146,1.146h12.6a1.149,1.149,0,0,0,1.146-1.146V10.386A.072.072,0,0,0,18.15,10.336Z" transform="translate(0 -1.197)" fill="#fff"/>
                            <path id="Path_12" data-name="Path 12" d="M11.241,14.432a1.947,1.947,0,0,0,1.4-.587L18.26,8.126a1.125,1.125,0,0,0-.709-.251H4.935a1.118,1.118,0,0,0-.709.251l5.619,5.719A1.947,1.947,0,0,0,11.241,14.432Z" transform="translate(-0.417 0)" fill="#fff"/>
                          </g>
                        </g>
                      </svg> {AgencyDetail?.email}
                      </a>
                    </Div>                  
                  }                    
                </>                  
              }
            </Div>

            {/* Info */}
             {/* Info */}
             <Div className="row g-1">
                <h5 className='mb-3 mt-5'>Price -</h5>
                <Div class="table-custom">
                  <table class="table table-dark table-striped table-hover table-borderless">
                      <thead>
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        AgencyDetail?.price_1 && 
                        <tr>
                          <td>Price 1</td>
                          <td>{AgencyDetail?.price_1.includes('$')? AgencyDetail?.price_1 : '$'+AgencyDetail?.price_1 }</td>
                        </tr>
                      }
                      
                      {
                        AgencyDetail?.price_2 && 
                        <tr>
                          <td>Price 2</td>
                          <td>{AgencyDetail?.price_2.includes('$')? AgencyDetail?.price_2 : '$'+AgencyDetail?.price_2 }</td>
                      </tr>
                      }
                      
                      { 
                        AgencyDetail?.price_3 &&
                        <tr>
                          <td>Price 3</td>
                          <td>{AgencyDetail?.price_3.includes('$')? AgencyDetail?.price_3 : '$'+AgencyDetail?.price_3 }</td>
                        </tr>
                      }
                    {
                          AgencyDetail?.price_4 &&
                        <tr>
                          <td>Price 4</td>
                          <td>{AgencyDetail?.price_4.includes('$')? AgencyDetail?.price_4 : '$'+AgencyDetail?.price_4 }</td>
                        </tr>
                    }                  
                    
                    </tbody>

                    </table>
                </Div>
              
             </Div>

             {/* Working Hours */}
             <Div className="row">
              <h5 className='mb-3 mt-4'>Social Links -</h5>
                <Div class="table-custom">               
                    {
                      <>
                        <ul className="custom_list">
                            { AgencyDetail?.facebook &&
                                <li className="w-100">
                                <a href={AgencyDetail?.facebook}>FaceBook</a> 
                            </li>                            
                            }  
                            
                            { AgencyDetail?.instagram &&
                                <li className="w-100">
                                <a href={AgencyDetail?.instagram}>instagram</a> 
                            </li>      
                             }  

                            { 
                              AgencyDetail?.instagram && <li className="w-100">
                                <a href={AgencyDetail?.instagram}>Linked In</a> 
                              </li>
                            } 
                          </ul>
                      </>    
                    }         
                </Div>
            </Div>
           

          </Div>
          <Div className="col-xl-8 col-lg-7">
            <Spacing lg='50' md='40'/>
            {/* About For Desktop */}
            {
              AgencyDetail?.about && 
                <Div className="mb-5">
                  <h4>About Me</h4>              
                  { 
                      AgencyDetail?.about && parse(AgencyDetail?.about)               
                  }
                </Div>
            }            
          </Div>
         
        </Div>

            {/* Select List Page */} 
            {/* Start PortfolioSlider Section */}
            {/* {
              AgencyDetail?.club_members? 
                <Div className="container ">
                  <PortfolioSliderAgency  SliderData={AgencyDetail?.club_members}/>
                  <Spacing lg="50" md="20" />
                </Div>
              : 'No profiles yet'
            } */}
            {/* End PortfolioSlider Section */}

            <Div className="listsection">
            <Div class="cs-height_60 cs-height_lg_20"></Div>
              <Div className="row">
                  <Div className="col-12">
                      <h3>Agency Escorts</h3>
                      <Div class="cs-height_30 cs-height_lg_20"></Div>
                      {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> */}
                  </Div>
              </Div>
              <Div className="row g-2 userlistcol">               
                  {AgencyDetail?.club_members && AgencyDetail?.club_members.slice(0, itemShow).map((item, index) => (
                    <Div
                    className={`${
                        index === 0 || index === 3 || index === 4
                        ? 'col-sm-3'
                        : 'col-sm-3'
                    }`}
                    key={index}
                    >
                    <PortfolioAgencyItems
                        name={item.name}
                        location={item.location}
                        source={AgencyDetail?.name}
                        price={item.price}
                        available={item.available}
                        href={item.href}
                        tel={item.tel}
                        sms={item.sms}
                        src={item.src}
                        contact_method={item.contact_method}
                        is_verified={item.is_verified}
                        variant="cs-style1 cs-type2 cs-size3"
                    />            
                    </Div>
                ))}
                {
                    portfolioData.length==0 && <div>No Search Results Found.</div>
                }

              </Div>
              <Div class="cs-height_60 cs-height_lg_20"></Div>
              {/* <Div className="text-center">              
                        <>
                        <Spacing lg="65" md="40" />
                        { (totalPages !== page) && (totalPages >1) && (
                            <span className="cs-text_btn" onClick={LoadMore}  >
                            {loading ? 'Loading...' : 'Load More'}
                                <Icon icon="bi:arrow-right" />
                            </span>
                            )}
                        </>
                    </Div> */}
            </Div>

            {/* Select List Page */}

      </Div>      
     </Div>
    </>
  )
}
