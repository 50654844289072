import React, { useState , useEffect} from 'react';
import { PostAuthRequest, getRecords } from '../../../../utills/common';
import { useForm } from "react-hook-form";
import { ProfileSidebar } from './ProfileSidebar';

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

export function ExtraServices({
    user_id,
    setActiveTab,
    NextStep,
    PrevStep,
    currentStep,
    profileData,
    notify,
    ServicesList
  }) {
    const {  register, handleSubmit, formState : { errors, isDirty, isSubmitting, isValid } } = useForm( {
        defaultValues: {
            services: []
        }
    });
    // const notify = () => toast("Profile saved successfully.");
    const [uncomplete, setUnComplete] = useState(false);
    // const [ServicesList, setServicesList] = useState("");
    const [array, setArray] = React.useState([{ name: "Massage only", id: 1 }, { name: "Full Service (include massage)", id: 2 }]);
    

    const handleSave = async (fildData) => {       
        try {
            if(isValid){
                const Res = await PostAuthRequest('user/save-profile', {primary_service:fildData.primary_service, user_id:fildData.user_id, profile_step:fildData.profile_step});
                let serviceArr = []
                fildData.services && fildData.services.map((item,index)=>{
                    serviceArr.push({"service_id":item, user_id:fildData.user_id})
                })
                const response = await PostAuthRequest('user/save-esc-services', {"services":serviceArr, "user_id":fildData.user_id});
                if(response.success){
                    handleNext();                  
                }else{
                    console.error(Res.message);
                } 
                handleNotify(); // Notify the Save data
            }      
        } catch (error) {
            console.error(error.message);
        }
        };

        const handleNext = async (e) => {
            try {
                NextStep();
            } catch (error) {
                console.error(error.message);
            }
        };
        
        const handlePrev = (e) => {
            PrevStep();
        };

        const handleNotify= (e) => {
            notify("Profile step saved successfully."); // Notify the Save data
        }

        useEffect(() => {
            try {
                async function fetchData() {
                // let servicesData = await getRecords("service/get-services");
                // console.log("servicesData ", servicesData);                    
                // if (servicesData.success) {
                //     setServicesList(servicesData.data);
                // }
            }
              fetchData();
            } catch (error) {
              // setLoading(false); // Stop loading in case of error
              console.error(error);
            }
          }, []);

        const inServiceArray = (needle, haystack) => {
        var length = haystack.length;
            for(var i = 0; i < length; i++) {
                if(haystack[i].id == needle)
                    return true;
            }
            return false;
        }

    return (
        <>
                    {/* <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition= "Bounce"
                    />

                    <ToastContainer /> */}
                    
                    {/* <button onClick={notify}>Notify!</button> */}
                    <form action="#" onSubmit={handleSubmit(handleSave) } className="nk-wizard nk-wizard-simple is-vertical is-alter wizard clearfix" id="wizard-02" role="application" novalidate="novalidate">
                            {/* <div className="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" className={currentStep == 1 ? "current first" : "done first" } aria-disabled="false" aria-selected="true" onClick={(e) => setActiveTab(e,1)}>
                                        <a id="wizard-02-t-0" href="" aria-controls="wizard-02-p-0" >
                                            <span className="current-info audible">current step: </span>
                                            <span className="number">01</span>
                                            <div className="step-text">
                                                <div className="lead-text">Personal Details</div>
                                                <div className="sub-text">Enter the Personal information</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" className={currentStep == 2 ? "current" : currentStep > 2 ? "done" : " " }  aria-disabled="false" aria-selected="false" onClick={(e) => setActiveTab(e,2)}>
                                        <a id="wizard-02-t-1" href="" aria-controls="wizard-02-p-1">
                                            <span className="number">02</span>
                                            <div className="step-text">
                                                <div className="lead-text">Your Availability</div>
                                                <div className="sub-text">Enter your working hours</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" className={currentStep == 3 ? "current" : currentStep > 3 ? "done" : "disabled " } aria-disabled="true">
                                        <a id="wizard-02-t-2" href="" aria-controls="wizard-02-p-2" onClick={(e) => setActiveTab(e,3)}>
                                            <span className="number">03</span>
                                            <div className="step-text">
                                                <div className="lead-text">Price Information</div>
                                                <div className="sub-text">Enter your Price</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" className={currentStep == 4 ? "current" : currentStep > 4 ? "done" : "disabled " } aria-disabled="true">
                                        <a id="wizard-02-t-3" href="" aria-controls="wizard-02-p-3" onClick={(e) => setActiveTab(e,4)}>
                                            <span className="number">04</span>
                                            <div className="step-text">
                                                <div className="lead-text">My Photos</div>
                                                <div className="sub-text">Upload your photos</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" className={currentStep == 5 ? "current" : currentStep > 5 ? "done" : "disabled " } aria-disabled="true">
                                        <a id="wizard-02-t-4" href="" aria-controls="wizard-02-p-4" onClick={(e) => setActiveTab(e,5)}>
                                            <span className="number">05</span>
                                            <div className="step-text">
                                                <div className="lead-text">My Extras / Services</div>
                                                <div className="sub-text">Select extra services</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" className={currentStep == 6 ? "current" : currentStep > 6 ? "done" : "disabled " } aria-disabled="true">
                                        <a id="wizard-02-t-5" href="" aria-controls="wizard-02-p-5" onClick={(e) => setActiveTab(e,6)}>
                                            <span className="number">06</span>
                                            <div className="step-text">
                                                <div className="lead-text">Verification</div>
                                                <div className="sub-text">Verify your profile</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" className={currentStep == 7 ? "current last" : currentStep > 7 ? "done" : "disabled " } aria-disabled="true">
                                        <a id="wizard-02-t-6" href="" aria-controls="wizard-02-p-6" onClick={(e) => setActiveTab(e,7)}>
                                            <span className="number">07</span>
                                            <div className="step-text">
                                                <div className="lead-text">Completed</div>
                                                <div className="sub-text">Review and Submit</div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                            <ProfileSidebar setActiveTab={setActiveTab} currentStep={currentStep} profileData={profileData} />
                            <div className="content clearfix">
                            <div className="nk-wizard-head title" id="wizard-02-h-4" tabindex="-1">
                            <div className="step-text">
                                <div className="lead-text">My Extras / Services</div>
                                <div className="sub-text">Select extra services</div>
                            </div>
                            </div>
                            <div className="nk-wizard-content body" id="wizard-02-p-4" role="tabpanel" aria-labelledby="wizard-02-h-4" aria-hidden="true" >
                            <div className="mb-5">
                                <h5 className="title mb-3 text-primary">My Extras / Services</h5>
                                <p className="text-soft">Please select from the keywords in the list.</p>
                            </div>
                            <div className="row gy-4">
                                <div className="col-sm-12">
                                <span className="preview-title-lg overline-title">Select primary service</span>
                                <div className="">
                                    <ul className="custom-control-group">

                                    {array.map((item, index) => {
                                        return (
                                            <>
                                             <li>
                                                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                                <input type="radio" {...register("primary_service")} value={item.id} className="custom-control-input" id={'btnRadio'+item.id} defaultChecked={(item.id==profileData.primary_service_id)? true : false} />
                                                <label className="custom-control-label" htmlFor={'btnRadio'+item.id}>{item.name}</label>
                                                </div>
                                            </li>
                                            </>            
                                        );
                                        })}
                                   
                                    </ul>
                                </div>
                                </div>
                                <div className="col-sm-12">
                                <hr className="preview-hr" />
                                <span className="preview-title-lg overline-title mb-3">Select keywords</span>
                                <div className="extraservicesm">
                                    <ul className="custom-control-group">
                                    { ServicesList && ServicesList.map((item, index) => {
                                        return (
                                            <>
                                            <li>
                                                <div className="custom-control custom-control-sm custom-checkbox custom-control-pro">
                                                <input key={index} type="checkbox"  {...register("services")} className="custom-control-input" id={'btnCheckControl'+item.id} value={item.id} 
                                                
                                                // defaultChecked={(item.id==1)? true : ''} 
                                                defaultChecked={ profileData.services && inServiceArray(item.id,profileData.services)? true : false }
                                                />
                                                <label className="custom-control-label" htmlFor={'btnCheckControl'+ item.id}>{item.name}</label>
                                                </div>
                                            </li>
                                            </>            
                                        );
                                        })
                                    }                                      
                                    </ul>
                                </div>
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="actions clearfix">
                                <ul role="menu" aria-label="Pagination">
                                    {currentStep > 1 && (
                                    <li className="" aria-disabled="false">
                                        <a href="#previous" role="menuitem" onClick={handlePrev}>
                                        Prev
                                        </a>
                                    </li>
                                    )}
                                    {currentStep < 7 && (
                                    <li aria-hidden="false" aria-disabled="false">
                                        <a
                                        href="#next"
                                        role="menuitem"
                                        onClick={handleSubmit(handleSave)}
                                        className={uncomplete}
                                        >
                                        Next
                                        </a>              
                                    </li>
                                    )}
                                    {currentStep == 7 && (
                                    <li aria-hidden="true">
                                        <a href="#finish" role="menuitem">
                                        Activate My Account
                                        </a>
                                    </li>
                                    )}
                                </ul>
                            </div>
                            <input type="hidden" value={user_id} {...register("user_id")} />
                            <input type="hidden" value={currentStep} {...register("profile_step")} />
                        </form>
                    </>
    )
}
