import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import { useForm } from "react-hook-form";
import { PostNoAuthRequest, addBodyClass,removeBodyClass } from '../../utills/common';
import ReCAPTCHA from "react-google-recaptcha"

  export default function ContactPage() {
    pageTitle('Contact Us');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');  
    const [mobile, setMobile] = useState('');
    const [city, setCity] = useState('');
    const [description, setDescription] = useState('');
    const [isSaved, setisSaved] = useState(false);
    const {reset, register, handleSubmit, formState: { errors, isDirty, isSubmitting, isValid } } = useForm();
    const [resError, setResError] = useState({});
    const [ContactDetail, setContactDetail] = useState("");
    const [ContactBanner, setContactBanner] = useState("");
    const [loading, setLoading] = useState(true)
    const [btnTxt, setBtnTxt] = useState('Send Message')

    const handleContact = async (data) => {
    try { 
      //  e.preventDefault();
      setBtnTxt('Sending...');
      let Res = await PostNoAuthRequest('contact/store',{name:data.name, email:data.email, mobile:data.mobile, city:data.city, description:data.description });

      if(Res.success){
        setisSaved(true);
        reset({name:'', email:'', mobile:'', city:'', description:'' })
        setBtnTxt('Send Message');
      }else{
        if(Res.data.email!=undefined){
            // errors.email = Res.data.email[0]
              setResError({email:Res.data.email[0]})
          }
          if(Res.data.password!=undefined){
              setResError({password:Res.data.password[0]})
          }
          if(Res.data.error){
              setResError({error:Res.data.error})
          } 
          setBtnTxt('Send Message');
        }

        setTimeout(() => {
          setisSaved(false);
        }, 5000);    
        
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
      window.scrollTo(0, 0);      

    setLoading(true);
    addBodyClass("MyLoaderClass")
    try {
      async function fetchData() {
        let contactData = await PostNoAuthRequest("get-cmspages", {'page_id':2});
        if (contactData.success) {
            setContactDetail(contactData.data?.description);
            setContactBanner(contactData.data?.image_file)
            setLoading(false);
            removeBodyClass('MyLoaderClass');
        }
      }
      fetchData();
    } catch (error) {
      // setLoading(false); // Stop loading in case of error
      console.error(error);
    }
  }, []);



  return (
    <>

      {
        // loading ? (
        //   <div className="loader-container">
        //       <div className="spinner"></div>
        //   </div>
        // ) :

        <>
          <PageHeading
              title="Contact Us"
              bgSrc={ContactBanner}
              pageLinkText="Contact"
            />
            <Spacing lg="100" md="40" />
            <Div className="container">
              <Div className="row">
                <Div className="col-lg-6">
                  <SectionHeading
                    title="Have a question? <br/>We are here to help!"
                    subtitle="Contact Us"
                  />
                  <Spacing lg="55" md="30" />
                  <ContactInfoWidget withIcon ContactDetail={ContactDetail}  />
                  <Spacing lg="0" md="50" />
                </Div>
                <Div className="col-lg-6">
                  <form className="row" onSubmit={handleSubmit(handleContact)} >
                    <Div className="col-sm-6">
                      <label className="cs-primary_color">Full Name*</label>
                      <input type="text" className="cs-form_field" placeholder="name" {...register("name", { required: 'Field is required' })} />
                      {errors.name && <span className="invalid" >Name is required.</span>}
                      <Spacing lg="20" md="20" />
                    </Div>
                    <Div className="col-sm-6">
                      <label className="cs-primary_color">Email*</label>
                      <input type="text" className="cs-form_field" placeholder="email" {...register("email", { required: true, pattern: {
                          value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: 'Invalid email address',
                        }, })} />

                        {errors.email?.type==="required" && <span className="invalid" >Email is required.</span>}
                        {errors.email?.type === "pattern" && (
                            <span className="invalid" >Invalid email</span>
                        )}
                      <Spacing lg="20" md="20" />
                    </Div>
                    <Div className="col-sm-6">
                      <label className="cs-primary_color">Mobile*</label>
                      <input type="text" className="cs-form_field" placeholder="mobile" {...register("mobile", { required: true,
                                                              //  pattern: {
                                                              //     value: /^[0-9]{0,9}$/,
                                                              //     message: 'Invalid email address',
                                                              // } 
                                                              })} />
                      {errors.mobile?.type === "required" && <span className="invalid" >Mobile is required.</span>}
                      {/* {errors.mobile?.type === "pattern" && <span className="invalid" >Invalid mobile number.</span>} */}
                      <Spacing lg="20" md="20" />
                    </Div>
                    <Div className="col-sm-6">
                      <label className="cs-primary_color">City</label>
                      <input type="text" className="cs-form_field" placeholder="city" {...register("city")}  />
                      {/* {errors.city && <span className="invalid" >City is required.</span>} */}
                      <Spacing lg="20" md="20" />
                    </Div>
                    
                    <Div className="col-sm-12">
                      <label className="cs-primary_color" >Message</label>
                      <textarea
                        cols="30"
                        rows="7"
                        className="cs-form_field"  placeholder="Write some thought" {...register("description")} 
                      ></textarea>
                      {/* {errors.description && <span className="invalid" >Description is required.</span>} */}
                      <Spacing lg="25" md="25" />
                    </Div>
                    
                    <Div className="col-sm-12">
                      <ReCAPTCHA sitekey={"6Lf2WbgpAAAAAKrqaBrvsnwxAcHNi1Izy8-eCFWq"} />
                    </Div>

                    <Div className="col-sm-12">
                      <button className="cs-btn cs-style1">
                        <span>{btnTxt}</span>
                        <Icon icon="bi:arrow-right" />
                      </button>
                    </Div>
                    { (isSaved)?
                    (<Div className="col-sm-12">
                        <span>Contact Send Successfully.</span>
                    </Div>) : "" }
                  </form>
                </Div>
              </Div>
            </Div>
            <Spacing lg="100" md="40" />
        </>

      }


      



    </>
  );
}
