import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Button from '../Button';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import { Link } from 'react-router-dom';
import { PostAuthRequest, PostNoAuthRequest } from "../../utills/common";

export default function FaqPage() {
 
  const [Title, setTitle] = useState("");
  const [FaqCatData, setFaqCatData] = useState([]);
  const [FaqData, setFaqData] = useState([]);
  const [ContactBanner, setContactBanner] = useState("");

  useEffect(() => {
    async function fetchData() { 

      let pageData = await PostNoAuthRequest("get-cmspages", {'page_id':5});
      if (pageData.success) {         
          setContactBanner(pageData.data?.image_file)
          setTitle(pageData.data?.title)
      }
      let faqData = await PostNoAuthRequest("get-faq-cat-data");
        if (faqData.success) {
          setFaqCatData(faqData.data);
          setFaqData(faqData.data[0].faq_list)
        }
    }
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const setFaqContent = (e , faqArr) =>{
    setFaqData(faqArr)
  }
  pageTitle(Title);

  return (
    <>
      <PageHeading
        title= {Title}
        bgSrc={ContactBanner}
        pageLinkText="FAQ"
      />
      <Spacing lg="100" md="40" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-4">
            <Div className="cs-faq_nav cs-radius_15">
              <h2 className="cs-faq_nav_title cs-m0">FAQ Category</h2>
              <Div className="cs-height_30 cs-height_lg_30" />
              <ul className="cs-list cs-style1 cs-mp0">                
                { 
                  FaqCatData && FaqCatData.map((item, index) => {                    
                    return <li>
                        <Link className={`cs-text_btn cs-type2`} onClick={(e) => setFaqContent(e, item.faq_list)} >
                              <>
                                <span>{item.name}</span>
                                {/* {icon ? icon : <Icon icon="bi:arrow-right" />} */}
                               { <Icon icon="material-symbols:content-copy-outline-rounded" /> }
                              </>
                        </Link>
                      </li>
                  })
                }

              </ul>
            </Div>
          </Div>
          <Div className="col-lg-7 offset-lg-1">
            <Spacing lg="0" md="40" />
            <Accordion  accordionData= {FaqData} />
          </Div>
        </Div>
      </Div>
      <Spacing lg="100" md="40" />
  
    </>
  );
}
