import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
export function ProfileSidebar({ setActiveTab, currentStep,  profileData }) {

  useEffect(() => {
    //console.log("Sidebar profileData ", profileData);
   }, []);

   return (
      <>
         <div className="steps clearfix">
          <ul role="tablist">
            <li
              role="tab"
              className={currentStep == 1 ? "current first" : "done first"}
              aria-disabled="false"
              aria-selected="true"
              onClick={(e) => setActiveTab(e, 1)}
            >
              <a id="wizard-02-t-0" href="" aria-controls="wizard-02-p-0">
                <span className="current-info audible">current step: </span>
                <span className="number">01</span>
                <div className="step-text">
                  <div className="lead-text">Personal Details</div>
                  <div className="sub-text">Enter the Personal information</div>
                </div>
              </a>
            </li>
            <li
              role="tab"
              className={
                currentStep == 2 ? "current" : currentStep > 2 ? "done" : " "
              }
              aria-disabled="false"
              aria-selected="false"
              onClick={(e) => setActiveTab(e, 2)}
            >
              <a id="wizard-02-t-1" href="" aria-controls="wizard-02-p-1">
                <span className="number">02</span>
                <div className="step-text">
                  <div className="lead-text">Your Availability</div>
                  <div className="sub-text">Enter your working hours</div>
                </div>
              </a>
            </li>
            <li
              role="tab"
              className={
                currentStep == 3
                  ? "current"
                  : currentStep > 3
                  ? "done"
                  : "disabled "
              }
              aria-disabled="true"
            >
              <a
                id="wizard-02-t-2"
                href=""
                aria-controls="wizard-02-p-2"
                onClick={(e) => setActiveTab(e, 3)}
              >
                <span className="number">03</span>
                <div className="step-text">
                  <div className="lead-text">Price Information</div>
                  <div className="sub-text">Enter your Price</div>
                </div>
              </a>
            </li>
            <li
              role="tab"
              className={
                currentStep == 4
                  ? "current"
                  : currentStep > 4
                  ? "done"
                  : "disabled "
              }
              aria-disabled="true"
            >
              <a
                id="wizard-02-t-3"
                href=""
                aria-controls="wizard-02-p-3"
                onClick={(e) => setActiveTab(e, 4)}
              >
                <span className="number">04</span>
                <div className="step-text">
                  <div className="lead-text">My Photos</div>
                  <div className="sub-text">Upload your photos</div>
                </div>
              </a>
            </li>
            <li
              role="tab"
              className={
                currentStep == 5
                  ? "current"
                  : currentStep > 5
                  ? "done"
                  : "disabled "
              }
              aria-disabled="true"
            >
              <a
                id="wizard-02-t-4"
                href=""
                aria-controls="wizard-02-p-4"
                onClick={(e) => setActiveTab(e, 5)}
              >
                <span className="number">05</span>
                <div className="step-text">
                  <div className="lead-text">My Extras / Services</div>
                  <div className="sub-text">Select extra services</div>
                </div>
              </a>
            </li>             
              
                { profileData?.profile_step!=-1 &&
                  <>
                <li
                      role="tab"
                      className={
                        currentStep == 6
                          ? "current"
                          : currentStep > 6
                          ? "done"
                          : "disabled "
                      }
                      aria-disabled="true"
                    >
                      <a
                        id="wizard-02-t-5"
                        href=""
                        aria-controls="wizard-02-p-5"
                        onClick={(e) => setActiveTab(e, 6)}
                      >
                        <span className="number">06</span>
                        <div className="step-text">
                          <div className="lead-text">Verification</div>
                          <div className="sub-text">Verify your profile</div>
                        </div>
                      </a>
                    </li>
                    <li
                      role="tab"
                      className={
                        currentStep == 7
                          ? "current last"
                          : currentStep > 7
                          ? "done"
                          : "disabled "
                      }
                      aria-disabled="true"
                    >
                      <a
                        id="wizard-02-t-6"
                        href=""
                        aria-controls="wizard-02-p-6"
                        onClick={(e) => setActiveTab(e, 7)}
                      >
                        <span className="number">07</span>
                        <div className="step-text">
                          <div className="lead-text">Completed</div>
                          <div className="sub-text">Review and Submit</div>
                        </div>
                      </a>
                    </li>
                    </>
                }
            
          </ul>
        </div>
      </>
   );
}
