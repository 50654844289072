import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Button from '../Button';
import IconBox from '../IconBox';
import Div from '../Div';
import Spacing from '../Spacing';
import MasonryGallery from '../Gallery/MasonryGallery';
import { PostAuthRequest, getRecords, PostNoAuthRequest } from "../../utills/common";
import parse from 'html-react-parser';

export default function ProfileDetailPage() {
  pageTitle('Profile Detail');
  let  images ='';
  const [profileDetail, setProfileDetail] = useState({});
  const  { user_id } = useParams('user_id');    
  let weekDays = ['Monday', 'Tuesday','Wednesday','Thursday','Friday','Saturday', 'Sunday'];
  useEffect(() => {
    try {  
      async function fetchData() {
        let profileData = await PostNoAuthRequest("get-profile-detail",{user_id:user_id});
        console.log("profileData ", profileData);
        if (profileData.success) {
          setProfileDetail(profileData.data);
        }
      }
      fetchData();
    } catch (error) {
      // setLoading(false); // Stop loading in case of error
      console.error(error);
    }
    window.scrollTo(0, 0)
  }, [])
  
  if (profileDetail?.user_images) {
      images = profileDetail.user_images?.map((item, i) => (
          (item.index < 3)? 
            <div className='col'>
                <img src={item.attachment_file} alt="Service" className='cs-radius_5 w-100' />
            </div>
            : ""
      ));
  }
  
  return (
    <>         
    <Div className="bgdark">
     <Spacing lg='50' md='50'/>
     <Div className="container">
        <Div className="row">
          <Div className="col-xl-8 col-lg-7">
            <Div className="cs-radius_5 cs-shine_hover_1 img-grid-box">
              { images && images  }             
              {/* <div className='col'>
                <img src="http://localhost:8000/storage/uploads/slider/Slider1707354299_pexels-tommy-huang-1128065.jpg" alt="Service" className='cs-radius_5 w-100' />
              </div> */}
            </Div>
          </Div>
          <Div className="infobox col-xl-4 col-lg-5">
            <Div className='userstatus'>
            {profileDetail.is_verified && profileDetail.is_verified > 0 ? (
              <Div class="verifieduser">
                <svg xmlns="http://www.w3.org/2000/svg" width="10.636" height="13" viewBox="0 0 10.636 13"><path id="Icon_material-verified-user" data-name="Icon material-verified-user" d="M9.818,1.5,4.5,3.864V7.409A7.354,7.354,0,0,0,9.818,14.5a7.354,7.354,0,0,0,5.318-7.091V3.864ZM8.636,10.955,6.273,8.591l.833-.833,1.53,1.525L12.53,5.388l.833.839Z" transform="translate(-4.5 -1.5)" fill="#fff"></path></svg>
                Verified
              </Div>
              ) : '' }
              <Div class="cs-portfolio_subtitle available">
                <span class="me-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><g id="Group_60" data-name="Group 60" transform="translate(-158 -1169)"><circle id="Ellipse_3" data-name="Ellipse 3" cx="6" cy="6" r="6" transform="translate(158 1169)" fill="#5ccc17" opacity="0.34"></circle><circle id="Ellipse_4" data-name="Ellipse 4" cx="4" cy="4" r="4" transform="translate(160 1171)" fill="#5ccc17" opacity="0.37"></circle><circle id="Ellipse_5" data-name="Ellipse 5" cx="2" cy="2" r="2" transform="translate(162 1173)" fill="#5ccc17"></circle></g></svg>
                </span>
                  Available
              </Div>
              {/* <div class="heart-ico ms-3 active"><svg xmlns="http://www.w3.org/2000/svg" width="20.597" height="19.513" viewBox="0 0 20.597 19.513"><path id="Icon_ionic-md-heart" data-name="Icon ionic-md-heart" d="M12.673,21.666,11.325,20.45c-4.789-4.4-7.95-7.25-7.95-10.8A5.057,5.057,0,0,1,8.489,4.5a5.5,5.5,0,0,1,4.184,1.965A5.5,5.5,0,0,1,16.857,4.5a5.057,5.057,0,0,1,5.114,5.145c0,3.555-3.161,6.408-7.95,10.8Z" transform="translate(-2.375 -3.5)" fill="none" stroke="#fff" stroke-width="2"></path></svg></div> */}
            </Div>
            
            {/* Escort Name */}
            <h2 className="cs-font_38 mb-1">{profileDetail.profile_name}</h2>
            { profileDetail.profile_type==="club" ?
                <p>{profileDetail?.club_detail?.name!=undefined &&  profileDetail?.club_detail?.name} / {profileDetail.profile_type}</p>
                :
                <p>{profileDetail?.region && profileDetail?.region.name} / {profileDetail.profile_type}</p>            
            }
            
            {/* Contact Buttons */}
            
            <Div className="row"> 

                {

                  profileDetail.contact_method && profileDetail.contact_method=="SMS_and_Phone" ?
                  <Div class="actionbtn">
                    <a class="callbutton me-5" href={'tel:'+profileDetail.phone}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><g id="Group_22" data-name="Group 22" transform="translate(-357 -1235)"><circle id="Ellipse_6" data-name="Ellipse 6" cx="16.5" cy="16.5" r="16.5" transform="translate(357 1235)" fill="#5ccc17"></circle><path id="Icon_material-call" data-name="Icon material-call" d="M7.729,11.449a13.513,13.513,0,0,0,5.879,5.879l1.963-1.963a.887.887,0,0,1,.91-.214,10.176,10.176,0,0,0,3.185.508.9.9,0,0,1,.892.892v3.113a.9.9,0,0,1-.892.892A15.164,15.164,0,0,1,4.5,5.392.9.9,0,0,1,5.392,4.5H8.514a.9.9,0,0,1,.892.892,10.134,10.134,0,0,0,.508,3.185.9.9,0,0,1-.223.91Z" transform="translate(361.056 1239.056)" fill="#fff"></path></g>
                      </svg>  {profileDetail.phone}
                    </a>
                    <a class="mailbutton" href={'sms:'+profileDetail.phone}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><g id="Group_21" data-name="Group 21" transform="translate(-356.808 -1197.808)"><circle id="Ellipse_7" data-name="Ellipse 7" cx="16.5" cy="16.5" r="16.5" transform="translate(356.808 1197.808)" fill="#17b2f5"></circle><path id="Icon_ionic-ios-text" data-name="Icon ionic-ios-text" d="M11.71,4.5c-4.6,0-8.335,3.014-8.335,6.732,0,2.6,1.827,4.857,4.5,5.979a5.237,5.237,0,0,1-1.342,2.424.159.159,0,0,0,.144.252,6.447,6.447,0,0,0,2.044-.509A6.324,6.324,0,0,0,10.576,17.9a10.669,10.669,0,0,0,1.142.064c4.6,0,8.335-3.014,8.335-6.732S16.315,4.5,11.71,4.5Z" transform="translate(362.081 1201.956)" fill="#fff"></path></g>
                      </svg> Text Me
                    </a> 
                  </Div>
                  : profileDetail.contact_method=="Phone_Only" ?
                  <Div class="actionbtn">
                    <a class="callbutton me-5" href={'tel:'+profileDetail.phone}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><g id="Group_22" data-name="Group 22" transform="translate(-357 -1235)"><circle id="Ellipse_6" data-name="Ellipse 6" cx="16.5" cy="16.5" r="16.5" transform="translate(357 1235)" fill="#5ccc17"></circle><path id="Icon_material-call" data-name="Icon material-call" d="M7.729,11.449a13.513,13.513,0,0,0,5.879,5.879l1.963-1.963a.887.887,0,0,1,.91-.214,10.176,10.176,0,0,0,3.185.508.9.9,0,0,1,.892.892v3.113a.9.9,0,0,1-.892.892A15.164,15.164,0,0,1,4.5,5.392.9.9,0,0,1,5.392,4.5H8.514a.9.9,0,0,1,.892.892,10.134,10.134,0,0,0,.508,3.185.9.9,0,0,1-.223.91Z" transform="translate(361.056 1239.056)" fill="#fff"></path></g>
                      </svg>  {profileDetail.phone}
                    </a>
                    </Div>
                  : profileDetail.contact_method=="SMS_only" ?
                  <Div class="actionbtn">
                    <a class="mailbutton" href={'sms:'+profileDetail.phone}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><g id="Group_21" data-name="Group 21" transform="translate(-356.808 -1197.808)"><circle id="Ellipse_7" data-name="Ellipse 7" cx="16.5" cy="16.5" r="16.5" transform="translate(356.808 1197.808)" fill="#17b2f5"></circle><path id="Icon_ionic-ios-text" data-name="Icon ionic-ios-text" d="M11.71,4.5c-4.6,0-8.335,3.014-8.335,6.732,0,2.6,1.827,4.857,4.5,5.979a5.237,5.237,0,0,1-1.342,2.424.159.159,0,0,0,.144.252,6.447,6.447,0,0,0,2.044-.509A6.324,6.324,0,0,0,10.576,17.9a10.669,10.669,0,0,0,1.142.064c4.6,0,8.335-3.014,8.335-6.732S16.315,4.5,11.71,4.5Z" transform="translate(362.081 1201.956)" fill="#fff"></path></g>
                      </svg> Text Me
                    </a>
                    </Div>
                  : ""
              }         

              
            </Div>

            {/* Info */}
            <Div className="row g-1">
              <h5 className='mb-3 mt-5'>My Info -</h5>
              {
                profileDetail.age && 
                <Div className="col-6">
                  <aside>
                  <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Age:</h3>
                  <p className='cs-m0'>{profileDetail.age}</p>
                  </aside>
                </Div>
              }
              
              {
                profileDetail.height && 
                <Div className="col-6">
                  <aside>
                  <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Height:</h3>
                  <p className='cs-m0'>{profileDetail.height}</p>
                  </aside>
                </Div>
              }
              
              {
                profileDetail.bust_size && 
                <Div className="col-6">
                  <aside>
                  <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Cup Size:</h3>
                  <p className='cs-m0'>{profileDetail.bust_size}</p>
                  </aside>
                </Div>
              }

              {
                profileDetail.eyes_color && 
                <Div className="col-6">
                  <aside>
                  <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Eyes:</h3>
                  <p className='cs-m0'>{profileDetail.eyes_color}</p>
                  </aside>
                </Div>
              }
              
              {
                  profileDetail.hair_color &&
                  <Div className="col-6">
                    <aside>
                    <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Hair:</h3>
                    <p className='cs-m0'>{profileDetail.hair_color}</p>
                    </aside>
                  </Div>
              }

              {
                  profileDetail.body_type &&
                  <Div className="col-6">
                    <aside>
                      <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Body Type:</h3>
                      <p className='cs-m0'>{profileDetail.body_type}</p>
                    </aside>
                  </Div>
              }

              {
                  profileDetail.bust_size &&
                  <Div className="col-6">
                    <aside>
                      <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Size:</h3>
                      <p className='cs-m0'>{profileDetail.bust_size}</p>
                    </aside>
                  </Div>
              }
              
              {
                profileDetail.available_status!= 0 &&
                <Div className="col-6">
                  <aside>
                    <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Place of Service:</h3>
                    <p className='cs-m0'>
                      {/* In Call / Out Call */}
                      {
                          profileDetail.available_status &&  [...profileDetail.available_status].map(function(item, i){
                            return (
                              <>
                                { (item!='Available Now')? item : "" }
                                {  (item!='Available Now')? profileDetail.available_status.length-1==i? ""  : " / " : '' }
                              </>
                            )                       
                          }
                        )
                      }
                    </p>
                  </aside>
                </Div>
              }
              
              
              {/* {
                profileDetail.profile_type && 
                <Div className="col-6">
                  <aside>
                    <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Type of Escort:</h3>
                    <p className='cs-m0'>{profileDetail.profile_type}</p>
                  </aside>
                </Div>
              } */}
              {/* {
                profileDetail.primary_service && 
                <Div className="col-6">
                    <aside>
                      <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Type of Service:</h3>
                      <p className='cs-m0'>{ (profileDetail.primary_service==1)? "Massage Only" : "Full Service"}</p>
                    </aside>
                </Div>
              } */}
              {
                profileDetail.language &&
                <Div className="col-6">
                  <aside>
                    <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Language:</h3>
                    <p className='cs-m0'>{profileDetail.language && profileDetail.language}</p>
                  </aside>
                </Div>
              }
              
               {
                profileDetail.nationality && 
                <Div className="col-6">
                  <aside>
                    <h3 className='cs-secondary_color cs-font_18 cs-font_18_sm cs-m0'>Nationality:</h3>
                    <p className='cs-m0'>{ profileDetail.nationality && profileDetail.nationality}</p>
                  </aside>
                </Div>
              }
            </Div>

          </Div>
          <Div className="col-xl-8 col-lg-7">
            <Spacing lg='50' md='40'/>
            {
              profileDetail.description && 
              <Div className="mb-5">
                <h4>About Me</h4>              
                { 
                    profileDetail.description && parse(profileDetail.description)               
                }
              </Div>
            }
            

             {/* Select List Page */}
            <MasonryGallery profileDetail={profileDetail} />
            {/* Select List Page */}

          </Div>
          <Div className="infobox col-xl-4 col-lg-5">

            {/* Fees */}
            <Spacing lg='10' md='10'/>
            <Div className="row">
              <h5 className='mb-3 mt-5'>My Fees -</h5>
              <Div class="table-custom">
              <table class="table table-dark table-striped table-hover table-borderless">
                  <thead>
                  <tr>
                    <th scope="col">Time</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    profileDetail.price_30_min &&                 
                    <tr>
                      <td>30 mins</td>
                      <td>{profileDetail.price_30_min.includes('$')? profileDetail.price_30_min : '$'+profileDetail.price_30_min } { (profileDetail.primary_service==1)? "Massage Only" : "Full Service"}</td>
                    </tr>
                  }
                  {
                    profileDetail.price_1_hour &&                  
                    <tr>
                      <td>60 mins</td>
                      <td>{profileDetail.price_1_hour.includes('$')? profileDetail.price_1_hour : '$'+profileDetail.price_1_hour } { (profileDetail.primary_service==1)? "Massage Only" : "Full Service"}</td>
                    </tr>
                 }

                 {
                    profileDetail.price_2_hour &&
                    <tr>
                      <td>120 mins</td>
                      <td>{profileDetail.price_2_hour.includes('$')? profileDetail.price_2_hour : '$'+profileDetail.price_2_hour } { (profileDetail.primary_service==1)? "Massage Only" : "Full Service"}</td>
                    </tr>
                 }

{
                    profileDetail.price_additional_info &&
                    <tr>
                      <td>Additional Info</td>
                      <td>{profileDetail.price_additional_info} { (profileDetail.primary_service==1)? "Massage Only" : "Full Service"}</td>
                    </tr>
                 }
                  
                </tbody>

                </table>
              </Div>
            </Div>

           {/* Working Hours */}
            <Div className="row">
              <h5 className='mb-3 mt-5'>Working Hours -</h5>
              <Div class="table-custom">
              <table class="table table-dark table-striped table-hover table-borderless">
                  <thead>
                  <tr>
                    <th scope="col">Days</th>
                    <th scope="col">Time</th>
                  </tr>
                </thead>
                <tbody>
                {
                     weekDays.map(function(item, index){
                      return (
                        <>                                                 
                        { 
                            <tr>
                              <td>{item}</td>
                              <td> 
                                { 
                                profileDetail?.availabilty_days && profileDetail?.availabilty_days[index].day?
                                  profileDetail?.availabilty_days[index].from  + "  to till  " +  
                                  profileDetail?.availabilty_days[index].to 
                                  : "Not Available" 
                                
                                }
                                </td>
                            </tr>
                        }
                        </>                        
                      )                       
                    }
                    )
                  }
                  
                </tbody>

                </table>
              </Div>
            </Div>


            {/* Extra Services */}
            <Div className="row">
              <h5 className='mb-3 mt-5'>Services Offered -</h5>
              <Div class="table-custom">
              <ul className="custom_list">
                <li className="bg-secondary-grey w-100">{ (profileDetail.primary_service==1)? "Massage Only" : "Full Service"}</li>
              </ul>
                <ul className="custom_list">
                   {
                        profileDetail.services &&  [...profileDetail.services].map(function(item, index){
                          return (
                            <>          
                           {  
                              <li key={index} >
                                  {profileDetail.services[index].name}                                  
                                  {/* <td>{profileDetail.services[index ].name}</td> */}
                              </li>
                            }
                          </>
                          )                       
                        })
                      }
                </ul>
         
                </Div>
            </Div>

            {/* Contact Buttons */}
            <Div className="row">
              <h5 className='mb-3 mt-5'>Contact Me -</h5>
              <Div class="actionbtn">
                  {
                    profileDetail.contact_method && profileDetail.contact_method=="SMS_and_Phone" ?
                    <Div class="actionbtn">

                      <a class="callbutton me-5" href={"tel:"+profileDetail.phone}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><g id="Group_22" data-name="Group 22" transform="translate(-357 -1235)"><circle id="Ellipse_6" data-name="Ellipse 6" cx="16.5" cy="16.5" r="16.5" transform="translate(357 1235)" fill="#5ccc17"></circle><path id="Icon_material-call" data-name="Icon material-call" d="M7.729,11.449a13.513,13.513,0,0,0,5.879,5.879l1.963-1.963a.887.887,0,0,1,.91-.214,10.176,10.176,0,0,0,3.185.508.9.9,0,0,1,.892.892v3.113a.9.9,0,0,1-.892.892A15.164,15.164,0,0,1,4.5,5.392.9.9,0,0,1,5.392,4.5H8.514a.9.9,0,0,1,.892.892,10.134,10.134,0,0,0,.508,3.185.9.9,0,0,1-.223.91Z" transform="translate(361.056 1239.056)" fill="#fff"></path></g>
                        </svg>  {profileDetail.phone}
                      </a>
                      <a class="mailbutton" href={"sms:"+profileDetail.phone}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><g id="Group_21" data-name="Group 21" transform="translate(-356.808 -1197.808)"><circle id="Ellipse_7" data-name="Ellipse 7" cx="16.5" cy="16.5" r="16.5" transform="translate(356.808 1197.808)" fill="#17b2f5"></circle><path id="Icon_ionic-ios-text" data-name="Icon ionic-ios-text" d="M11.71,4.5c-4.6,0-8.335,3.014-8.335,6.732,0,2.6,1.827,4.857,4.5,5.979a5.237,5.237,0,0,1-1.342,2.424.159.159,0,0,0,.144.252,6.447,6.447,0,0,0,2.044-.509A6.324,6.324,0,0,0,10.576,17.9a10.669,10.669,0,0,0,1.142.064c4.6,0,8.335-3.014,8.335-6.732S16.315,4.5,11.71,4.5Z" transform="translate(362.081 1201.956)" fill="#fff"></path></g>
                        </svg> Text Me
                      </a> 
                    </Div>
                    : profileDetail.contact_method=="Phone_Only" ?

                      <a class="callbutton me-5" href={"tel:"+profileDetail.phone}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><g id="Group_22" data-name="Group 22" transform="translate(-357 -1235)"><circle id="Ellipse_6" data-name="Ellipse 6" cx="16.5" cy="16.5" r="16.5" transform="translate(357 1235)" fill="#5ccc17"></circle><path id="Icon_material-call" data-name="Icon material-call" d="M7.729,11.449a13.513,13.513,0,0,0,5.879,5.879l1.963-1.963a.887.887,0,0,1,.91-.214,10.176,10.176,0,0,0,3.185.508.9.9,0,0,1,.892.892v3.113a.9.9,0,0,1-.892.892A15.164,15.164,0,0,1,4.5,5.392.9.9,0,0,1,5.392,4.5H8.514a.9.9,0,0,1,.892.892,10.134,10.134,0,0,0,.508,3.185.9.9,0,0,1-.223.91Z" transform="translate(361.056 1239.056)" fill="#fff"></path></g>
                        </svg>  {profileDetail.phone}
                      </a>

                    : profileDetail.contact_method=="SMS_only" ?
                      <a class="mailbutton" href={"sms:"+profileDetail.phone}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><g id="Group_21" data-name="Group 21" transform="translate(-356.808 -1197.808)"><circle id="Ellipse_7" data-name="Ellipse 7" cx="16.5" cy="16.5" r="16.5" transform="translate(356.808 1197.808)" fill="#17b2f5"></circle><path id="Icon_ionic-ios-text" data-name="Icon ionic-ios-text" d="M11.71,4.5c-4.6,0-8.335,3.014-8.335,6.732,0,2.6,1.827,4.857,4.5,5.979a5.237,5.237,0,0,1-1.342,2.424.159.159,0,0,0,.144.252,6.447,6.447,0,0,0,2.044-.509A6.324,6.324,0,0,0,10.576,17.9a10.669,10.669,0,0,0,1.142.064c4.6,0,8.335-3.014,8.335-6.732S16.315,4.5,11.71,4.5Z" transform="translate(362.081 1201.956)" fill="#fff"></path></g>
                        </svg> Text Me
                      </a>
                    : ""
                  }   
 
              </Div>
            </Div>

            {/* Club */}
            <Spacing lg='10' md='10'/>
              {/* {
                profileDetail?.club_detail &&
                <Div className="row">
                  <h5 className='mb-3 mt-5'>Agency:  {profileDetail?.club_detail.region_name}</h5>
                  <Spacing lg='10' md='10'/>
                  {
                    profileDetail?.club_detail.attachment_file &&
                    <img src={profileDetail?.club_detail.attachment_file} width ="80" alt="img" className='cs-radius_5 w-100' />
                  } 
                  <Spacing lg='10' md='10'/>
                  <Div class="table-custom">
                  <table class="table table-dark table-striped table-hover table-borderless">
                      <thead>
                      <tr>
                        <th scope="col">Label</th>
                        <th scope="col">Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        profileDetail?.club_detail.name &&                 
                        <tr>
                          <td>Name </td>
                          <td>{profileDetail?.club_detail.name}</td>
                        </tr>
                      }
                      {
                      profileDetail?.club_detail.region_name &&                 
                        <tr>
                          <td>Region </td>
                          <td>{profileDetail?.club_detail.region_name}</td>
                        </tr>
                      }
                    {
                      profileDetail?.club_detail.hours &&                 
                        <tr>
                          <td>Hours </td>
                          <td>{ parse(profileDetail?.club_detail.hours) }</td>
                        </tr>
                      } 
                      {
                      profileDetail?.club_detail.phone &&                 
                        <tr>
                          <td>Phone </td>
                          <td>{profileDetail?.club_detail.phone}</td>
                        </tr>
                      }
                      {
                      profileDetail?.club_detail.email &&                 
                        <tr>
                          <td>Email </td>
                          <td>{profileDetail?.club_detail.email}</td>
                        </tr>
                      }
                      {
                      profileDetail?.club_detail.facebook &&                 
                        <tr>
                          <td>Facebook </td>
                          <td><a href={profileDetail?.club_detail.facebook} target="__blank">{profileDetail?.club_detail.facebook}</a></td>
                        </tr>
                      }
                      {
                      profileDetail?.club_detail.instagram &&                 
                        <tr>
                          <td>Instagram </td>
                          <td><a href={profileDetail?.club_detail.instagram} target="__blank">{profileDetail?.club_detail.instagram}</a></td>
                        </tr>
                      }
                      {
                      profileDetail?.club_detail.linked_in &&                 
                        <tr>
                          <td>Linkedin </td>
                          <td><a href={profileDetail?.club_detail.linked_in} target="__blank">{profileDetail?.club_detail.linked_in}</a></td>
                        </tr>
                      }
                      {
                        profileDetail?.club_detail.address &&                 
                        <tr>
                          <td>Hours </td>
                          <td>{ parse(profileDetail?.club_detail.address) }</td>
                        </tr>
                      } 
                      
                    </tbody>

                    </table>
                  </Div>
                </Div>
              }  */}

          </Div>
        </Div>
      </Div>
      <Spacing lg='50' md='50'/>
     </Div>  
  
    </>
  )
}
