import React, { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import Hero6 from '../Hero/Hero6';
import Div from '../Div';
import Spacing from '../Spacing';
import Cta from '../Cta';
import MovingText from '../MovingText';
import PortfolioSlider2 from '../Slider/PortfolioSlider2';
import { pageTitle } from '../../helper';
import SelectList from '../SelectList';
import ModalApp from '../LoadModal';
import { PostAuthRequest, PostNoAuthRequest, getRecords, addBodyClass, removeBodyClass } from "../../utills/common";

export default function Home({}) {
  pageTitle('Home');
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(true);

  const [portfolioData, setPortfolioData] = useState([]);
  const [regions, setReagions] = useState({});
  const [services, setServics] = useState({});
  const [SliderData, setSliderData] = useState([]);

  useEffect(() => {
      window.scrollTo(0, 0);
      try { 
          async function fetchData() {
            let slidersData =   await getRecords('slider/get-sliders');
            let regionsData = await PostNoAuthRequest("get-regions");
            let servicesData = await getRecords("service/get-services");
            let userListData = await PostNoAuthRequest("get-user-profiles");

            let sliderData = await PostNoAuthRequest("get-slider-profiles");
            if (sliderData.success) {
              setSliderData(sliderData.data);
            }

            // setLoading(true);
            setLoading(true);  // show the loader
            addBodyClass("MyLoaderClass")  // Add class to body
            let items = [];
            if(slidersData.success){
              slidersData.data?.map((slider, i) => (
                items.push({ imgUrl:slider.image_file , name:slider.name, description:slider.description})
                ));

                setLoading(false);
                removeBodyClass('MyLoaderClass');
            }
            setSliders(items);

            addBodyClass("MyLoaderClass")
            if (userListData.success) {
                setPortfolioData(userListData);
                setLoading(false);
                removeBodyClass('MyLoaderClass');
            }
              
            if (regionsData.success) {
                let arr = [{value:"",label:"Select Region"}];
                regionsData.data && regionsData.data.map((item, index)=>{
                    arr.push({value:item.id,label:item.name})
                })
              setReagions(arr);
            }
  
            if (servicesData.success) {
                let arr =[{value:"",label:"Select Service"}];
                servicesData.data && servicesData.data.map((item, index)=>{
                    arr.push({value:item.id,label:item.name})
                })
                setServics(arr);
            } 

          }
          fetchData();
      } catch (error) {
          setLoading(false); // Stop loading in case of error
          console.error(error);
      }      
    }, []);


  return (
    <>

      {
        // loading ? (
        //   <div className="loader-container">
        //       <div className="spinner"></div>
        //   </div>
        // ) :

        <>
            {/* Start Hero Section */}
            <Hero6
              showcaseData={sliders}
            />
            {/* End Hero Section */}

            {/* Start MovingText Section */}
            {/* <Spacing lg="10" md="10" /> */}
              {/* <MovingText text="Our reputed world wide partners" /> */}
            {/* <Spacing lg="10" md="10" /> */}
            {/* End MovingText Section */}

            {/* Start PortfolioSlider Section */}
            <Div className="container ">
            {/* <Spacing lg="50" md="20" /> */}
              {/* <PortfolioSlider2  SliderData={ SliderData }/> */}
            {/* <Spacing lg="50" md="20" /> */}
            </Div>
            {/* End PortfolioSlider Section */}
          
            {/* Select List Page */}
              {/* { loading==false?  */}
                <SelectList  portfolio={portfolioData} regions={regions}  services={services} />
              {/* : ''  }  */}
            {/* Select List Page */}

            {/*Modal Load Page */}
            <ModalApp />
            {/* Modal Load Page */}

          </>

      }

     
    </>
  );
}
