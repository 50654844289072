// LoginForm.js
import React, { useState , useEffect} from 'react';
import { PostNoAuthRequest, addBodyClass,removeBodyClass } from '../../utills/common';
import { useNavigate, Link, NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form";

const Signup = () => {
    const [signupTxt, setSignupTxt] = useState('Sign Up');    
    const [message, setMessage] = useState();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setpassword_confirmation] = useState('');
    const [IsSuccess, setIsSuccess] = useState(false);
    const [term, setTerm] = useState(false);
    const [passwordType, setpasswordType] = useState('password');
    const [confirmPwType, setconfirmPwType] = useState('password');  
    const [resError, setResError] = useState({}); 
    const [resendTxt, setResendTxt] = useState('Resend Email');   
   
    const navigate = useNavigate();
    const {reset, register, handleSubmit, formState: { errors, isDirty, isSubmitting, isValid } } = useForm();

    useEffect(() => {
        
    }, []);

    const handleSignup = async (data) => {
        setResError('')
        setSignupTxt('Sign Up...')
    if(data.password!=data.password_confirmation){
        console.log()   
        setResError({password_confirmation: "Password and Confirm Password not equal "})
        return false;
    }

    let parms ={ name:data.name, email:data.email, password:data.password, password_confirmation:data.password_confirmation, "role":"escort" }
    try {       
        let Res = await PostNoAuthRequest('auth/register', parms);
        if(Res.success){
            setIsSuccess(true)
            setSignupTxt('Sign Up')
        }else{
            
            if(Res.data.email!=undefined){
                // errors.email = Res.data.email[0]
                setResError({email:Res.data.email[0]})
            }
            if(Res.data.password!=undefined){
                setResError({password:Res.data.password[0]})
            }
            if(Res.data.error){
                setResError({error:Res.data.error})
            }
        }          
    } catch (error) {
      console.error(error.message);
    }
  };

    const togglePassword =(e)=>{
        if(passwordType==="password")
        {
            setpasswordType("text")
            return;
        }
        setpasswordType("password")
    }

    const toggleConfirmPassword =()=>{
        if(confirmPwType==="password")
        {
            setconfirmPwType("text")
            return;
        }
        setconfirmPwType("password")
    }

    const sendAgainEmail = async (event)=>{         
        event.preventDefault();
        setResendTxt('Sending...');
        let Emailresend = await PostNoAuthRequest("auth/email-resend", { "email": email });
        if (Emailresend && Emailresend.success) {
            setResendTxt('Resend Again');
            setMessage(Emailresend.message);
        }else{
            setResendTxt('Resend Again');
            setMessage(Emailresend.message);
        }
    }


  return (
    <div className="nk-body bg-white npc-general pg-auth" >
       <div className="nk-app-root">
        <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content ">
                    <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                        <div className="card card-bordered">
                            {       (!IsSuccess) ?
                                    <div className="card-inner card-inner-lg">
                                        <form onSubmit={handleSubmit(handleSignup)}>
                                            <div className="nk-block-head">
                                                <div className="nk-block-head-content text-center">
                                                    <img className="logo-icon mb-3" src="./images/logo-icon.svg" alt="logo" />
                                                    <h4 className="nk-block-title">Register</h4>
                                                    <div className="nk-block-des">
                                                        <p>Create Escort Account</p>
                                                    </div>
                                                </div>
                                            </div>                                
                                                <div className="form-group">
                                                    <label className="form-label" for="name">Your Name*</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" className="form-control form-control-lg" id="name" {...register("name", { required: 'Field is required' })} placeholder="Enter your name" value={name}  onChange={(e) => setName(e.target.value)} />
                                                        {errors.name && <span className="invalid" >Name is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label" for="name">Email*</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" className="form-control form-control-lg" id="email" placeholder="Enter your email address" value={email} {...register("email", { required: true, pattern: {
                                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,                                                            
                                                            message: 'Invalid email address',
                                                        } })}  onChange={(e) => setEmail(e.target.value)} />
                                                        {errors.email?.type==="required" && <span className="invalid" >Email is required.</span>}
                                                        {errors.email?.type === "pattern" && (
                                                            <span className="invalid" >Invalid email</span>
                                                        )}
                                                        {resError.email && <span className="invalid" >{resError.email}</span>}
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label class="form-label" for="password">Password*</label>
                                                    <div class="form-control-wrap">
                                                        <a onClick={togglePassword} class="form-icon form-icon-right passcode-switch lg" data-target="password">                                                        
                                                            { passwordType==="password"? <em class="passcode-icon icon-show icon ni ni-eye"></em> : <em class="passcode-icon icon-hide icon ni ni-eye-off"></em> }                                                        
                                                        </a>
                                                        <input type={passwordType} class="form-control form-control-lg" id="password" placeholder="Enter your passcode" 
                                                            {...register("password", { 
                                                                required: true, 
                                                                minLength: 6
                                                                // pattern: {
                                                                //     value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/,
                                                                //     message: 'Password must contains special symbols, numbers, letters between (8-16)',
                                                                // } 
                                                        })}  value={password} onChange={(e) => setPassword(e.target.value)} />

                                                        {errors.password?.type==="required" && <span className="invalid" >Password is required.</span>}
                                                        {resError.password && <span className="invalid" >{resError.password}</span>}
                                                        {errors.password?.type==="minLength" && <span className="invalid" >Minmum Length is 6 digits.</span>}
                                                        {/* {errors.password?.type === "pattern" && (
                                                            <span className="invalid" >Password must contains special symbols, numbers, letters between (8-16)</span>
                                                        )} */}
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label class="form-label" for="confirmpassword">Confirm Password*</label>
                                                    <div class="form-control-wrap ">
                                                        <a onClick={toggleConfirmPassword} class="form-icon form-icon-right passcode-switch lg" data-target="confirmpassword">
                                                        { confirmPwType==="password"? <em class="passcode-icon icon-show icon ni ni-eye"></em> : <em class="passcode-icon icon-hide icon ni ni-eye-off"></em> }
                                                        </a>
                                                        <input type={confirmPwType} class="form-control form-control-lg" id="confirmpassword" placeholder="Confirm your passcode" {...register("password_confirmation", { required: 'Field is required' })}  value={password_confirmation} onChange={(e) => setpassword_confirmation(e.target.value)} />
                                                        {errors.password_confirmation && <span className="invalid" >Confirm password is required.</span>}
                                                        {resError.password_confirmation && <span className="invalid" >{resError.password_confirmation}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="form-control-wrap">
                                                    <ReCAPTCHA sitekey={"6Lf2WbgpAAAAAKrqaBrvsnwxAcHNi1Izy8-eCFWq"} />
                                                    </div>
                                                </div>
                                                
                                                <div class="form-group">
                                                    <div class="custom-control custom-control-xs custom-checkbox">
                                                    <input type='checkbox' name='term' class="custom-control-input" id="checkbox" {...register("term", { required: 'Field is required' })} value={term} onChange={(e) => setTerm(e.target.value)} />
                                                        <label class="custom-control-label" for="checkbox">By creating an account, I agree to the <a href="/terms-of-use" target='_blank'>terms of use.</a></label>
                                                        {errors.term && <span className="invalid" >Terms is required.</span>}
                                                    </div>
                                                </div>
                                                
                                                
                                                <p>{resError.err && <span className="invalid" >{resError.err}</span>}</p>
                                                <div className="form-group mb-0">
                                                    <button type='submit' className="btn btn-lg btn-primary btn-block" >{signupTxt}</button>
                                                </div>
                                                <div className="form-note-s2 text-center pt-4"> Already have an account? <NavLink to="/login"><strong>Sign in</strong></NavLink>
                                            </div>
                                        </form>
                                    
                                    </div>
                            : 
                                <>
                                    <div class="card-inner card-inner-lg">
                                        <div class="nk-block-head">
                                            <div class="nk-block-head-content text-center">
                                                <img class="logo-icon mb-4" src="./images/email.svg" alt="logo" />
                                                <h4 class="nk-block-title mb-2">Please Verify Your Email</h4>
                                                <p>You're almost there! we sent an email to <br/><strong>{email}</strong></p>
                                                <p>Just click on the link in that email to complete your signup. If you don't see it, you may need to check your spam folder.</p>
                                                <p>Still can't find the email?</p>
                                            
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <a class="btn btn-md btn-primary" onClick={sendAgainEmail} href="#">{resendTxt}</a>
                                            
                                        </div>
                                        <div class="form-note-s2 text-center mt-4">{message}</div>
                                        <div class="form-note-s2 text-center mt-4"> Need help? <a href="mailto:info@naughtynewzealand.co.nz"><strong>Contact Us</strong></a></div>
                                        
                                    </div>
                                </>
                            
                            
                            }

                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
    </div>
  );
};

export default Signup;
