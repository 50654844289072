// LoginForm.js
import React, { useState } from 'react';
import { useAuth } from '../../auth/AuthContext';
import { login, setUserSession, PostNoAuthRequest } from '../../utills/common';
import { useNavigate, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";


const LoginForm = () => {      
  const { uselogin,  user, token } = useAuth();
  const navigate = useNavigate();
  const {reset, register, handleSubmit, formState: { errors, isDirty, isSubmitting, isValid } } = useForm();
  const [passwordType, setPasswordType] = useState("password");
  const [errorCustom, setErrorCustom] = useState({});
  const [resError, setResError] = useState({});
  const [loading, setLoading] = useState(false)
  const handleLogin = async (data) => {      
    
    try {
        //  "email": "billa3@gmail.com",
        //  "password": "123456"
        if(data.email!='' && data.password!='' && data.email!=undefined && data.password!=undefined ){
            let email = data.email;
            let password = data.password;
            localStorage.clear();
            // setLoading(true);    
            setResError('')
            let Res = await login({email,password});
            
            if(Res.success){
                // setTimeout(() => {
                //     setLoading(false);
                // }, 5000);
                // 
                let profiledetail = await PostNoAuthRequest("get-profile-detail",{'user_id':Res.data.user.id});
                if (profiledetail && profiledetail.success) {                      
                    if(profiledetail.data.profile_step ==-1){
                        navigate('/');
                    }else{
                        navigate('/profile');
                    }                  
                }
                uselogin(Res.data.user.name, Res.data.token);    
                setUserSession(Res.data.token, Res.data.user)
                navigate('/profile');
                window.location.reload(false)  
            }else{

                if(Res.data.email!=undefined){
                    resError.email = Res.data.email[0]
                }
                if(Res.data.password!=undefined){
                    resError.password = Res.data.password[0]
                }
                if(Res.data.error){
                    setResError({err:Res.data.error})
                }
            }        
           
        }else{

            setLoading(false);
        }
        
    } catch (error) {
      console.log("error ", error);
    }
  };

  const togglePassword =(event)=>{
    event.preventDefault();
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }

  return (
     <>
            {
                // loading==true ? (
                //     <div className="loader-container">
                //         <div className="spinner"></div>
                //     </div>
                // ) :
                (
                    <div className="nk-body bg-white npc-general pg-auth" >      
                    <div className="nk-app-root">
                    <div className="nk-main ">
                        <div className="nk-wrap nk-wrap-nosidebar">
                            <div className="nk-content ">
                                <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                                    <div className="card card-bordered">
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head">
                                                <div className="nk-block-head-content text-center">
                                                    <img className="logo-icon mb-3" src="./images/logo-icon.svg" alt="logo" />
                                                    <h4 className="nk-block-title">Sign-In</h4>
                                                    <div className="nk-block-des">
                                                        <p>Access your account using your email and password.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit(handleLogin)}>
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="email">Email</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" className="form-control form-control-lg" id="email" placeholder="Enter your email" {...register("email", { required: true, pattern: {
                                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,                                                            
                                                            message: 'Invalid email address',
                                                        } })}  />
                                                        {/* {errors.email && <p>Email is required.</p>} */}
                                                        {errors.email?.type === "required" && <span className="invalid" >Email is required.</span>}
                                                        {errors.email?.type === "pattern" && (
                                                            <span className="invalid" >Invalid email.</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-label-group">
                                                        <label className="form-label" htmlFor="password">Password</label>
                                                        {/* <a className="link link-primary link-sm" href="html/forgot.html">Forgot Password?</a> */}
                                                        <NavLink to="/forget-password"><strong>Forgot Password</strong></NavLink>
                                                    </div>
                                                    <div className="form-control-wrap">
                                                        <a href="" className="form-icon form-icon-right passcode-switch lg" onClick={togglePassword} data-target="password">                                                        
                                                            { passwordType==="password"?  <em className="passcode-icon icon-show icon ni ni-eye"></em>: <em className="passcode-icon icon-hide icon ni ni-eye-off"></em> }
                                                                                                                       
                                                        </a>
                                                        <input type={passwordType} className="form-control form-control-lg" id="password" placeholder="Enter your passcode" {...register("password", { required: 'Field is required' })} />
                                                        {/* {errors.password && <p>Password is required.</p>} */}
                                                        {errors.password && <span className="invalid" >Password is required.</span>}
                                                        {resError.email && <span className="invalid" >{resError.email}</span>}
                                                        {resError.password && <span className="invalid" >{resError.password}</span>}
                                                        {resError.err && <span className="invalid" >{resError.err}</span>}
                                                    </div>
                                                    
                                                </div>
            
                                                <div className="form-group">
                                                    <button className="btn btn-lg btn-primary btn-block" type >Sign In</button>
                                                </div>
                                            </form>
                                            <div className="form-note-s2 text-center pt-4"> New on our platform? <NavLink to="/signup"><strong>Create an account</strong></NavLink>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                )
            }
        </>
  );
  
};

export default LoginForm;
