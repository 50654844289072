import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import './portfolio.scss';

export default function Portfolio({contact_method, href, src, name, location, variant, price, source, available,  sms,  tel, is_verified }) {

 let APP_URL =  process.env.APP_URL;
  return (
    
    <Div className={`cs-portfolio cs-bg ${variant ? variant : 'cs-style1'}`}>
      <>
        
        {/* Add class "active" to selected favourite */}
        {/* <Div className="heart-ico">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.597" height="19.513" viewBox="0 0 20.597 19.513">
          <path id="Icon_ionic-md-heart" data-name="Icon ionic-md-heart" d="M12.673,21.666,11.325,20.45c-4.789-4.4-7.95-7.25-7.95-10.8A5.057,5.057,0,0,1,8.489,4.5a5.5,5.5,0,0,1,4.184,1.965A5.5,5.5,0,0,1,16.857,4.5a5.057,5.057,0,0,1,5.114,5.145c0,3.555-3.161,6.408-7.95,10.8Z" transform="translate(-2.375 -3.5)" fill="none" stroke="#fff" stroke-width="2"/>
        </svg>
        </Div> */}

        {/* Call Mail Buttons */}
        <Div className="actionbtn">
        {/* mail Button */}
        {
         
            contact_method && contact_method=="SMS_and_Phone" ?
            <>
              <Link to={'sms:'+ sms} className={`mailbutton`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
                  <g id="Group_21" data-name="Group 21" transform="translate(-356.808 -1197.808)">
                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="16.5" cy="16.5" r="16.5" transform="translate(356.808 1197.808)" fill="#17b2f5"/>
                    <path id="Icon_ionic-ios-text" data-name="Icon ionic-ios-text" d="M11.71,4.5c-4.6,0-8.335,3.014-8.335,6.732,0,2.6,1.827,4.857,4.5,5.979a5.237,5.237,0,0,1-1.342,2.424.159.159,0,0,0,.144.252,6.447,6.447,0,0,0,2.044-.509A6.324,6.324,0,0,0,10.576,17.9a10.669,10.669,0,0,0,1.142.064c4.6,0,8.335-3.014,8.335-6.732S16.315,4.5,11.71,4.5Z" transform="translate(362.081 1201.956)" fill="#fff"/>
                  </g>
                </svg>
                </Link>

                
                <Link to={'tel:'+ tel} className={`callbutton`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
                  <g id="Group_22" data-name="Group 22" transform="translate(-357 -1235)">
                    <circle id="Ellipse_6" data-name="Ellipse 6" cx="16.5" cy="16.5" r="16.5" transform="translate(357 1235)" fill="#5ccc17"/>
                    <path id="Icon_material-call" data-name="Icon material-call" d="M7.729,11.449a13.513,13.513,0,0,0,5.879,5.879l1.963-1.963a.887.887,0,0,1,.91-.214,10.176,10.176,0,0,0,3.185.508.9.9,0,0,1,.892.892v3.113a.9.9,0,0,1-.892.892A15.164,15.164,0,0,1,4.5,5.392.9.9,0,0,1,5.392,4.5H8.514a.9.9,0,0,1,.892.892,10.134,10.134,0,0,0,.508,3.185.9.9,0,0,1-.223.91Z" transform="translate(361.056 1239.056)" fill="#fff"/>
                  </g>
                </svg>
                </Link>
            </>
          
          : contact_method && contact_method=="Phone_Only" ?

          <Link to={'tel:'+ tel} className={`callbutton`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
            <g id="Group_22" data-name="Group 22" transform="translate(-357 -1235)">
              <circle id="Ellipse_6" data-name="Ellipse 6" cx="16.5" cy="16.5" r="16.5" transform="translate(357 1235)" fill="#5ccc17"/>
              <path id="Icon_material-call" data-name="Icon material-call" d="M7.729,11.449a13.513,13.513,0,0,0,5.879,5.879l1.963-1.963a.887.887,0,0,1,.91-.214,10.176,10.176,0,0,0,3.185.508.9.9,0,0,1,.892.892v3.113a.9.9,0,0,1-.892.892A15.164,15.164,0,0,1,4.5,5.392.9.9,0,0,1,5.392,4.5H8.514a.9.9,0,0,1,.892.892,10.134,10.134,0,0,0,.508,3.185.9.9,0,0,1-.223.91Z" transform="translate(361.056 1239.056)" fill="#fff"/>
            </g>
          </svg>
          </Link>
            : contact_method && contact_method=="SMS_only" ?
            <Link to={'sms:'+ sms} className={`mailbutton`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
              <g id="Group_21" data-name="Group 21" transform="translate(-356.808 -1197.808)">
                <circle id="Ellipse_7" data-name="Ellipse 7" cx="16.5" cy="16.5" r="16.5" transform="translate(356.808 1197.808)" fill="#17b2f5"/>
                <path id="Icon_ionic-ios-text" data-name="Icon ionic-ios-text" d="M11.71,4.5c-4.6,0-8.335,3.014-8.335,6.732,0,2.6,1.827,4.857,4.5,5.979a5.237,5.237,0,0,1-1.342,2.424.159.159,0,0,0,.144.252,6.447,6.447,0,0,0,2.044-.509A6.324,6.324,0,0,0,10.576,17.9a10.669,10.669,0,0,0,1.142.064c4.6,0,8.335-3.014,8.335-6.732S16.315,4.5,11.71,4.5Z" transform="translate(362.081 1201.956)" fill="#fff"/>
              </g>
            </svg>
            </Link>
            : ""  
        }
        
          
        </Div>

        <Link to={"/" + href}>
          {/* Verified User APP_URL */}            
            {is_verified && is_verified > 0 ? (
              <>
              <Div className="verifieduser">
                <svg xmlns="http://www.w3.org/2000/svg" width="10.636" height="13" viewBox="0 0 10.636 13">
                  <path id="Icon_material-verified-user" data-name="Icon material-verified-user" d="M9.818,1.5,4.5,3.864V7.409A7.354,7.354,0,0,0,9.818,14.5a7.354,7.354,0,0,0,5.318-7.091V3.864ZM8.636,10.955,6.273,8.591l.833-.833,1.53,1.525L12.53,5.388l.833.839Z" transform="translate(-4.5 -1.5)" fill="#fff"/>
                </svg>
                Verified
                </Div>
              </>
            ) : ""  }            
          
          <Div className="cs-portfolio_hover" />
          <Div
            className="cs-portfolio_bg cs-bg"
            style={{ backgroundImage: `url("${src}")` }}
          />
          <Div className="cs-portfolio_info">            
            <Div className="cs-portfolio_info_bg cs-accent_bg" />
            <Div className="cs-portfolio_subtitle available">              
              {
                ( available!=0 && available?.indexOf("Available Now") > -1) && (
                  <>
                    <span className='me-1'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <g id="Group_60" data-name="Group 60" transform="translate(-158 -1169)">
                          <circle id="Ellipse_3" data-name="Ellipse 3" cx="6" cy="6" r="6" transform="translate(158 1169)" fill="#5ccc17" opacity="0.34"/>
                          <circle id="Ellipse_4" data-name="Ellipse 4" cx="4" cy="4" r="4" transform="translate(160 1171)" fill="#5ccc17" opacity="0.37"/>
                          <circle id="Ellipse_5" data-name="Ellipse 5" cx="2" cy="2" r="2" transform="translate(162 1173)" fill="#5ccc17"/>
                        </g>
                      </svg>
                    </span>
                   { available && available[available.indexOf("Available Now")] } 
                </>
                )
              }
            </Div>
            <h2 className="cs-portfolio_title">{name && name}</h2>
            <Div className="cs-portfolio_subtitle">{location && location} | {source && source}</Div>
            <Div className="cs-portfolio_subtitle description">1 Hour {price && price.includes('$')? price : '$'+price }</Div>
          </Div>
        </Link>
      </>
      </Div>
  );
}
