import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {EffectFade, Autoplay, Mousewheel, Pagination, Navigation } from 'swiper';
import { Icon } from '@iconify/react';
import 'swiper/css/effect-fade';
// import parse from 'html-react-parser';
import Div from '../Div';

export default function FullScreenHorizontalSlider({ data }) {
  return (
    <Div className="cs-swiper_arrow_style_1">
      {/* <Div className="cs-hero_text">
        <Div className="container">
          <Div className="row">
            <Div className="col-sm-12 col-md-8 col-lg-7 col-xxl-6">
                <aside>
                  <h1 className="cs-hero_title">{ data[0]?.name }</h1>
                  <p>{ data[0]?.description }</p>
                  <a class="cs-btn cs-style1 cs-type1 primary-btn" href="signup"><span>Post Your Advertisement</span></a>
                </aside>
            </Div>
          </Div>
          
        </Div>
      </Div> */}
     
      <Div className="swiper-button image-swiper-button-next">
        Next <Icon icon="bi:arrow-right" />
      </Div>
      <Div className="swiper-button image-swiper-button-prev">
        <Icon icon="bi:arrow-left" /> Prev
      </Div>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        mousewheel={false}
        autoplay={true}
        pagination={false}
        speed={2000}
        loop={true}
        effect="fade"
        modules={[EffectFade, Autoplay, Mousewheel, Pagination, Navigation]}
        className="mySwiper"
        
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
          disabledClass: 'swiper-button-disabled',
        }
      }
     
      >
        {data.map((item, index) => (
          
          <>
          {item.imgUrl}
          </>,
          <SwiperSlide key={index}>
            <Div
              className="cs-hero cs-style4 cs-bg"
              style={{ backgroundImage: `url(${item.imgUrl})` }}
            >
              
              <Div className="cs-hero_text">
                <Div className="container">
                  <Div className="row">
                    <Div className="col-sm-12 col-md-8 col-lg-7 col-xxl-6">
                        <aside>
                          <h1 className="cs-hero_title">{ item?.name }</h1>
                          <p>{ item?.description }</p>
                          <a class="cs-btn cs-style1 cs-type1 primary-btn" href="signup"><span>Post Your Advertisement</span></a>
                        </aside>
                    </Div>
                  </Div>
                  
                </Div>
              </Div>
            </Div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Div>
  );
}
